
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useContext, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../context/AppContext";
import { TranslationContext } from "../../context/TranslationContext";
import { tn } from "../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";

const useStyles = createStyles((theme) => ({
  wrapper: {
    
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",

    // 
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
  },

  title: {
    color: "#69DCBF",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
}));
export function AdminLoginPage() {
  const pb: any = new PocketBase(CMS_ENDPOINT);

  const navigate = useNavigate();
  const [isError, setisError]: any = useState(false);
  const form: any = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      // email: (value) =>
      //   value !== "" ? null : "please write your email or usename",
      // password: (value) => (value !== "" ? null : "please write your password"),
    },
  });

  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale } = useContext(TranslationContext);

  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  const errorMassage = tn("Invalid email or password");
  return (
    <form
    onSubmit={form.onSubmit(async () => {
                  // if(form.isValid()){
                  try {
                    const authData = await pb.collection('admin')
                      .authWithPassword(form.values.email, form.values.password)
                      .then((res: any) => {
                        navigate("/admin/dashboard");
                      });
                  } catch (err) {
                    setisError(true);
                  }
                  // }
                })}
    >
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "60px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <img style={{ height: "93px" }} src="/dark_text_logo.svg" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
            <Text style={{"color": "#21033F",
  "textAlign": "right",
  "fontSize": "64px",
  "fontStyle": "normal",
  "fontWeight": "700",
  "lineHeight": "108px"}}>
    {tn("System Administrator")}
            </Text>
            </div>
            
            <div>
              <TextInput
                {...form.getInputProps("email")}
                required
                mt={90}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "black",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #21033F85",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("Email Address")}
                placeholder="hello@coophub.co"
                size="md"
              />
              <PasswordInput
                {...form.getInputProps("password")}
                required
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "#21033F",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #21033F85",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                  innerInput: {
                    color: "#21033F",
                  },
                })}
                label={tn("Password")}
                placeholder={tn("Your password")}
                mt={50}
                size="md"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Checkbox
                  defaultChecked={true}
                  label={tn("Remember me")}
                  mt="xl"
                  size="md"
                  styles={() => ({
                    label: { cursor: "pointer", color: "#21033F" },
                    input: {
                      cursor: "pointer",
                      "&:checked": {
                        backgroundColor: "#21033F",
                        border: "0px solid #21033F",
                      },
                    },
                  })}
                />
                <Anchor<"a">
                  color="#FFB004"
                  href="#"
                  weight={700}
                  onClick={(event) => navigate("/admin/forgot_password")}
                >
                  {tn("Forgot Password?")}
                </Anchor>
              </div>
              <Button
                type="submit"
                style={{
                  borderRadius: "5.112px",
                  height: "70px",
                  backgroundColor: "#21033F",
                  fontSize: "16px",
                }}
                fullWidth
                mt="xl"
                size="md"
              >
                {tn("Login")}
              </Button>
              {isError && (
                <Text mt="md" size="13px" color="red">
                  {errorMassage}
                </Text>
              )}
            </div>
          </div>
        </Paper>

        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/images/image1.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(100%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            height: "100%",
            width: `calc(100vw - 700px)`,
            background:
              "linear-gradient(312deg, rgba(2,0,36,1) 0%, #b6b6b6 0%, rgba(255, 255, 255, 0.472) 100%)",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: "url(/shapes/yellow_blue.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              fontSize: "40px",
              color: "white",
              fontWeight: 700,
              textAlign: "left",
              padding: "270px 0px 0px 0px",
            }}
          >
            خطوتك الأولى
            <br /> لمستقبل مهني واعد
          </div>
        </div>
      </div>
    </form>
  );
}

export default AdminLoginPage;
