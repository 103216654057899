
import { useContext, useEffect, useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Anchor,
  Group,
  Burger,
  rem,
  Center,
  RingProgress,
  Text
} from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { MantineLogo } from "@mantine/ds";
import { IconArrowUpRight } from "@tabler/icons-react";
import { CMS_ENDPOINT } from "../../../constants";
import  PocketBase from "pocketbase";
import { AppContext } from "../../../context/AppContext";
import { NotiPopup } from "../../../main_components/notifications/NotiPopup";

const HEADER_HEIGHT = rem(84);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 140000,
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`,
  },

  mainLink: {
    textTransform: "uppercase",
    fontSize: rem(13),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    padding: `${rem(7)} ${theme.spacing.sm}`,
    fontWeight: 700,
    borderBottom: `${rem(2)} solid transparent`,
    transition: "border-color 100ms ease, color 100ms ease",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },

  secondaryLink: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
    transition: "color 100ms ease",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },

  mainLinkActive: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottomColor:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 5 : 6],
  },
}));

interface LinkProps {
  label: string;
  link: string;
}
export default function MainHeaderApplicant() {
  const pb = new PocketBase(CMS_ENDPOINT);

  // const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  //  const [ProgressValue, setProgressValue] = useState(30);
  //  const [userData, setUserData] = useState(pb.authStore.model);
  //  const {reloader}:any = useContext(AppContext)
//    useEffect(() => {
//     pb.collection(pb.authStore.model?.collectionName).subscribe(pb.authStore.model.id, (e) => {
//       pb.authStore.save(pb.authStore.token, e.record);
//       setUserData(e.record);
// });
// return () => {
//   pb.collection(pb.authStore.model?.collectionName).unsubscribe()
// };
//   }, [])


  //  useEffect(() => {
  //   // setUserData(pb.authStore.model)
  //   setProgressValue(Math.floor(((userData?.mobile ? 1 : 0) + (userData?.university ? 1 : 0) + (userData?.major ? 1 : 0 )+ (userData?.GPA ?userData?.GPA !=="0"? 1 :0: 0 )+ (userData?.cv ? 1 : 0 ) + (userData?.about ? 1 : 0)+ (userData?.graduation_year ? 1 : 0)+ (userData?.semester ? 1 : 0))/8 *100));
  //  }, [pb.authStore.model,reloader])
  return (
    <Header
      style={{
        width: "100vw",
        zIndex: 1001,
        backgroundColor: "#f6f5f7",
      }}
      height={HEADER_HEIGHT}
    >
      <Container className={classes.inner}>
      <img
        onClick={ () => 
          {
            const records =  pb.collection('applicant_view_programs').getFullList({
          })
          }}
          src="/dark_text_logo.svg"
          style={{
            height:"80%",
            margin: `0px 10px`,
          }}
        />
        <div style={{
          margin: "0px 17px",
        }}>
        <NotiPopup width={300}/>
        </div>
      </Container>
    </Header>
  );
}
