import { IconTriangleFilled } from "@tabler/icons-react";
import { tn } from "../../../translation";


export const ValueBoxes = ({
    data,
    locale
}:any)=>{


    return <div
    style={{
      display: "grid",
      justifyContent: "start",
      marginTop: "20px",
      gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
      gap: "20px",
    }}
  >
    <ValueBox
      title={"Active programs"}
      value={data?.total_current_programs ?? 0}
      isValuePercentage={false}
      percentage={PercentageBuilder(
        data?.current_programs_last_3_months,
        data?.current_programs_previous_3_months
      )}
      arrow={ArrowBuilder(
        PercentageBuilder(
          data?.current_programs_last_3_months,
          data?.current_programs_previous_3_months
        )
      )}
      color={ColorBuilder(
        PercentageBuilder(
          data?.current_programs_last_3_months,
          data?.current_programs_previous_3_months
        )
      )}
    />
    <ValueBox
      title={"Total requests"}
      value={data?.total_applications ?? 0}
      isValuePercentage={false}
      percentage={PercentageBuilder(
        data?.applications_last_3_months,
        data?.applications_previous_3_months
      )}
      arrow={ArrowBuilder(
        PercentageBuilder(
          data?.applications_last_3_months,
          data?.applications_previous_3_months
        )
      )}
      color={ColorBuilder(
        PercentageBuilder(
          data?.applications_last_3_months,
          data?.applications_previous_3_months
        )
      )}
    />
    <ValueBox
      title={"Total number of accepted candidates"}
      value={data?.total_accepted_applications ?? 0}
      isValuePercentage={false}
      percentage={PercentageBuilder2(
        data?.total_accepted_applications,
        data?.total_applications
      )}
      arrow={ArrowBuilder(
        PercentageBuilder2(
          data?.total_accepted_applications,
          data?.total_applications
        )
      )}
      color={ColorBuilder(
        PercentageBuilder2(
          data?.total_accepted_applications,
          data?.total_applications
        )
      )}
    />
    <ValueBox
      title={"Program Withdrawal Rate"}
      percentage={PercentageBuilder2(
        data?.total_withdrawn_applications,
        data?.total_applications
      )}
      isValuePercentage={false}
      arrow={ArrowBuilder(
        PercentageBuilder2(
          data?.total_withdrawn_applications,
          data?.total_applications
        )
      )}
      color={
        (PercentageBuilder2(
          data?.total_withdrawn_applications,
          data?.total_applications
        ) || 0) > 20 ? "#F88379" : (PercentageBuilder2(
          data?.total_withdrawn_applications,
          data?.total_applications
        )|| 0 ) > 10 ? "#f0b000" : "#82DFCA"
  }
    />
    <ValueBox
      title={"Program Completion Rate"}
      value={PercentageBuilder2(
        data?.total_completed_programs,
        data?.total_programs
      )} 
      isValuePercentage={true}
      // percentage={PercentageBuilder(
      //   data?.completed_programs_last_3_months,
      //   data?.completed_programs_previous_3_months
      // )}
      // arrow={ArrowBuilder(
      //   PercentageBuilder(
      //     data?.completed_programs_last_3_months,
      //     data?.completed_programs_previous_3_months
      //   )
      // )}
      // color={ColorBuilder(
      //   PercentageBuilder(
      //     data?.completed_programs_last_3_months,
      //     data?.completed_programs_previous_3_months
      //   )
      // )}
    />
    <ValueBox
      title={"Total Training Program Rewards"}
      value={`${data?.total_incentives ?? 0} ${(locale === "ar" ? " ر.س" : " SAR")}`}
      isValuePercentage={false}
      percentage={null}  // Assuming no percentage calculation is needed here
      arrow={"none"}       // Assuming no arrow is needed here
      color={"#6d6d6d"}    // Assuming a default color or style
    />
    <ValueBox
      title={"Percentage of trainees to the number of employees"}
      value={PercentageBuilder2(
        data?.number_of_employees,
        data?.total_applications
      )}
      isValuePercentage={true}
      arrow={ArrowBuilder(
        PercentageBuilder2(
          data?.number_of_employees,
          data?.total_applications
        )
      )}
      color={
        (PercentageBuilder2(
          data?.number_of_employees,
          data?.total_applications
        ) || 0) > 2 ? "#82DFCA" : (PercentageBuilder2(
          data?.number_of_employees,
          data?.total_applications
        )|| 0) > 1 ? "#f0b000" : "#F88379"
      }
  
    />
  </div>
}



const ValueBox = ({
    title,
    value,
    isValuePercentage,
    percentage,
    arrow = "up",
    color = "#82DFCA",
  }: any) => {
    return (
      <div
        style={{
          border: `1px solid ${color}`,
          borderRadius: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 20px",
          height: "80px",
        }}
      >
        <div
          style={{
            color: "#21033F",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {tn(title)}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#21033F",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {value}
            {isValuePercentage ? "%" : ""}
          </div>
          {(percentage != null || percentage != undefined) && (
            <>
              {" "}
              <div
                style={{
                  background: color,
                  width: "1px",
                  height: "40px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: color,
                  fontSize: "13px",
                  direction: "ltr",
                }}
              >
                {percentage}
                {percentage ? "%" : percentage == 0 ? "%" : ""}
              </div>
              {arrow != "none" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    transform: `rotate(${arrow != "up" ? "180deg" : "0deg"})`,
                  }}
                >
                  <IconTriangleFilled
                    style={{
                      color: color,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };
  



const PercentageBuilder = (current: any, previous: any) => {
  console.log("previouspreviouspreviousprevious", previous)
    if (previous == 0 || !previous) {
      if(current == 0){
        return 0
      }
      return null;
    }
    const percentage = Math.floor(((current - previous) / previous) * 100);
    return percentage
  };
  
  const PercentageBuilder2 = (value1:any, value2:any) => {
    if (value2 === 0 || !value2) {
      if(value1 == 0){
        return 0
      }
      return null;
    }
    return Math.floor((value1 / value2) * 100);
  };
  
  const ArrowBuilder = (value:any) => {
    if (value > 0) {
      return "up";
    }
    if (value < 0) {
      return "down";
    }
    if (value === 0 || !value) {
      return "none";
    }
  };
  
  
  const ColorBuilder = (
    value: any,
  ) => {
    if (value < 0) {
      return "#F88379";
    }
    if (value > 0) {
      return "#82DFCA";
    }
    if (value === 0 || !value) {
      return "#6d6d6d";
    }
  }
  
  