import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const ExcludeInterview = (props: any) => {
  const { setExcludeInterview }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return (
    <ActionComponent
      data={data}
      action={action}
      table={table}
      setData={async () => {
        setExcludeInterview({
          id: data.id,
          record: data,
          action: action,
          table: table,
        });
      }}
      enabled={true}
    />
  );
};
