
import { Helmet } from "react-helmet"
import { DataGrid } from "../../main_components/data-grid/DataGrid"
import { ttn, tn } from "../../translation"

export const SearchApplicants = () => {
    return (
      <>
      <Helmet>
<title>{ttn("search_applicants") + " / " + tn("Coophub")}</title>
</Helmet>
        <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "30px",
          background: "#fcfcfc",
        }}
      >
            <DataGrid name="search_applicants"/>
        </div>
        </>
    )
}