import { useContext, useEffect, useState } from "react";
import { useTimeout, useViewportSize } from "@mantine/hooks";

import { DataGrid } from "../../../main_components/data-grid/DataGrid";
import {
  ActionIcon,
  Button,
  Center,
  Loader,
  Modal,
  Progress,
  RingProgress,
  Table,
  Text,
  rem,
} from "@mantine/core";
import { tn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT, WEB_ENDPOINT } from "../../../constants";
import { TranslationContext } from "../../../context/TranslationContext";
import { motion } from "framer-motion";
import { IconCheck } from "@tabler/icons-react";
import { AppContext } from "../../../context/AppContext";
import { DataContext } from "../../../context/DataContext";
import { WithdrawalRequest } from "../../../main_components/data-grid/components/modals/applicant_action/withdrawal_request/WithdrawalRequest";
import { set } from "lodash";
import { Helmet } from "react-helmet";
import { EvaluationProgram } from "../../web/components/EvaluationProgram";
import { fv } from "../../../main_components/Functions";
const pb: any = new PocketBase(CMS_ENDPOINT);

function CurrentProgram() {
  const [evaluationOpen, setEvaluationOpen] = useState(false);
  const { locale, translation }: any = useContext(TranslationContext);
  const { reloader, setReloader } = useContext(AppContext);
  const {setProgramEvaluation}: any = useContext(DataContext);

  const [data, setData]: any = useState({});
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(0);
  const [withdrawalRequest, setWithdrawalRequest] = useState({});
  const [programsModalDetails, setProgramsModalDetails] = useState(false);
  const [coordinatorModalDetails, setCoordinatorModalDetails] = useState(false);
  const [programPlanModal, setProgramPlanModal] = useState(false)
  // useEffect(() => {
  //   if (bannerOpen) {
  //     start();
  //   }
  // }, [bannerOpen]);

  useEffect(() => {
    const record = pb
      .collection("applications_view")
      .getList(1, 10, {
        expand: "program,recruiter,company",
        filter: "main_status = 'accepted'",
      })
      ?.then((res: any) => {
        setLoading(1);
        setData(res?.items?.[0]);
        const records = pb
          .collection("program_plans_view")
          .getFullList({
            sort: "start_date",
            filter: `application = "${res?.items?.[0]?.id}"`,
            expand: "sub_department",
          })
          ?.then((res: any) => {
            setPlans(res);
          });
      });
  }, [reloader]);

  const { height, width }: any = useViewportSize();
  const { setContractViewerData }: any =
    useContext(AppContext);
  const openNewTab = () => {
    const url = WEB_ENDPOINT + "/contract_viewer"; // Replace with your desired URL
    window.open(url, "_blank", "noopener,noreferrer");
  };
  function convertToArabicNumbers(number) {
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number.toString().replace(/[0-9]/g, (digit) => arabicNumbers[digit]);
  }
  return width > 10 && loading == 1 ? (
    data?.id ? (
      <>
        <Helmet>
          <title>{tn("Current program") + " / " + tn("Coophub")}</title>
        </Helmet>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 84px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            padding: "20px",
            paddingTop: "20px",
            background: "#fcfcfc",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "1600px",
            }}
          >
            <Text
              style={{
                backgroundColor: "#f3ec78",
                backgroundImage:
                  "linear-gradient(113deg, rgba(105,220,191,1) 0%, rgba(222,140,134,1) 19%, rgba(33,3,63,1) 56%)",
                WebkitBackgroundClip: "text",
                MozBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "right",
                fontSize: "23px",
                fontStyle: "normal",
                fontWeight: "700",
                // lineHeight: "95%",
                marginBottom: "10px",
              }}
            >
              {tn("Current program")}
            </Text>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
              borderRadius: "15px",
              boxShadow: "1px 3px 30px 0px rgba(0, 0, 0, 0.13)",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#F9837C",
                    borderRadius: "5.112px",
                  }}
                  onClick={() => {
                    setWithdrawalRequest({
                      record: data,
                      id: data.id,
                      table: {
                        table: "applications",
                        name: "applications",
                      },
                    });
                  }}
                  size="xs"
                >
                  {tn("Request to withdraw from the program")}
                </Button>
              </div>
              <img
                style={{
                  width: "calc(100% - 60px)",
                  margin: "10px 10px",
                  objectFit: "contain",
                }}
                src={
                  `${CMS_ENDPOINT}/api/files/yv4pwbt2ii9l1jo/${data?.company_id}/${data?.company_logo}?token=`
                  // `https://upload.wikimedia.org/wikipedia/commons/6/6c/01_NUPCO_LOGO_DUAL_RGB-01.png`
                }
              />
              <div />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#5c5c5c",
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {tn("Program progress")}
              </Text>

              {data?.progress_percentage != "100" ? (
                <RingProgress
                  size={130}
                  thickness={16}
                  roundCaps
                  rootColor="#dddddd"
                  sections={[
                    {
                      value: parseInt(data?.progress_percentage),
                      color: "#FFB004",
                    },
                  ]}
                  label={
                    <Center>
                      <Text
                        style={{
                          fontSize: "30px",
                          fontWeight: "900",
                          color: "#5c5c5c",
                          textAlign: "center",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        %
                        {locale == "ar"
                          ? convertToArabicNumbers(
                              parseInt(data?.progress_percentage)
                            )
                          : parseInt(data?.progress_percentage)}
                      </Text>
                    </Center>
                  }
                />
              ) : (
                <RingProgress
                  size={130}
                  thickness={16}
                  roundCaps
                  sections={[{ value: 100, color: "teal" }]}
                  label={
                    <Center>
                      <ActionIcon
                        color="teal"
                        variant="light"
                        radius="1000px"
                        size="70px"
                      >
                        <IconCheck
                          style={{ width: rem(52), height: rem(52) }}
                        />
                      </ActionIcon>
                    </Center>
                  }
                />
              )}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: width > 905 ? "row" : "column",
              alignItems: "stretch",
              justifyContent: "space-between",
              maxWidth: "1400px",
            }}
          >
             <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <div
                onClick={() => setProgramsModalDetails(true)}
                 style={{
                  width: "calc(50% - 10px)",
                  border: "3px solid #FFB004",
                  height: "90px",
                  borderRadius: "10px",
                  color: "#FFB004",
                  padding: "10px",
                  backgroundImage:"url('/wave1.svg')",
                  backgroundSize: "cover",
                  backgroundPosition: "center 10px",
                  backgroundRepeat: "no-repeat",
                  fontWeight: "bold",
                }}>
                  {tn("program details")}
                </div>
                <div
                onClick={() => setCoordinatorModalDetails(true)}
                 style={{
                  width: "calc(50% - 10px)",
                  border: "3px solid #FFB004",
                  height: "90px",
                  borderRadius: "10px",
                  color: "#FFB004",
                  padding: "10px",
                  backgroundImage:"url('/wave2.svg')",
                  backgroundSize: "cover",
                  backgroundPosition: "center 10px",
                  backgroundRepeat: "no-repeat",
                  fontWeight: "bold",
                }}>
                  {tn("Program coordinator")}
                </div>
              </div>
              <div
                onClick={() => setProgramPlanModal(true)}
               style={{
                  width: "calc(100% - 0px)",
                  border: "3px solid #FFB004",
                  height: "90px",
                  borderRadius: "10px",
                  color: "#FFB004",
                  padding: "10px",
                  backgroundImage:"url('/wave3.svg')",
                  backgroundSize: "cover",
                  backgroundPosition: "center 10px",
                  backgroundRepeat: "no-repeat",
                  fontWeight: "bold",
                  marginTop: "20px",
                }}>
                  {tn("Program plan")}
                </div>
            
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: width > 905 ? "row" : "column",
              alignItems: "stretch",
              justifyContent: "space-between",
              marginTop: "20px",
              maxWidth: "1400px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "center",
                width: width > 905 ? "calc(20% - 20px)" : "100%",
              }}
            >
              <MainBox
                style={{
                  marginTop: "0px",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#5c5c5c",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "7px",
                    marginTop: "10px",
                  }}
                >
                  {tn("Program attachments")}
                </Text>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#5c5c5c",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "7px",
                      marginTop: "10px",
                    }}
                  >
                    {tn("Training request letter")}
                  </Text>
                  <Button
                    disabled={!data?.training_request_letter}
                    color="color3"
                    radius="10px"
                    onClick={() => {
                      window.open(
                        `${CMS_ENDPOINT}/api/files/${data?.collectionId}/${data?.id}/${data?.training_request_letter}?token=`
                      );
                    }}
                  >
                    {tn("Show")}
                  </Button>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    margin: "10px 0px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#5c5c5c",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "7px",
                      marginTop: "10px",
                    }}
                  >
                    {tn("Training acceptance letter")}
                  </Text>
                  <Button
                    disabled={!data?.acceptance_letter}
                    color="color3"
                    radius="10px"
                    onClick={() => {
                      window.open(
                        `${CMS_ENDPOINT}/api/files/${data?.collectionId}/${data?.id}/${data?.acceptance_letter}?token=`
                      );
                    }}
                  >
                    {tn("Show")}
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginBottom: "7px",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#5c5c5c",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "7px",
                      marginTop: "10px",
                    }}
                  >
                    {tn("Taining contract")}
                  </Text>
                  <div />
                  <Button
                    disabled={
                      !data?.contract_data ||
                      data?.contract_status != "finished"
                    }
                    color="color3"
                    radius="10px"
                    onClick={() => {
                      setContractViewerData({
                        applicant: {
                          ...data?.contract_data?.applicant,
                          religion:
                          fv(data?.contract_data?.applicant?.religion,"religion",translation)?.[locale],
                          marital_status:
                          fv(data?.contract_data?.applicant?.marital_status,"marital_status",translation)?.[locale],
                          gender:
                          fv(data?.contract_data?.applicant?.gender,"gender",translation)?.[locale],
                        },
                        company: {
                          ...data?.contract_data?.company,
                          job_title:
                            data?.contract_data?.company?.job_title?.[locale],
                        },
                        program: {
                          ...data?.contract_data?.program,
                          programs_majors:
                            data?.contract_data?.program?.programs_majors
                              ?.map((item: any) => fv(item,"major",translation)?.[locale])
                              .join(", "),
                          program_type_translated:
                          fv(data?.contract_data?.program
                              ?.program_type_translated,"program_type",translation)?.[locale],
                        },
                      });
                      openNewTab();
                    }}
                  >
                    {tn("Show")}
                  </Button>
                </div>
              </MainBox>
              <MainBox
                style={{
                  marginTop: "10px",
                  alignItems: "center",
                  opacity:
                    data?.progress_percentage == "100"
                      ? !data?.program_evaluation
                        ? "1"
                        : "0.5"
                      : "0.5",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#5c5c5c",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {tn("Program evaluation")}
                </Text>
                <Button
                  disabled={
                    data?.progress_percentage == "100"
                      ? !data?.program_evaluation
                        ? false
                        : true
                      : true
                  }
                  my="md"
                  color="color3"
                  radius="10px"
                  onClick={() => {
                    setProgramEvaluation({
                      record: data,
                      id: data.id,
                      table: {
                        table: "applications",
                        name: "applications",
                      },
                    });
                  }}
                >
                  {tn("evaluation ")}
                </Button>
             
              </MainBox>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "start",
                width: width > 905 ? "calc(80% - 20px)" : "100%",
              }}
            >
            
            </div>
          </div>
        </div>
        <Modal
          size={"500px"}
          style={{ zIndex: 10000, position: "relative" }}
          overlayProps={{
            zIndex: 2000,
            opacity: 0.55,
            blur: 3,
          }}
          styles={() => ({
            content: {
              zIndex: 10001,
              borderRadius: "10px",
              background:
                "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            },
            header: { display: "none" },
            body: { padding: "9px" },
            inner: { zIndex: 10001 },
          })}
          opened={withdrawalRequest?.record}
          onClose={() => {
            setWithdrawalRequest({});
          }}
          centered
        >
          <div
            style={{
              background: "white",
              width: "100%",
              height: "100%",
              marginTop: "9px",
              borderRadius: "5px",
              // paddingTop: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WithdrawalRequest
              data={withdrawalRequest}
              onClose={setWithdrawalRequest}
            />
          </div>
        </Modal>
              <ShowData
                opened={programsModalDetails}
                setOpened={setProgramsModalDetails}
                values={[
                  { name: tn("program title"), value: data?.expand?.program?.name },
                  { name: tn("Program type"), value:fv(data?.expand?.program?.type,"program_type",translation)?.[locale] },
                  {
                    name: tn("Program start date"),
                    value: new Date(
                      data?.date_of_commencement
                    )?.toLocaleDateString("en-GB"),
                  },
                  {
                    name: tn("Program end date"),
                    value: new Date(data?.date_of_end)?.toLocaleDateString(
                      "en-GB"
                    ),
                  },
                  { name: tn("Period"),value:fv(data?.expand?.program?.period,"period",translation)?.[locale] },
                  { name: tn("City"),value: fv(data?.expand?.program?.city,"city",translation)?.[locale] },
                ]}
              />
              <ShowData
                opened={coordinatorModalDetails}
                setOpened={setCoordinatorModalDetails}
                values={[
                  { name: tn("name"), value: data?.recruiter_name },
                  { name: tn("Email Address"), value: data?.recruiter_email },
                  { name: tn("Company mobile"), value: data?.company_tel },
                  { name: tn("Extension"), value: data?.recruiter_extension },
                ]}
              />
              <Modal
                opened={programPlanModal}
                onClose={() => setProgramPlanModal(false)}
                size="md"
                padding="20px"
                style={{ zIndex: 10001, position: "relative" }}
                overlayProps={{
                  zIndex: 10001,
                  opacity: 0.55,
                  blur: 3,
                }}
                styles={() => ({
                  content: {
                    zIndex: 10001,
                    borderRadius: "10px",
                    background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                  },
                  header: { display: "none" },
                  body: { padding: "9px" },
                  inner: { zIndex: 10001 },
                })}
                centered
              >
                <div
                  style={{
                    background: "white",
                    width: "100%",
                    height: "100%",
                    marginTop: "9px",
                    borderRadius: "5px",
                    // paddingTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                    gap: "10px",
                    padding: "20px",
                  }}
                >
                  <Text
                    fz="md"
                    style={{
                      color: "#595959",
                      fontWeight: 700,
                      background:
                        "var(--sc, linear-gradient(90deg, #21033F 0%, #69DCBF 100%))",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {tn("details")}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    {plans?.[0] ? (
                    <Table striped>
                      <thead
                        style={{
                          position: "sticky",
                          top: "0px",
                          zIndex: 100,
                          background: "#fff",
                          borderBottom: "1px solid #000000",
                        }}
                      >
                        <tr>
                          <TH>#</TH>
                          <TH>{tn("Internal departments")}</TH>
                          <TH>{tn("Beginning of the period")}</TH>
                          <TH>{tn("End of period")}</TH>
                        </tr>
                      </thead>
                      <tbody>
                        {" "}
                        {plans?.map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <TD>{index + 1}</TD>
                              <TD>{item?.expand?.sub_department?.name}</TD>
                              <TD>
                                {new Date(item?.start_date)?.toLocaleDateString(
                                  "en-GB"
                                )}
                              </TD>
                              <TD>
                                {new Date(item?.end_date)?.toLocaleDateString(
                                  "en-GB"
                                )}
                              </TD>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {tn("nothing")}
                    </div>
                  )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems
                    : "center",
                      
                      width: "100%",
                    }}
                  >
                    <Button
                      color="gray"
                      variant="outline"
                      style={{
                        fontWeight: 700,
                        fontSize: "12px",
                        borderRadius: "10px",
                        padding: "10px",
                      }}
                      onClick={() => setProgramPlanModal(false)}
                    >
                      {tn("close")}
                    </Button>
                  </div>
                </div>
              </Modal>
      </>
    ) : (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <Helmet>
          <title>{tn("Current program") + " / " + tn("Coophub")}</title>
        </Helmet>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#5c5c5c",
            textAlign: "center",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "7px",
            marginTop: "10px",
          }}
        >
          {tn("No current program")}
        </Text>
      </div>
    )
  ) : (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 84px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader size={60} />
    </div>
  );
}

const TH = (props: any) => {
  return (
    <th>
      {" "}
      <Text
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          color: "#5c5c5c",
          textAlign: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "7px",
          height: "30px",
        }}
      >
        {props.children}
      </Text>
    </th>
  );
};

const TD = (props: any) => {
  return (
    <td>
      {" "}
      <Text
        style={{
          fontSize: "12px",
          color: "#5c5c5c",
          textAlign: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "30px",
        }}
      >
        {props.children}
      </Text>
    </td>
  );
};

const MainBox = (props: any) => {
  const { height, width }: any = useViewportSize();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(79, 81, 82, 0.05)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: width > 600 ? "center" : "start",
        justifyContent: "start",
        marginTop: "20px",
        overflowX: "hidden",
        overflowY: "hidden",
        ...props.style,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
      
      </div>
      {props.children}
    </div>
  );
};

export default CurrentProgram;



const ShowData = ({
  opened,
  setOpened,
  values
}) => {

  return  <Modal
  opened={opened}
  onClose={() => setOpened(false)}
  size="md"
  padding="20px"
  style={{ zIndex: 10001, position: "relative" }}
  overlayProps={{
    zIndex: 10001,
    opacity: 0.55,
    blur: 3,
  }}
  styles={() => ({
    content: {
      zIndex: 10001,
      borderRadius: "10px",
      background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
    },
    header: { display: "none" },
    body: { padding: "9px" },
    inner: { zIndex: 10001 },
  })}
  centered
>
  <div
    style={{
      background: "white",
      width: "100%",
      height: "100%",
      marginTop: "9px",
      borderRadius: "5px",
      // paddingTop: "30px",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
      gap: "10px",
      padding: "20px",
    }}
  >
    <Text
      fz="md"
      style={{
        color: "#595959",
        fontWeight: 700,
        background:
          "var(--sc, linear-gradient(90deg, #21033F 0%, #69DCBF 100%))",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      {tn("details")}
    </Text>
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        gap: "10px",
        width: "100%",
      }}
    >
      {values?.[0] && values?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              width: "calc(50% - 20px)",
              paddingBottom: "5px",
              borderBottom: "1px solid #636363",
            }}
          >
            <Text
              fz="sm"
              style={{
                color: "#21033F",
                fontWeight: 700,
              }}
            >
              {item?.name}
            </Text>
            <Text
              fz="sm"
              style={{
                color: "#595959",
                fontWeight: 400,
              }}
            >
              {item?.value}
            </Text>
          </div>
        );
      })}
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Button
        color="gray"
        variant="outline"
        style={{
          fontWeight: 700,
          fontSize: "12px",
          borderRadius: "10px",
          padding: "10px",
        }}
        onClick={() => setOpened(false)}
      >
        {tn("close")}
      </Button>
    </div>
  </div>
</Modal>
}