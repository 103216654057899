import {
  Button,
  Checkbox,
  Divider,
  Radio,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { nanoid } from 'nanoid';

import { t, tn } from "../../../../../../../../translation";
import { useContext, useEffect, useState } from "react";
import { pb } from "../../../../../../../../config";
import { useForm } from "@mantine/form";
import { TranslationContext } from "../../../../../../../../context/TranslationContext";
import { use } from "echarts";
import _, { set } from "lodash";
import { vf } from "../../../../../../../Functions";

export const FiltrationForm = ({
  data,
  setIsEdit,
  setCurrentModel,
  filtration_tests,
  currentModel,
  setLoader,
  setFiltration_tests,
}: any) => {
  const fill_massage = tn("Please fill all questions and answers");
  const select_massage = tn("Please select at least one correct answer");
  const [loading, setLoading] = useState(false);
  const [errorMassage, setErrorMassage] = useState("");
  const { translation, locale }: any = useContext(TranslationContext);
  const filtration_test_durations = vf("filtration_test_durations");
  const is_new = currentModel =="new";
  const form = useForm({
    initialValues: is_new?
      {
        name: "",
        minimum_to_pass: "",
        duration: "",
        questions: [
          {
            question: "",
            id:nanoid(),
            answers: [
              {
                answer: "",
                correct: true,
                id:nanoid()
              },
              {
                answer: "",
                correct: false,
                id:nanoid()
              },
            ],
          },
        ],
      }
    :{
      name: data?.name ?? "",
      minimum_to_pass: data?.minimum_to_pass ?? "",
      duration: data?.duration ?? "",
      questions: data?.questions ?? [
        {
          question: "",
          id:nanoid(),
          answers: [
            {
              answer: "",
              correct: true,
              id:nanoid()
            },
            {
              answer: "",
              correct: false,
              id:nanoid()
            },
          ],
        },
      ],
    },
     
  });
  console.log("efewfewf",form.values.questions);
  useEffect(() => {
    setErrorMassage("");
  }, [form.values.questions]);

  return (
    <form
      style={{
        background: "rgba(79, 81, 82, 0.05)",
        width: "100%",
        height: "100%",
        marginTop: "9px",
        borderRadius: "5px",
        // paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {" "}
      <div
        style={{
          width: "calc(100% - 30px)",
          background: "#21033F",
          height: "7px",
          borderRadius: "522px",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "10px",
          gap: "15px",
        }}
      >
        <FormBuilder
          title={tn("Model name")}
          input={
            <TextInput
            required
              style={{ width: "calc(100% - 80px)" }}
              size="xs"
              {...form.getInputProps("name")}
              styles={() => ({
                input: {
                  border: "1px solid #000",
                  width: "100%",
                },
              })}
            />
          }
          width="calc(40% - 30px)"
        />
        <FormBuilder
          title={tn("Minimum to pass")}
          input={
            <Select
            required
              data={[
                { value: "90", label: "90%" },
                { value: "80", label: "80%" },
                { value: "70", label: "70%" },
                { value: "60", label: "60%" },
                { value: "50", label: "50%" },
                { value: "40", label: "40%" },
                { value: "30", label: "30%" },
                { value: "20", label: "20%" },
                { value: "10", label: "10%" },
              ]}
              style={{ width: "calc(100% - 100px)" }}
              size="xs"
              {...form.getInputProps("minimum_to_pass")}
              styles={() => ({
                input: {
                  border: "1px solid #000",
                  width: "100%",
                },
              })}
            />
          }
          width="calc(30% - 30px)"
        />

        <FormBuilder
          title={tn("Test duration")}
          input={
            <Select
            required
              data={filtration_test_durations?.map((d: any) => ({
                value: d.value,
                label: d?.[locale],
              }))}
              style={{ width: "calc(100% - 100px)" }}
              size="xs"
              {...form.getInputProps("duration")}
              styles={() => ({
                input: {
                  border: "1px solid #000",
                  width: "100%",
                },
              })}
            />
          }
          width="calc(30% - 30px)"
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Divider size="sm" style={{ width: "40px" }} />
        <Divider
          size="sm"
          label={tn("questions")}
          style={{ width: "calc(100% - 80px)" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          gap: "10px",
          width: "100%",
          maxHeight: "370px",
          height: "370px",
          overflowY: "scroll",
        }}
      >
        {form.values.questions?.map((question: any, index: number) => {
          return (
            <QuestionBuilder question={question} index={index} form={form} />
          );
        })}
        <QuestionBuilder
          index={form.values.questions?.length}
          form={form}
          is_new={true}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: "0px 10px",
          gap: "10px",
          margin: "10px 0px",
        }}
      >

        <Button
          loading={loading}
          // mx="xs"
          compact
          style={{
            backgroundColor: "#69DCBF",
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
          }}
          onClick={async () => {
            if(!form.values.name || !form.values.minimum_to_pass || !form.values.duration){
              setErrorMassage(fill_massage);
              return;
            }
            if (
              form.values.questions.some(
                (q: any) =>
                  q.question === "" ||
                  q.answers.some((a: any) => a.answer === "")
              )
            ) {
              setErrorMassage(fill_massage);
              return;
            }
            if (
              form.values.questions.some((q: any) =>
                q.answers.every((a: any) => !a.correct)
              )
            ) {
              setErrorMassage(select_massage);
              return;
            }
            setLoading(true);
            setErrorMassage("");
            if(is_new){
              const record = await pb
              .collection("filtration_tests")
              .create({
                name: form.values.name,
                minimum_to_pass: form.values.minimum_to_pass,
                duration: form.values.duration,
                questions: form.values.questions,
                company:pb?.authStore?.model?.company
              });
              setCurrentModel(record?.id);
            }else{
              const record = await pb
              .collection("filtration_tests")
              .update(data?.id, {
                name: form.values.name,
                minimum_to_pass: form.values.minimum_to_pass,
                duration: form.values.duration,
                questions: form.values.questions,
              });
            }
           
            setLoading(false);

            const records = await pb
              .collection("filtration_tests")
              .getFullList({
                sort: "created",
              });
            setFiltration_tests(records);

            setIsEdit(false);
          }}
        >
          {tn("Save")}
        </Button>
       {!is_new && <Button
          loading={loading}
          // mx="xs"
          compact
          style={{
            backgroundColor: "#F9837C",
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
          }}
          onClick={() => {
            setIsEdit(false);
          }}
        >
          {tn("Cancel")}
        </Button>}
                {errorMassage && <Text style={{ color: "red" }}>{errorMassage}</Text>}

      </div>
    </form>
  );
};

const FormBuilder = ({ title, input, width, opacity = "1" }: any) => {
  return (
    <div
      style={{
        width,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "5px",
        opacity: opacity,
      }}
    >
      <Text
        style={{
          color: "#4F5152",
          fontSize: "12px",
        }}
      >
        {title}
      </Text>
      {input}
    </div>
  );
};

const QuestionBuilder = ({ question, index, form, is_new }: any) => {
  const deletable = index > 0;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        padding: "0px 30px",
        marginBottom: "10px",
      }}
    >
      {index != 0 && (
        <Divider
          style={{
            width: "100%",
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            gap: "10px",
          }}
        >
          {is_new || deletable ? (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                if (is_new) {
                  form.setFieldValue(`questions.${index}`, {
                    question: "",
                    id:nanoid(),
                    answers: [
                      {
                        answer: "",
                        correct: true,
                        id:nanoid()
                      },
                      {
                        answer: "",
                        correct: false,
                        id:nanoid()
                      },
                    ],
                  });
                  return;
                } else if (deletable) {
                  form.setFieldValue(`questions`, [
                    ...form.values.questions.filter(
                      (_: any, i: any) => i !== index
                    ),
                  ]);
                }
              }}
            >
              {" "}
              <Tooltip
                style={{
                  cursor: "pointer",
                }}
                children={is_new ? ADD_SVG : deletable ? DELETE_SVG : <></>}
                label={tn("Add answer")}
              />
            </div>
          ) : (
            <div
              style={{
                width: "15px",
              }}
            />
          )}
          <FormBuilder
            opacity={is_new ? "0.5" : "1"}
            title={tn("question") + " " + (index + 1)}
            input={
              <TextInput
              placeholder={tn("Enter question")}
                disabled={is_new}
                style={{ width: "calc(100% - 65px)" }}
                size="xs"
                {...form.getInputProps(`questions.${index}.question`)}
                styles={() => ({
                  input: {
                    border: "1px solid #000",
                    width: "100%",
                  },
                })}
              />
            }
            width="calc(100% - 140px)"
          />
        </div>
        <div />
      </div>
      {question?.answers?.map((answer: any, aIndex: number) => {
        return (
          <AnswerBuilder
            answer={answer}
            aIndex={aIndex}
            index={index}
            form={form}
          />
        );
      })}
      {!is_new && (
        <AnswerBuilder
          aIndex={question?.answers?.length}
          index={index}
          form={form}
          is_new={true}
        />
      )}
    </div>
  );
};

const AnswerBuilder = ({
  answer,
  aIndex,
  index,
  form,
  is_new,
  question_is_new,
}: any) => {
  const deletable = aIndex > 1;
  if (aIndex > 4) {
    return <></>;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        width: "100%",
      }}
    >
      <div
        style={{
          width: is_new || deletable ? "90px" : "120px",
        }}
      ></div>
      {(is_new || deletable) && (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            if (is_new) {
              form.setFieldValue(`questions.${index}.answers.${aIndex}`, {
                answer: "",
                correct: false,
                id:nanoid()
              });
              return;
            } else if (deletable) {
              form.setFieldValue(`questions.${index}.answers`, [
                ...form.values.questions[index].answers.filter(
                  (_: any, i: any) => i !== aIndex
                ),
              ]);
            }
          }}
        >
          {" "}
          <Tooltip
            style={{
              cursor: "pointer",
            }}
            children={is_new ? ADD_SVG : deletable ? DELETE_SVG : <></>}
            label={tn("Add answer")}
          />
        </div>
      )}

      <FormBuilder
        opacity={is_new ? "0.5" : "1"}
        title={tn("answer") + " " + (aIndex + 1)}
        input={
          <TextInput
            placeholder={tn("Enter answer")}
            disabled={is_new}
            style={{
              width: "calc(100% - 60px)",
              opacity: is_new ? "0.5" : "1",
            }}
            size="xs"
            {...form.getInputProps(
              `questions.${index}.answers.${aIndex}.answer`
            )}
            styles={() => ({
              input: {
                border: "1px solid #000",
                width: "100%",
              },
            })}
          />
        }
        width="calc(100% - 140px)"
      />
      <div
        style={{
          width: "140px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Checkbox
          disabled={is_new}
          radius={100}
          checked={is_new ? false : answer?.correct}
          onChange={(value) => {
            //set new answer correct and remove previous answer correct

            form.setFieldValue(
              `questions.${index}.answers`,
              form.values.questions[index].answers.map((a: any, i: any) => {
                if (i === aIndex) {
                  return {
                    answer: a.answer,
                    correct: true,
                    id: a.id
                  };
                }
                return {
                  answer: a.answer,
                  correct: false,
                  id: a.id
                };
              })
            );
          }}
          size="13px"
          styles={() => ({
            label: {
              fontSize: "11px",
            },
          })}
          label={tn("The correct answer")}
        />
      </div>
    </div>
  );
};

const ADD_SVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.5917 5.88446 12.9491 4.33566 11.8067 3.19329C10.6643 2.05093 9.11555 1.40827 7.5 1.40625ZM9.84375 7.96875H7.96875V9.84375C7.96875 9.96807 7.91937 10.0873 7.83146 10.1752C7.74355 10.2631 7.62432 10.3125 7.5 10.3125C7.37568 10.3125 7.25645 10.2631 7.16855 10.1752C7.08064 10.0873 7.03125 9.96807 7.03125 9.84375V7.96875H5.15625C5.03193 7.96875 4.9127 7.91936 4.8248 7.83146C4.73689 7.74355 4.6875 7.62432 4.6875 7.5C4.6875 7.37568 4.73689 7.25645 4.8248 7.16854C4.9127 7.08064 5.03193 7.03125 5.15625 7.03125H7.03125V5.15625C7.03125 5.03193 7.08064 4.9127 7.16855 4.82479C7.25645 4.73689 7.37568 4.6875 7.5 4.6875C7.62432 4.6875 7.74355 4.73689 7.83146 4.82479C7.91937 4.9127 7.96875 5.03193 7.96875 5.15625V7.03125H9.84375C9.96807 7.03125 10.0873 7.08064 10.1752 7.16854C10.2631 7.25645 10.3125 7.37568 10.3125 7.5C10.3125 7.62432 10.2631 7.74355 10.1752 7.83146C10.0873 7.91936 9.96807 7.96875 9.84375 7.96875Z"
      fill="#21033F"
    />
  </svg>
);

const DELETE_SVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g opacity="0.85">
      <path
        d="M7.62207 1.40625C6.41684 1.40625 5.23868 1.76364 4.23657 2.43323C3.23445 3.10282 2.4534 4.05454 1.99218 5.16802C1.53096 6.28151 1.41028 7.50676 1.64541 8.68883C1.88054 9.8709 2.46091 10.9567 3.31314 11.8089C4.16537 12.6612 5.25117 13.2415 6.43324 13.4767C7.61531 13.7118 8.84056 13.5911 9.95405 13.1299C11.0675 12.6687 12.0193 11.8876 12.6888 10.8855C13.3584 9.88339 13.7158 8.70523 13.7158 7.5C13.7141 5.88436 13.0716 4.33538 11.9291 3.19295C10.7867 2.05052 9.23771 1.40796 7.62207 1.40625ZM9.82871 9.04336C9.87227 9.08691 9.90681 9.13861 9.93038 9.19552C9.95395 9.25242 9.96608 9.31341 9.96608 9.375C9.96608 9.43659 9.95395 9.49758 9.93038 9.55448C9.90681 9.61139 9.87227 9.66309 9.82871 9.70664C9.78516 9.75019 9.73346 9.78474 9.67656 9.80831C9.61965 9.83188 9.55866 9.84401 9.49707 9.84401C9.43548 9.84401 9.37449 9.83188 9.31759 9.80831C9.26069 9.78474 9.20898 9.75019 9.16543 9.70664L7.62207 8.1627L6.07871 9.70664C6.03516 9.75019 5.98346 9.78474 5.92656 9.80831C5.86965 9.83188 5.80866 9.84401 5.74707 9.84401C5.68548 9.84401 5.62449 9.83188 5.56759 9.80831C5.51069 9.78474 5.45898 9.75019 5.41543 9.70664C5.37188 9.66309 5.33733 9.61139 5.31376 9.55448C5.29019 9.49758 5.27806 9.43659 5.27806 9.375C5.27806 9.31341 5.29019 9.25242 5.31376 9.19552C5.33733 9.13861 5.37188 9.08691 5.41543 9.04336L6.95938 7.5L5.41543 5.95664C5.32748 5.86868 5.27806 5.74939 5.27806 5.625C5.27806 5.50061 5.32748 5.38132 5.41543 5.29336C5.50339 5.2054 5.62268 5.15599 5.74707 5.15599C5.87146 5.15599 5.99076 5.2054 6.07871 5.29336L7.62207 6.8373L9.16543 5.29336C9.20898 5.24981 9.26069 5.21526 9.31759 5.19169C9.37449 5.16812 9.43548 5.15599 9.49707 5.15599C9.55866 5.15599 9.61965 5.16812 9.67656 5.19169C9.73346 5.21526 9.78516 5.24981 9.82871 5.29336C9.87227 5.33691 9.90681 5.38861 9.93038 5.44552C9.95395 5.50242 9.96608 5.56341 9.96608 5.625C9.96608 5.68659 9.95395 5.74758 9.93038 5.80448C9.90681 5.86139 9.87227 5.91309 9.82871 5.95664L8.28477 7.5L9.82871 9.04336Z"
        fill="#F1623F"
      />
    </g>
  </svg>
);
