import { ltn, tn } from "../../../../../translation";
import {Button, Text} from "@mantine/core"
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../constants";
import { useContext } from "react";
import { AppContext } from "../../../../../context/AppContext";

export const  AccountDeactivate = (props:any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data , onClose} = props;
  const {reloader,setReloader} = useContext(AppContext)

     return <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"start",
        padding:"20px",
        width:"100%",

     }}>
      <Text fw={600} color="#5c5c5c">
      {data?.record?.[1]?ltn("deactivate_massages"):ltn("deactivate_massage")}
      </Text>
      <div style={{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        marginTop:"20px"

      }}>
        <Button color="red" mx={5}onClick={data?.record?.[0]?()=>{
          data?.record?.forEach(async (record:any) => {
            await pb.collection(data?.table?.table).update(record,{status:"inactive"});
          });
          setReloader(!reloader)
          onClose({})
        }:async () => {
           await pb.collection(data?.table?.table).update(data?.record?.id,{status:"inactive"});
           setReloader(!reloader)
          onClose({})
        }}>
          {tn("Deactivate")}
        </Button>
        <Button variant="outline" color="gray" mx={5} onClick={() => onClose({})}>
          {tn("Cancel")}
        </Button>
      </div>
       
     </div>
}