import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";
import { CMS_ENDPOINT } from "../../../../../../constants";

export const CompletionLetterDownload = (props: any) => {

  const { data, action, table } = props;
  return (
    <ActionComponent
    is_mobile={true}
    data={data}
    action={action}
    table={table}
    setData={async () => {
  
        window.open(
          `${CMS_ENDPOINT}/api/files/${data?.collectionId}/${data?.id}/${data?.completion_letter}?token=`,
          "_blank",
          "noreferrer"
        );
    }}
    enabled={data?.completion_letter && data?.program_evaluation?.precentage}
  />
    
  );
};
