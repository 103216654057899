import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import { TranslationContext } from "../../../../../../../../context/TranslationContext";
import { useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { DataContext } from "../../../../../../../../context/DataContext";
import { motion } from "framer-motion";
import { ActionComponent } from "../../../../../ActionComponent";

export const ApproveContract = (props: any) => {
  const { locale }: any = useContext(TranslationContext);
  const { setApproveContract }: any = useContext(DataContext);
  
  const { data, action , table } = props;

  const when_be_able = data?.contract_issuance_status != "waiting_for_signature"
  return (
    <ActionComponent
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setApproveContract({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
    }}
    enabled={when_be_able}
  />
  );
};
