import { useContext, useEffect, useState } from "react";
import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import PocketBase from "pocketbase";
import { Icon } from "@iconify/react";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { IconAlertTriangle, IconSquareRoundedX } from "@tabler/icons-react";
import { ltn } from "../../../../../../translation";
import { ActionComponent } from "../../../ActionComponent";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { DataContext } from "../../../../../../context/DataContext";
import { BannersContext } from "../../../../../../context/BannersContext";
import { pb } from "../../../../../../config";
import { useNavigate } from "react-router-dom";

interface ApplyForProgramProps {
  data: {
    id: string;
    [key: string]: any;
  };
  action: string;
  table: string;
}

export const ApplyForProgram = ({
  data,
  action,
  table,
}: ApplyForProgramProps) => {
  const { setApplyForProgram }: any = useContext(DataContext);

  const { setTopBanner }: any = useContext(BannersContext);
  const messageTaken = ltn("applied_already");
  const messageUserData = ltn("fill_your_data");
  const messageAccepted = ltn("accepted_already");
  const messageAcceptedAnswer = ltn("accepted_already_answer");
  const GPA_not_enough = ltn("GPA_not_enough");
  const university_not_in_program = ltn("university_not_in_program");
  const case_study_not_in_program = ltn("case_study_not_in_program");
  const major_not_in_program = ltn("major_not_in_program");
  const degree_not_in_program = ltn("degree_not_in_program");
  const navigate = useNavigate();

  return (
    <ActionComponent
      is_mobile={true}
      data={data}
      action={action}
      table={table}
      setData={async () => {
       
        if (
          await handleApplyInProgramClick({
            setTopBanner,
            messages: {
              messageTaken,
              messageUserData,
              messageAccepted,
              messageAcceptedAnswer,
              GPA_not_enough,
              university_not_in_program,
              case_study_not_in_program,
              major_not_in_program,
              degree_not_in_program,
            },
            navigate,
            data,
          })
        ) {
          setApplyForProgram({ id: data.id, record: data, action, table });
        }
      }}
      enabled={true}
    />
  );
};

interface HandleApplyInProgramParams {
  setTopBanner: (banner: {
    message: string;
    color: string;
    top: string;
    backgroundColor: string;
    icon: JSX.Element;
  }) => void;
  messages: { [key: string]: string };
  navigate: (path: string) => void;
  data: any; // Consider using a more specific type
}

export const handleApplyInProgramClick = async ({
  setTopBanner,
  messages,
  navigate,
  data,
}: HandleApplyInProgramParams) => {
  const applications = await pb
  .collection("applications")
  .getFullList({});
  const answers = await pb.collection("answers").getFullList({
    filter: `filtration_test = '${data.filtration_test}' && program = '${data.id}'`,
  });
  const updateBanner = (message: string, icon: JSX.Element) => {
    setTopBanner({
      message,
      color: "#E77C40",
      top: "83px",
      backgroundColor: "rgba(255, 246, 239, 0.90)",
      icon,
    });
  };

  const {
    university,
    major,
    GPA,
    semester,
    graduation_year,
    mobile,
    cv,
    GPA_out_of,
    case_study,
    degree,
  } = pb.authStore.model;
  const isApplied = applications?.some(
    (app: any) => app.program === data.id && app.main_status !== "accepted"
  );
  if (
    university &&
    major &&
    GPA &&
    parseFloat(GPA) > 0 &&
    semester &&
    graduation_year &&
    mobile &&
    cv
  ) {
    if (
      (GPA_out_of === "4" && data?.GPA_4 > GPA) ||
      (GPA_out_of === "5" && data?.GPA_5 > GPA)
    ) {
      updateBanner(messages?.GPA_not_enough, <IconSquareRoundedX />);
      return false;
    }
    if (
      !data?.universities
        .includes(university) &&
      data?.universities?.[0] != "all_universities"
    ) {
      updateBanner(messages?.university_not_in_program, <IconSquareRoundedX />);
      return false;
    }
    if (
      !data?.target_educational_segment
        .includes(case_study)
    ) {
      updateBanner(messages?.case_study_not_in_program, <IconSquareRoundedX />);
      return false;
    }
    console.log("Dddedewfewfew", data, major);

    if (!data?.major.includes(major)) {
      updateBanner(messages?.major_not_in_program, <IconSquareRoundedX />);
      return false;
    }
    if (!data?.degree.includes(degree)) {
      updateBanner(messages?.degree_not_in_program, <IconSquareRoundedX />);
      return false;
    }

    const isAcceptedOrInReview = applications.some((app) =>
      ["accepted", "document_review"].includes(app.main_status)
    );

    if (isAcceptedOrInReview) {
      updateBanner(messages?.messageAccepted, <IconSquareRoundedX />);
      return false;
    } else if (!isApplied) {
      if (answers?.[0]) {
        updateBanner(messages?.messageAcceptedAnswer, <IconSquareRoundedX />);
        return false;
      } else {
        return true;
      }
    } else {
      updateBanner(messages?.messageTaken, <IconSquareRoundedX />);
      return false;
    }
  } else {
    updateBanner(messages?.messageUserData, <IconAlertTriangle />);
    navigate("/applicant/profile");
    return false;
  }
};
