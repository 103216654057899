import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import { TranslationContext } from "../../../../../context/TranslationContext";
import { useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { DataContext } from "../../../../../context/DataContext";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const EditCompany = (props: any) => {
  const { locale }: any = useContext(TranslationContext);
  const { setDeleteAccount: setDeleteAccount }: any = useContext(DataContext);

  const { data, action, table } = props;
  const navigate = useNavigate();
  return (
    <div style={{ width:"100%",
    height:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"}}>
      {table?.acions_type?.name== "menu" ? (
        <Menu.Item
          color={action?.second_color}
          icon={<Icon icon={action?.icon} />}
          onClick={() => {
            navigate(`/admin/compnaies_management/${data.id}`);
          }}
        >
          {action?.["label_" + locale]}
        </Menu.Item>
      ) : (
        <Tooltip offset={-8}label={action?.["label_" + locale]}>
          <motion.div
            whileHover={{ scale: 1.1 }}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              margin: "0px 3px",
            }}
            onClick={() => {
              navigate(`/admin/compnaies_management/${data.id}`);
            }}
          >
            <Icon
              style={{ fontSize: "15px" }}
              icon={action?.icon}
              color={action?.color}
            />
          </motion.div>
        </Tooltip>
      )}
    </div>
  );
};
