import { createContext, useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../constants";

export const UserContext: any = createContext(null);

function setLocalStorage(key: any, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
}

function getLocalStorage(key: any, initialValue: any) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
}
const UserContextProvider = ({ children }: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);


  const [companyRequest, setCompanyRequest,]: any = useState(() =>
    getLocalStorage("companyRequest", null)
  );
  useEffect(() => {
    setLocalStorage("companyRequest", companyRequest);
  }, [companyRequest]);

  useEffect(() => {
    const record = pb
      .collection("companies")
      .getOne(pb?.authStore?.model?.company, {
        // expand: 'relField1,relField2.subRelField',
      })
      .then((record: any) =>{ setLocalStorage("company", record)
    });
  }, []);
// 
 

  const [userCurrentData, setUserCurrentData] = useState(getLocalStorage("userCurrentData", null));
  
useEffect(() => {
  setLocalStorage("userCurrentData", userCurrentData);
}, [userCurrentData]);

  return (
    <UserContext.Provider
      value={{
      
        companyRequest, setCompanyRequest,
        userCurrentData,setUserCurrentData
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
