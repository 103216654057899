import {
  Checkbox,
  Divider,
  FileInput,
  MultiSelect,
  Select,
  Slider,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { tn } from "../../../../../../../translation";
import { map } from "lodash";
import { vf } from "../../../../../../Functions";

export const LastForm = ({ form, locale, translation, disabled}:any) => {
  const university = vf("university");
  const skills = vf("skills");
  return (
    <div
    style={{
      width: "calc(50% - 10px)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "start",
      background: "rgba(79, 81, 82, 0.05)",
      borderRadius: "10px",
      marginTop: "",
    }}
  >
    <div
      style={{
        width: "93%",
        background: "#21033F",
        height: "7px",
        borderRadius: "522px",
      }}
    />
   
  

      <Divider
       
        style={{ width: "calc(100% - 20px)",
        marginTop: "5px",
       }}
        label={tn("required skills")}
        labelPosition="center"
      />
     

     <MultiSelectField
     padding="0px 10px"
     searchable
     disabled={disabled}
     marginTop="-10px"
            description="Identify the essential personal and professional skills required of applicants"
            label="Personal and professional skills required"
            error={form.errors?.required_skills}
            inputProps={form.getInputProps("required_skills")}
            data={mapOptions(skills, locale, `name`)}
          />
 <Divider
       mb="5px"
       style={{ width: "calc(100% - 20px)",
       marginTop: "15px",
      }}
       label={tn("Additional instructions")}
       labelPosition="center"
     />
      <TextAreaField
      disabled={disabled}
              padding="0px 10px"
              error={form.errors?.additional_instructions}
              inputProps={form.getInputProps("additional_instructions")}
            />
             <Divider
       mb="5px"
       style={{ width: "calc(100% - 20px)",
       marginTop: "15px",
      }}
       label={tn("Program-specific attachments")}
       labelPosition="center"
     />
      <FileField
      disabled={disabled}
     marginTop="-10px"
            padding="0px 10px"
            label="attachment 1"
            error={form.errors?.attachment_1}
            inputProps={form.getInputProps("attachment_1")}
          />
           <FileField
           disabled={disabled}
     marginTop="-10px"
            padding="0px 10px"
            label="attachment 2"
            error={form.errors?.attachment_2}
            inputProps={form.getInputProps("attachment_2")}
          />
<Divider
       mb="5px"
       style={{ width: "calc(100% - 20px)",
       marginTop: "15px",
      }}
       label={tn("Candidate filter tools")}
       labelPosition="center"
     />
     
      <div
        onClick={() => {
          if(!disabled){
            form?.setFieldValue("allUniversities",!form?.values?.allUniversities)

          }
        }}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
          padding: "0px 10px",
          margin: "10px 0px 0px",
          cursor: "pointer",
        }}
      >
        <Checkbox
        disabled={disabled}
        size="xs"
          checked={form?.values?.allUniversities}
          style={{
            margin: "0px 0px 0px",
          }}
        />
        <Text color={form?.values?.allUniversities ? "black" : "dimmed"} mx="xs">
          {tn("all universities")}
        </Text>
      </div>
      <div
       
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
      
        <MultiSelectField
        
          data={
            mapOptions(university, locale, `name`) || []
          }
          label="Target universities"
          description="Choose the universities whose students are targeted for this program"
          error={form?.errors?.universities}
          inputProps={form.getInputProps("universities")}
          withAsterisk
          disabled={form?.values?.allUniversities || disabled}
        />
       
      </div>

      <div
        style={{
          height: "82px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px 0px 0px",
            padding: "0px 10px",
          }}
        >
          <Text fz="xs">{tn("Academic average of 5")}</Text>
          <Slider
          disabled={disabled}
            // onChangeEnd={(e) =>
            //   form?.setFieldValue("academic_average_5", e)
            // }
            {...form.getInputProps("academic_average_5")}
            defaultValue={3}
            min={0}
            max={5}
            step={0.1}
            // inverted
            thumbSize={12}
            styles={{
              thumb: {
                borderWidth: 0,
                background: "white",
                fill: "var(--neutral-white, #FFF)",
                filter:
                  "drop-shadow(0px 0.5px 1.8px rgba(0, 0, 0, 0.351))",
              },
            }}
            size="xs"
            style={{
              width: "70%",
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px 0px",
            padding: "0px 10px",
          }}
        >
          <Text fz="xs">{tn("Academic average of 4")}</Text>
          <Slider
          disabled={disabled}
            // onChangeEnd={(e) =>
            //   form?.setFieldValue("academic_average_4", e)
            // }
            {...form.getInputProps("academic_average_4")}
            defaultValue={3}
            min={0}
            // inverted
            max={4}
            step={0.1}
            size="xs"
            thumbSize={12}
            styles={{
              thumb: {
                borderWidth: 0,
                background: "white",
                fill: "var(--neutral-white, #FFF)",
                filter:
                  "drop-shadow(0px 0.5px 1.8px rgba(0, 0, 0, 0.351))",
              },
            }}
            style={{
              width: "70%",
            }}
          />
        </div>
      </div>
    </div>
  );
};
const FileField = ({
  description,
  label,
  error,
  inputProps,
  withAsterisk = false,
  padding,
  disabled,
  marginTop,
}: any) => (
  <div
    style={{
      width: "100%",
      padding: padding ? padding : "0px 0px",
      marginTop: marginTop ? marginTop : "0px",
    }}
  >
    <FileInput
    clearable
      disabled={disabled}
      label={tn(label)}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      {...inputProps}
      size="xs"
      style={{ width: "100%" }}
    />
    <Text mt="2px" px="5px" fz="8px" color="#868e96">
      {description?tn(description):""}
    </Text>
  </div>
);
const TextField = ({
  description,
  label,
  error,
  inputProps,
  withAsterisk = false,
  padding,
  disabled,
  marginTop,
}: any) => (
  <div
    style={{
      width: "100%",
      padding: padding ? padding : "0px 0px",
      marginTop: marginTop ? marginTop : "0px",
    }}
  >
    <TextInput
      disabled={disabled}
      label={tn(label)}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      {...inputProps}
      size="xs"
      style={{ width: "100%" }}
    />
    <Text mt="2px" px="5px" fz="8px" color="#868e96">
      {tn(description)}
    </Text>
  </div>
);
const TextInputStyle = (error: any) => ({
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  error: {
    marginBottom: "-14px",
    transform: "translate(4px, 13px)",
    // fontSize: "8px",
  },
});
const SelectField = ({
  data,
  label,
  description,
  error,
  inputProps,
  withAsterisk = false,
  disabled,
}: any) => (
  <div
    style={{
      width: "100%",
    }}
  >
    <Select
      disabled={disabled}
      data={data}
      label={tn(label)}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      {...inputProps}
      size="xs"
      style={{ width: "100%" }}
    />
    <Text mt="2px" px="5px" fz="8px" color="#868e96">
      {tn(description)}
    </Text>
  </div>
);
const TextAreaField = ({
  description,
  label,
  error,
  inputProps,
  withAsterisk = false,
  padding,
  disabled
}: any) => (
  <div
    style={{
      width: "100%",
      padding: padding ? padding : "0px 0px",
    }}
  >
    <Textarea
    disabled={disabled}
      label={label ? tn(label) :""}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      {...inputProps}
      size="xs"
      style={{ width: "100%" }}
    />
    <Text mt="2px" px="5px" fz="8px" color="#868e96">
      {description?tn(description):""}
    </Text>
  </div>
);

const MultiSelectField = ({
  data,
  label,
  description,
  error,
  inputProps,
  withAsterisk = false,
  maxValues,
  searchable,
  disabled,
  padding
}: any) => (
  <div
    style={{
      width: "100%",
     padding: padding ? padding : "0px 0px",
    }}
  >
    <MultiSelect
      disabled={disabled}
      maxValues={maxValues}
      searchable={searchable}
      data={data}
      label={tn(label)}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      {...inputProps}
      size="xs"
      style={{ width: "100%" }}
    />
    <Text mt="2px" px="5px" fz="8px" color="#868e96">
      {tn(description)}
    </Text>
  </div>
);
const mapOptions = (items: any, labelField: any, valueField: any) =>
  items.map((item: any) => ({
    value: item[valueField],
    label: item[labelField],
  }));

const addIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M7.94239 1.40625C6.73716 1.40625 5.55899 1.76364 4.55688 2.43323C3.55477 3.10282 2.77372 4.05454 2.31249 5.16802C1.85127 6.28151 1.7306 7.50676 1.96573 8.68883C2.20085 9.8709 2.78123 10.9567 3.63345 11.8089C4.48568 12.6612 5.57148 13.2415 6.75355 13.4767C7.93563 13.7118 9.16088 13.5911 10.2744 13.1299C11.3878 12.6687 12.3396 11.8876 13.0092 10.8855C13.6787 9.88339 14.0361 8.70523 14.0361 7.5C14.0341 5.88446 13.3915 4.33566 12.2491 3.19329C11.1067 2.05093 9.55793 1.40827 7.94239 1.40625ZM10.2861 7.96875H8.41114V9.84375C8.41114 9.96807 8.36175 10.0873 8.27384 10.1752C8.18593 10.2631 8.06671 10.3125 7.94239 10.3125C7.81807 10.3125 7.69884 10.2631 7.61093 10.1752C7.52302 10.0873 7.47364 9.96807 7.47364 9.84375V7.96875H5.59864C5.47432 7.96875 5.35509 7.91936 5.26718 7.83146C5.17927 7.74355 5.12989 7.62432 5.12989 7.5C5.12989 7.37568 5.17927 7.25645 5.26718 7.16854C5.35509 7.08064 5.47432 7.03125 5.59864 7.03125H7.47364V5.15625C7.47364 5.03193 7.52302 4.9127 7.61093 4.82479C7.69884 4.73689 7.81807 4.6875 7.94239 4.6875C8.06671 4.6875 8.18593 4.73689 8.27384 4.82479C8.36175 4.9127 8.41114 5.03193 8.41114 5.15625V7.03125H10.2861C10.4105 7.03125 10.5297 7.08064 10.6176 7.16854C10.7055 7.25645 10.7549 7.37568 10.7549 7.5C10.7549 7.62432 10.7055 7.74355 10.6176 7.83146C10.5297 7.91936 10.4105 7.96875 10.2861 7.96875Z"
      fill="#ffc038"
    />
  </svg>
);
