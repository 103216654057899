import { ltn, tn, vtn } from "../../../../../../../translation";
import {
  Button,
  Center,
  FileButton,
  FileInput,
  Text,
  rem,
} from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import { useContext } from "react";
import { AppContext } from "../../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";
import { MIME_TYPES } from "@mantine/dropzone";
import { IconPhoto, IconUpload } from "@tabler/icons-react";
import { isNotEmpty, useForm } from "@mantine/form";

export const UploadDocuments = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);

  const { reloader, setReloader } = useContext(AppContext);
  const validate_massage = vtn("required_field");
  const form = useForm({
    initialValues: {
      letter: "",
    },
    validate: {
      letter: (value) => (value?.name ? null : validate_massage),
    },
  });
  return (
    <form
      onSubmit={form.onSubmit((value) => {
        const formData = new FormData();
        formData.append("training_request_letter", value?.letter);
        const record = pb
          .collection("applications")
          .update(data?.id, formData)
          .then((res: any) => {
            const record = pb
              .collection("applications")
              .update(data?.id, {
                document_status: "under_review",
              })
              .then((res: any) => {
                setReloader(!reloader);
                onClose();
              });
          })
          .catch((err: any) => {
            console.log("err", err);
          });
      })}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "30px",
        width: "100%",
      }}
    >
      <Text
        style={{
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "bold",
          color: "#424242",
          marginBottom: "20px",
        }}
      >
        {tn("Upload a training request letter")}
      </Text>

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            // fontWeight:"bold",
            color: "#424242",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          {ltn("upload_letter_massage")}
        </Text>
        <div
          style={{
            // border: "1px solid #21033Fdc",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            justifyContent: "center",
          }}
        >
          <FileButton
              {...form.getInputProps("letter")}
              // multiple
              accept={MIME_TYPES.pdf}
            >
              {(props) => (
                <Button
                  style={{
                    background: "#FFB004",
                    borderRadius: "5.112px",
                    height: "30px",
                  }}
                  {...props}
                >
                  {tn("Upload")}
                </Button>
              )}
            </FileButton>
        </div>
        <Text
          style={{
            color: "#393939",
            textAlign: "center",
            fontFamily: "IBM Plex Sans Arabic",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "28px",
            marginTop: "10px",
          }}
        >
          {
            form?.values?.letter?.name
          }
        </Text>
        <Text
          style={{
            color: "#FFB004",
            textAlign: "center",
            fontFamily: "IBM Plex Sans Arabic",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "28px",
            marginTop: form?.errors?.letter ? "20px" : "0px",
          }}
        >
          {tn("pdf massage")}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Button
          compact
          type="submit"
          size="lg"
          style={{
            background: "#69DCBF",
            borderRadius: "5.112px",
            width: "100px",
            margin: "0px 10px",
          }}
        >
          {tn("Send")}
        </Button>

        <Button
          compact
          size="lg"
          onClick={() => {
            onClose();
          }}
          style={{
            background: "#F9837C",
            borderRadius: "5.112px",
            width: "100px",
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </form>
  );
};
