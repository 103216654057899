// import { pb } from "../../../../../config";

// export async function AfterExclude(application: any) {
//   try {
//     const programId = application?.program;
//     if (!programId) throw new Error("Program ID is missing from the application.");

//     const program = await pb.collection("programs").getOne(programId);
//     if (!program) throw new Error("Program not found.");

//     const applications = await pb.collection("applications").getFullList({
//       sort: "-created",
//       filter: `program = "${program.id}"`,
//     });

//     const allApplicationsExcludedOrRejected = applications.every(
//       (item: any) =>
//         ["excluded", "rejected", "withdrawn", "canceled_program"].includes(item?.main_status)
//     );

//     const programLastSubmissionDatePassed =
//       new Date(program?.last_submission_date) < new Date();

//     if (allApplicationsExcludedOrRejected) {
//       if (programLastSubmissionDatePassed) {  
//         await pb.collection("programs").update(program.id, {
//           status: "canceled",
//         });
//       } else if (program.status === "current") {
//         await pb.collection("programs").update(program.id, {
//           status: "under_the_procedure",
//         });
//       }

//     }
//   } catch (error) {
//     console.error("Error in AfterExclude:", error.message);
//   }
//   return ;
// }
import { pb } from "../../../../../config";

export async function AfterExclude(application: any) {
  const application_veiw = await pb.collection('applications_view').getOne(application?.id);
  console.log("applicationee_veiw",application_veiw);
  if(application_veiw?.program_must_be_canceled){
    await pb.collection("programs").update(application?.program, {
      status: "canceled",
    });
  }
  if(application_veiw?.program_must_be_under_the_procedure){
    await pb.collection("programs").update(application?.program, {
      status: "under_the_procedure",
    });
  }
  return ;
}
