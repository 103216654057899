export const CMS_ENDPOINT = "https://api.coophub.co";
export const WEB_ENDPOINT = "https://app.coophub.co"





// export const WEB_ENDPOINT = "https://app-test.coophub.co"
// export const CMS_ENDPOINT = "https://api-test.coophub.co"






// export const CMS_ENDPOINT = "https://api-test.coophub.co"
// export const WEB_ENDPOINT = "http://localhost:5173"
