import { useContext } from "react";
import PocketBase from "pocketbase";
import alasql from "alasql";
import { IconSquareRoundedX } from "@tabler/icons-react";
import { SelectableActionComponent } from "../SelectableActionComponent";
import { DataContext } from "../../../../context/DataContext";
import { BannersContext } from "../../../../context/BannersContext";
import { CMS_ENDPOINT } from "../../../../constants";
import { ltn } from "../../../../translation";

export const AcceptWaitingInterview = (props: any) => {
  const { setAcceptWaitingInterview }: any = useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);

  const pb = new PocketBase(CMS_ENDPOINT);
  const { selectedRecords, action, table } = props;

  const dialogMessage = selectedRecords?.length > 1 
    ? ltn("applicants_view_programs_full") 
    : ltn("applicant_view_programs_full");
  const dialogMessageAccepted = ltn("applicant_accepted");

  const fetchProgramsData = async (programIds: string[]) => {
    return pb.collection("applicant_view_programs").getList(1, 50, {
      filter: programIds.map((id) => `id = "${id}"`).join(" || "),
    });
  };

  const handleAction = async () => {
    try {
      const applicationsResponse = await pb.collection("applications_view").getList(1, 50, {
        filter: selectedRecords.map((id: string) => `id = "${id}"`).join(" || "),
      });

      const programIds = applicationsResponse.items.map((record: any) => record.program);
      const programsResponse = await fetchProgramsData(programIds);

      const allSeatsAvailable = programsResponse.items.every((program) =>
        alasql(`select * from ? where program = ?`, [
          applicationsResponse.items,
          program.id,
        ]).length <= program.free_seats
      );

      if (allSeatsAvailable) {
        const applicant = await pb.collection("applicants_view").getOne(selectedRecords[0]);

        if (applicant?.number_of_accepted_applications === 0) {
          setAcceptWaitingInterview({
            id: selectedRecords[0],
            record: selectedRecords,
            action,
            table,
          });
        } else {
          setTopBanner({
            message: dialogMessageAccepted,
            color: "#c02d2d",
            top: "83px",
            backgroundColor: "#fff2f2eb",
            icon: <IconSquareRoundedX />,
            duration: 5000,
          });
        }
      } else {
        setTopBanner({
          message: dialogMessage,
          color: "#c02d2d",
          top: "83px",
          backgroundColor: "#fff2f2eb",
          icon: <IconSquareRoundedX />,
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Error processing interview acceptance:", error);
      // Optionally, set an error banner here.
    }
  };

  return (
    <SelectableActionComponent
      selectedRecords={selectedRecords}
      action={action}
      table={table}
      setData={handleAction}
      enabled={true}
    />
  );
};
