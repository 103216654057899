import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { isNotEmpty, useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../../context/AppContext";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { BannersContext } from "../../../context/BannersContext";
import { IconCheck, IconX } from "@tabler/icons-react";
import { Helmet } from "react-helmet";

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#21033F",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",

    //
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
  },

  title: {
    color: "#69DCBF",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
}));
 function Login() {
  const { setTopBanner, setDialogBanner }: any = useContext(BannersContext);
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [isValidatedError, setIsValidatedError] = useState(false);
  const navigate = useNavigate();
  const [isError, setisError]: any = useState(false);
  const [buttonLoading, setButtonLoading]: any = useState(false);

  const form: any = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: isNotEmpty(vtn("email_empty")),
      password: isNotEmpty(vtn("password_empty")),
      // email: (value) =>
      //   value !== "" ? null : "please write your email or usename",
      // password: (value) => (value !== "" ? null : "please write your password"),
    },
  });

  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale } = useContext(TranslationContext);

  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  const errorMassage = tn("Invalid email or password");
  const errorValidatedMassage = tn("Please verify your email first");
  const dialogMassage = tn("Activation message sent");
  return (
     <>
   <Helmet>
      <title>{tn("Login") + " / " + tn("Coophub")}</title>
    </Helmet>
    <form
      onSubmit={form.onSubmit(async () => {
        // if(form.isValid()){
        try {
          const authData = await pb
            .collection("applicants")
            .authWithPassword(
              form.values.email?.toLowerCase(),
              form.values.password
            )
            .then((res: any) => {
              setButtonLoading(false);
              // if (res.record.full_info) {
              //   navigate("/applicant/programs_search");
              // } else {
              //   navigate("/applicant/full_info");
              // }
              if (res?.record.verified) {
                navigate("/applicant/programs_search");
              } else {
                setIsValidatedError(true);
                pb.authStore.clear();
                setButtonLoading(false);
              }
            });
        } catch (err) {
          setisError(true);
          setButtonLoading(false);
        }
        // }
      })}
    >
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "60px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text className={classes.title}>{tn("you are welcome in")}</Text>
              <a href="https://www.coophub.co"
           >  <img style={{ height: "93px" }} src="/white_text_logo.png" /></a> 
            </div>
            <div
              style={{
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  background: "rgba(105, 220, 191, 0.77)",
                  width: "208px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "16px",
                  margin: width > 500 ? "0px 20px" : "0px 7px",
                  cursor: "pointer",
                }}
              >
                {tn("Login")}
              </div>
              <div
                style={{
                  width: "208px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#8c8c8c",
                  fontSize: "16px",
                  margin: width > 500 ? "0px 20px" : "0px 7px",
                  border: "1px solid #8c8c8c",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/applicant/register")}
              >
                {tn("Create a new account")}
              </div>
            </div>
            <div>
              <TextInput
                {...form.getInputProps("email")}
                mt={90}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#69DCBF",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "#21033F",
                    borderBottom: "1px solid #69dcbf86",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                    },
                  },
                })}
                label={tn("Email Address")}
                placeholder="hello@coophub.co"
                size="md"
              />
              <PasswordInput
                {...form.getInputProps("password")}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#69DCBF",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "#21033F",
                    borderBottom: "1px solid #69dcbf9a",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                    },
                  },
                  innerInput: {
                    color: "white",
                  },
                })}
                label={tn("Password")}
                placeholder={tn("Your password")}
                mt={50}
                size="md"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Checkbox
                  defaultChecked={true}
                  label={tn("Remember me")}
                  mt="xl"
                  size="md"
                  styles={() => ({
                    label: { cursor: "pointer", color: "white" },
                    input: {
                      cursor: "pointer",
                      "&:checked": {
                        backgroundColor: "#69DCBF",
                        border: "0px solid #69DCBF",
                      },
                    },
                  })}
                />
                <Anchor<"a">
                  color="#FFB004"
                  href="#"
                  weight={700}
                  onClick={() => navigate("/applicant/forgot_password")}
                >
                  {tn("Forgot Password?")}
                </Anchor>
              </div>
              <Button
                loading={buttonLoading}
                onClick={() => {
                  if (form?.values?.email && form?.values?.password) {
                    setButtonLoading(true);
                  }
                }}
                type="submit"
                style={{
                  borderRadius: "5.112px",
                  height: "70px",
                  backgroundColor: "rgba(105, 220, 191, 0.77)",
                  fontSize: "16px",
                }}
                fullWidth
                mt="xl"
                size="md"
              >
                {tn("Login")}
              </Button>
              {!buttonLoading && isError && (
                <Text mt="md" size="13px" color="red">
                  {errorMassage}
                </Text>
              )}
              {!buttonLoading && isValidatedError && (
                <Text mt="md" size="13px" color="red">
                  {errorValidatedMassage}
                  {"  "}
                  <span>
                    <Anchor
                      onClick={async () => {
                        try {
                          await pb
                            .collection("applicants")
                            .requestVerification(form.values.email)
                            .then(() => {
                              setDialogBanner({
                                message: dialogMassage,
                                color: "green",
                                backgroundColor: "#9be1d0",
                                icon: (
                                  <IconCheck
                                    style={{
                                      margin: "0px 10px",
                                    }}
                                  />
                                ),
                              });
                            });
                        } catch (err) {
                          setDialogBanner({
                            message: "Something went wrong",
                            color: "red",
                            backgroundColor: "#e19b9b",
                            icon: (
                              <IconX
                                style={{
                                  margin: "0px 10px",
                                }}
                              />
                            ),
                          });
                        }
                      }}
                      color="#ff9900"
                    >
                      {tn("Click here to send the verification link")}
                    </Anchor>
                  </span>
                </Text>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
                <a style={{textDecoration: "none"}} target="_blank" href="https://www.coophub.co/terms">
                  <Text  color="#ffffff" fz="16px" weight={700}>
                    {tn("terms and conditions")}
                  </Text>
                </a>
                {divider}
                <a style={{textDecoration: "none"}} target="_blank" href="https://www.coophub.co/terms">
                  <Text  color="#ffffff" fz="16px" weight={700}>
                    {tn("Usage policy")}
                  </Text>
                </a>
                {divider}
                <Text color="#ffffff" fz="16px" weight={700}>
                  {tn("All rights are save")}
                </Text>
            </div>
          </div>
        </Paper>

        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/images/image1.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(40%)",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `100%`,
              background:
                "linear-gradient(312deg, rgba(2,0,36,1) 0%, rgba(88,170,162,1) 0%, rgba(183,254,247,0.4724483543417367) 100%)",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundImage: "url(/shapes/login_shape.svg)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom center",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "40px",
                color: "white",
                fontWeight: 700,
                textAlign: "left",
                padding: "270px 0px 0px 0px",
              }}
            >
              خطوتك الأولى
              <br /> لمستقبل مهني واعد
            </div>
          </div>
        </div>
      </div>
    </form>
    </>

  );
}

export default Login;
