import { Helmet } from "react-helmet"
import { DataGrid } from "../../../main_components/data-grid/DataGrid"
import { ttn, tn } from "../../../translation"
import { DataGridMobile } from "../../../main_components/data-grid/DataGridMobile"

 const TrainingContract = () => {
    return (
      <>
        <Helmet>
        <title>{ttn("training_contract") + " / " + tn("Coophub")}</title>
      </Helmet>
        <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "30px",
          background: "#fcfcfc",
        }}
      >
            {/* <DataGrid name="HR_recruiters_manage"/> */}
            <DataGridMobile name="training_contract"/>

        </div>
        </>
    )
}

export default TrainingContract;