import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Menu,
  Text,
  Anchor,
  Dialog,
  Modal,
  rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../context/AppContext";
import { TranslationContext } from "../../context/TranslationContext";
import { lt, ltn, tn } from "../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { UserContext } from "../../context/UserContext";
import { IconEdit, IconRepeat, IconCheck } from "@tabler/icons-react";

import { useTimeout } from "@mantine/hooks";

const useStyles = createStyles((theme):any => ({
  wrapper: {
    maxHeight: "100vh",
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",

    //
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
  },

  title: {
    color: "#69DCBF",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  subtitle: {
    color: "#21033f",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    maxWidth: "300px",
  },
}));
export function RegisterRegisterSuccess() {
  const pb: any = new PocketBase(CMS_ENDPOINT);

  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale }:any = useContext(TranslationContext);

  const divider = (
    <div
      style={{
        width: width < 500 ? "70%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  const errorMassage = tn("Invalid email or password");

  const texts3 = [
    tn("terms and conditions"),
    tn("Usage policy"),
    tn("All rights are save"),
  ];
  const { userCurrentData, setUserCurrentData }:any = useContext(UserContext);
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { start, clear } = useTimeout(() => setDialogOpen(false), 3000);
  useEffect(start, [dialogOpen]);

  const [email, setEmail] = useState(userCurrentData?.email);
  useEffect(() => {
    if (!userCurrentData?.email) {
      navigate("/recruiter/register");
    }
  }, []);
  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <div className={classes.inner}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginTop: "60px",
              flexDirection: width > 500 ? "row" : "column",
              cursor: "pointer"
            }}
            onClick={() => {
              navigate("/recruiter/register");
            }}
          >
            <img style={{ height: "93px" }} src="/dark_text_logo.svg" />
          </div>
          <Text mt="xl" className={classes.title}>
            {lt("applicant_registraion_success_title")}
          </Text>
          <Text mt="xl" style={{ color: "#828282" }}>
            {userCurrentData?.email}
          </Text>

          <Text mt={45} className={classes.subtitle}>
            {lt("applicant_registraion_success_subtitle")}
          </Text>
          <div
            style={{ display: "flex", marginTop: "30px", alignItems: "center" }}
          >
            <Text className={classes.subtitle}>
              {lt("applicant_registraion_success_massage")}
            </Text>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Anchor
                  onClick={() => {
                    // const senEmail = await  pb.collection('applicants').requestVerification(res.email);
                  }}
                  style={{
                    fontSize: width > 500 ? "20px" : "",
                    color: "#FFB004",
                  }}
                  mx="sm"
                >
                  {tn("click here")}
                </Anchor>
              </Menu.Target>

              <Menu.Dropdown>
                {/* <Menu.Label>Application</Menu.Label> */}

                <Menu.Item
                  icon={<IconEdit size={14} />}
                  onClick={() => {
                    setEditModal(true);
                    setEmail(userCurrentData?.email);
                  }}
                >
                  {tn("Edit Email")}
                  
                </Menu.Item>
                <Menu.Item
                  icon={<IconRepeat size={14} />}
                  onClick={async () => {
                    const senEmail = await  pb.collection('recruiters').requestVerification(userCurrentData.email).then((res:any) => {setDialogOpen(true)});
                    
                    
                  }}
                >
                  {tn("Resend the activation message")}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Modal
              styles={() => ({
                content: {
                  borderRadius: "10px",
                  background:
                    "linear-gradient(153deg, rgba(34,193,195,1) 100%, rgb(33, 3, 63) 100%)",
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                },
                header: { display: "none" },
                body: { padding: "9px" },
              })}
              opened={editModal}
              onClose={() => {
                setEditModal(false);
              }}
              centered
            >
              <div
                style={{
                  background: "white",
                  width: "100%",
                  height: "100%",
                  marginTop: "9px",
                  borderRadius: "5px",
                  paddingTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextInput
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  style={{ width: "90%" }}
                  required
                  // mt={90}
                  variant="filled"
                  styles={() => ({
                    label: {
                      color: "#21033F",
                    },
                    input: {
                      borderRadius: "0px",
                      color: "#340662",
                      backgroundColor: "transparent",
                      borderBottom: "1px solid #21033F",
                      "&:focus-within": {
                        border: "0px solid #21033F",
                        borderBottom: "1px solid #21033F",
                      },
                    },
                  })}
                  label={tn("Email")}
                  size="md"
                  mx="md"
                  data-autofocus
                />
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <Button
                    my="md"
                    style={{ backgroundColor: "#21033F" }}
                    onClick={async () => {
                      const  senEmail = await pb
                        .collection("recruiter")
                        .requestVerification(email)
                        .then(() => {
                          setEditModal(false);
                          setDialogOpen(true);
                        });
                      setUserCurrentData({ ...userCurrentData, email: email });
                    }}
                  >
                    {tn("Save")}
                  </Button>
                </div>
              </div>
            </Modal>
            <Dialog
              opened={dialogOpen}
              withCloseButton
              onClose={() => setDialogOpen(false)}
              size="lg"
              radius="md"
              style={{ background: "#b4ffbc" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <IconCheck />
                <Text size="sm" mb="xs" weight={500} mx="md" >
                  {tn("Activation message sent")}
                </Text>
              </div>
            </Dialog>
          </div>

          {width > 577 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "absolute",
                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
                bottom: "0",
                marginBottom: "30px",
              }}
            >
              <Text color="#21033f"  weight={700}>
                {texts3?.[0]}
              </Text>{" "}
              {divider}
              <Text color="#21033f"  weight={700}>
                {texts3?.[1]}
              </Text>{" "}
              {divider}
              <Text color="#21033f"  weight={700}>
                {texts3?.[2]}
              </Text>{" "}
            </div>
          )}
        </div>
      </Paper>

      <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/shapes/hole_image.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(100%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: `100%`,
            background:
              "linear-gradient(127deg, rgba(33,3,63,0.0970982142857143) 0%, rgba(33,3,63,0.6657256652661064) 100%)",
          }}
        >
          {/* <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: "url(/shapes/#21033F.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              fontSize: "40px",
              color: "#21033F",
              fontWeight: 700,
              textAlign: "left",
              padding: "270px 0px 0px 0px",
            }}
          >
           
          </div> */}
        </div>
    </div>
  );
}

export default RegisterRegisterSuccess;
