import { createContext, useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../constants";

export const DataContext: any = createContext(null);

function setLocalStorage(key: any, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
}

function getLocalStorage(key: any, initialValue: any) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
}
const DataContextProvider = ({ children }: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);

  // const [programEditID, setProgramEditID]: any = useState("");
  // const [companyEditID, setCompanyEditID]: any = useState("");
  // const [recruitersEditID, setRecruitersEditID]: any = useState("");

  const [adminApplicantData, setAdminApplicantData] = useState({});


  const [accountDelete, setDeleteAccount] = useState({});
  const [editRecruiter, setEditRecruiter] = useState({});
  const [viewRecruiter, setViewRecruiter] = useState({});

  const [activeAcount, setActiveAcount] = useState({});
  const [deactiveAcount, setDeactiveAcount] = useState({});
   const [roleEdit, setRoleEdit] = useState({});
   const [roleView, setRoleView] = useState({});

   const [accountAdd, setAccountAdd] = useState({});

   const [deleteCompany, setDeleteCompany] = useState({});
   const [activateCompany, setActivateCompany] = useState({});
   const [deleteDepartment, setDeleteDepartment] = useState({});
    const [softDeleteAccount, setSoftDeleteAccount] = useState({});
    const [editDepartment, setEditDepartment] = useState({});
    const [viewDepartment, setViewDepartment] = useState({});
    const [cancelProgram, setCancelProgram] = useState({});
    const [editProgram, setEditProgram] = useState({});
    const [hrViewProgram, setHRviewProgram] = useState({});
    const [applicantViewProgram, setApplicantViewProgram] = useState({});
    const [applyForProgram, setApplyForProgram] = useState({});
    const [publishProgram , setPublishProgram] = useState({});
    const [acceptNewApplicant, setAcceptNewApplicant] = useState({});
    const [rejectNewApplicant, setRejectNewApplicant] = useState({});
    const [acceptWaitingInterview, setAcceptWaitingInterview] = useState({});
    const [excludeInterview, setExcludeInterview] = useState({});
    const [schedulingInterview, setSchedulingInterview] = useState({});
    const [acceptScheduledInterview, setAcceptScheduledInterview] = useState({});
    const [cancelInterview, setCancelInterview] = useState({});
    const [reschedule, setReschedule] = useState({});
    const [didNotAttend, setDidNotAttend] = useState({});
    const [evaluationInterview, setEvaluationInterview] = useState({});
    const [acceptAppointment, setAcceptAppointment] = useState({});
    const [excludeDocument, setExcludeDocument] = useState({});
    const [correctionRequest, setCorrectionRequest] = useState({});
    const [acceptLetter, setAcceptLetter] = useState({});
    const [uploadDocuments, setUploadDocuments] = useState({});
    const [ changeCommencementDate, setChangeCommencementDate] = useState({});
    const [ terminateApplicant, setTerminateApplicant] = useState({});
    const [ cancelProgramApplicant, setCancelProgramApplicant] = useState({});
    const [ schedulePlan, setSchedulePlan] = useState({});
    const [ activeProgramRecruiter, setActiveProgramRecruiter] = useState({});
    const [ activeProgramsApplicant, setActiveProgramsApplicant] = useState({});
    const [ terminationProgramWithoutDocument, setTerminationProgramWithoutDocument] = useState({});
    const [ uploadingProgramCompletionLetter, setUploadingProgramCompletionLetter] = useState({});
    const [programEvaluation, setProgramEvaluation] = useState({});
    const [withdrawalRequest, setWithdrawalRequest] = useState({});
    const [withdrawal, setWithdrawal] = useState({});
    const [withdrawalReason, setWithdrawalReason] = useState({});
    const [editPermission, setEditPermission] = useState({});
    const [deletePermission, setDeletePermission] = useState({});
    const [viewPermission, setViewPermission] = useState({});
    const [rejectWithdraw, setRejectWithdraw] = useState({});
    const [acceptWithdraw, setAcceptWithdraw] = useState({});
    const [adoptProgram, setAdoptProgram] = useState({});
    const [rejectProgram, setRejectProgram] = useState({});
    const [rejectProgramReason, setRejectProgramReason] = useState({});
    const [cancelPartialProgram, setCancelPartialProgram] = useState({});
    const [hangPartialProgram, setHangPartialProgram] = useState({});
    const [publishPartialProgram, setPublishPartialProgram] = useState({});
    const [resendForApproval, setResendForApproval] = useState({});
    const [correctionRequestContractIssuing, setCorrectionRequestContractIssuing] = useState({});
    const [correctionMessageIssuing, setCorrectionMessageIssuing] = useState({});
    const [approveContract, setApproveContract] = useState({});
    const [fillContractData, setFillContractData] = useState({});
    const [correctionMessageApplicant, setCorrectionMessageApplicant] = useState({});
    const [approveContractApproval, setApproveContractApproval] = useState({});
    const [correctionRequestContractApproval, setCorrectionRequestContractApproval] = useState({});
    const [correctionMessageDocuments, setCorrectionMessageDocuments] = useState({});
    const [showAbout, setShowAbout] = useState({});
    const [inviteApplicant, setInviteApplicant] = useState({});
    const [cancelInvitation, setCancelInvitation] = useState({});
    const [rejectInvitation, setRejectInvitation] = useState({});
    const [acceptInvitation, setAcceptInvitation] = useState({});
    const [republishProgram, setRepublishProgram] = useState({});
    const [locationDataForm,setLocationDataForm] = useState({});
    const [testInstructions, setTestInstructions] = useState({});
    const [terminateApplicantView, setTerminateApplicantView] = useState({});
    const [programEvaluationView, setProgramEvaluationView] = useState({});
    const [locationsDetails, setLocationsDetails] = useState({});
    const [addDepartment, setAddDepartment] = useState({});

  return (
    <DataContext.Provider
      value={{
        adminApplicantData,
        setAdminApplicantData,
        accountDelete,
        setDeleteAccount,
        editRecruiter,
        setEditRecruiter,
        viewRecruiter,
        setViewRecruiter,
        activeAcount,
        setActiveAcount,
        deactiveAcount,
        setDeactiveAcount,
        accountAdd,
        setAccountAdd,
        deleteCompany,
        setDeleteCompany,
        activateCompany,
        setActivateCompany,
        //recruiters
        deleteDepartment,
        setDeleteDepartment,
        roleEdit,
        setRoleEdit,
        roleView,
        setRoleView,
        softDeleteAccount,
        setSoftDeleteAccount,
        setEditDepartment,
        editDepartment,
        setViewDepartment,
        viewDepartment,
    setCancelProgram,
    cancelProgram,
    editProgram,
    setEditProgram,
    setHRviewProgram,
    hrViewProgram,
    setApplicantViewProgram,
    applicantViewProgram,
    setApplyForProgram,
    applyForProgram,
    setPublishProgram,
    publishProgram,
    setAcceptNewApplicant,
    acceptNewApplicant,
    setRejectNewApplicant,
    rejectNewApplicant,
    setAcceptWaitingInterview,
    acceptWaitingInterview,
    setExcludeInterview,
    excludeInterview,
    setSchedulingInterview,
    schedulingInterview,
    setAcceptScheduledInterview,
    acceptScheduledInterview,
    setCancelInterview,
    cancelInterview,
    setReschedule,
    reschedule,
    setDidNotAttend,
    didNotAttend,
    evaluationInterview, setEvaluationInterview,
    setAcceptAppointment,
    acceptAppointment,
    setExcludeDocument,
    excludeDocument,
    setCorrectionRequest,
    correctionRequest,
    setAcceptLetter,
    acceptLetter,
    setUploadDocuments,
    uploadDocuments,
    changeCommencementDate,
    setChangeCommencementDate,
    terminateApplicant,
    setTerminateApplicant,
    cancelProgramApplicant,
    setCancelProgramApplicant,
    schedulePlan,
    setSchedulePlan,
    setActiveProgramRecruiter,
    activeProgramRecruiter,
    setActiveProgramsApplicant,
    activeProgramsApplicant,
    terminationProgramWithoutDocument,
    setTerminationProgramWithoutDocument,
    uploadingProgramCompletionLetter,
    setUploadingProgramCompletionLetter,
    programEvaluation,
    setProgramEvaluation,
    withdrawalRequest,
    setWithdrawalRequest,
    withdrawal,
    setWithdrawal,
    setWithdrawalReason,
    withdrawalReason,
    editPermission,
    setEditPermission,
    deletePermission,
    setDeletePermission,
    viewPermission,
    setViewPermission,
    setRejectWithdraw,
    rejectWithdraw,
    setAcceptWithdraw,
    acceptWithdraw,
    setAdoptProgram,
    adoptProgram,
    setRejectProgram,
    rejectProgram,
    setRejectProgramReason,
    rejectProgramReason,
    setCancelPartialProgram,
    cancelPartialProgram,
    setHangPartialProgram,
    hangPartialProgram,
    setPublishPartialProgram,
    publishPartialProgram,
    setResendForApproval,
    resendForApproval,

    setCorrectionRequestContractIssuing,
    correctionRequestContractIssuing,
    setCorrectionMessageIssuing,
    correctionMessageIssuing,
    setApproveContract,
    approveContract,
    setFillContractData,
    fillContractData,
    setCorrectionMessageApplicant,
    correctionMessageApplicant,
    setApproveContractApproval,
    approveContractApproval,
    setCorrectionRequestContractApproval,
    correctionRequestContractApproval,
    setCorrectionMessageDocuments,
    correctionMessageDocuments,
    setShowAbout,
    showAbout,
    setInviteApplicant,
    inviteApplicant,
    setCancelInvitation,
    cancelInvitation,
    rejectInvitation,
    setRejectInvitation,
    setAcceptInvitation,
    acceptInvitation,
    republishProgram,
    setRepublishProgram,
    setLocationDataForm,
    locationDataForm,
    testInstructions,
    setTestInstructions,
    terminateApplicantView,
    setTerminateApplicantView,
    setProgramEvaluationView,
    programEvaluationView,
    setLocationsDetails,
    locationsDetails,
    setAddDepartment,
    addDepartment
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;








        // programEditID,
        // setProgramEditID,
        // companyEditID,
        // setCompanyEditID,
        // recruitersEditID,
        // setRecruitersEditID,