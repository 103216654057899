import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { isEmail, useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../context/AppContext";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",

    //
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
  },

  title: {
    color: "#21033F",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
}));
export function AdminForgotPassword() {
  const pb: any = new PocketBase(CMS_ENDPOINT);

  const navigate = useNavigate();
  const [isError, setisError]: any = useState(false);

  // const [isEmail, setisEmail]: any = useState(false);
  const [isEmail_, setIsEmail]: any = useState(false);
   const noEmail = vtn("password_forgot")
 const errorsText = [
    vtn("not_taken_email"),
    vtn("email"),
 ]
  const form: any = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
          ? !isEmail_
            ? errorsText?.[0]
            : null
          : errorsText?.[1],


    },
  
  });
  useEffect(() => {
    const resultList = pb
      .collection("admin")
      .getList(1, 2, {})
      .then((res: any) => {
        if (
          res.items.filter((item: any) => item.email == form.values.email)
            .length == 0
        ) {
          setIsEmail(false);
        } else {
          setIsEmail(true);
        }
      });
  }, [form.values.email]);
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale } = useContext(TranslationContext);

  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  const errorMassage = true;

  return (
    <form
      onSubmit={
        form.onSubmit(async (e) => {
        try {
          const resultList =  pb
          .collection("admin")
          .getFirstListItem(`email = "${form.values.email}"`)
          .then((res: any) => {
             pb
            .collection("admin")
            .requestPasswordReset(e.email)
            .then((res: any) => {
              navigate("/admin/forgot_password_confirmation_message");
            });
    
          });
         
        } catch (err) {
          setisError(true);
        }
        // }
      })}
    >
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "100px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <img style={{ height: "93px" }} src="/dark_text_logo.svg" />
            </div>
            <div style={{ maxWidth: "393px" }}>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: "82px",
                  fontWeight: "700",
                  lineHeight: "100px",
                  color: "#21033F",
                }}
              >
                {tn("Reset Password")}
              </Text>
            </div>
            <div>
              <TextInput
               
                {...form.getInputProps("email")}
                required
                mt={90}
                variant="filled"
                styles={(theme) => ({
                  label: {
                    color: "#21033F",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "#21033F",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #21033F86",
                    "&:focus-within": {
                      border: "0px solid #21033F",
                      borderBottom: "1px solid #21033F",
                    },
                  },
                })}
                label={tn("Email Address")}
                placeholder="hello@coophub.co"
                size="md"
              />

              <Button
                type="submit"
                style={{
                  borderRadius: "5.112px",
                  height: "70px",
                  backgroundColor: "#21033Fc4",
                  fontSize: "16px",
                }}
                fullWidth
                mt={100}
                size="md"
              >
                {tn("Reset Password")}
              </Button>
              {/* {isError && (
              <Text mt="md" size="13px" color="red">
                 {errorMassage}
              </Text>
            )} */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <Text color="#21033F"  weight={700}>
                {tn("terms and conditions")}
              </Text>{" "}
              {divider}
              <Text color="#21033F"  weight={700}>
                {tn("Usage policy")}
              </Text>{" "}
              {divider}
              <Text color="#21033F"  weight={700}>
                {tn("All rights are save")}
              </Text>{" "}
            </div>
          </div>
        </Paper>

        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/images/image1.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(100%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            height: "100%",
            width: `calc(100vw - 700px)`,
            background:
              "linear-gradient(312deg, rgba(2,0,36,1) 0%, #b6b6b6 0%, rgba(255, 255, 255, 0.472) 100%)",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: "url(/shapes/yellow_blue.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              fontSize: "40px",
              color: "white",
              fontWeight: 700,
              textAlign: "left",
              padding: "270px 0px 0px 0px",
            }}
          >
            خطوتك الأولى
            <br /> لمستقبل مهني واعد
          </div>
        </div>
      </div>
    </form>
  );
}

export default AdminForgotPassword;
