import { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { SelectableActionComponent } from "../SelectableActionComponent";

export const ActivateAccounts = (props: any) => {
  const { setActiveAcount }: any = useContext(DataContext);

  const { selectedRecords, action, table } = props;
  return (
    <SelectableActionComponent
    selectedRecords={selectedRecords}
       action={action}
       table={table}
       setData={async () => {
        setActiveAcount({
           id: selectedRecords?.[0],
           record: selectedRecords,
           action: action,
           table: table,
         });
       }}
       enabled={true}
    />
  );
};
