import {
  Button,
  Checkbox,
  Divider,
  Group,
  Modal,
  MultiSelect,
  NumberInput,
  Radio,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { tn } from "../../../../../../../translation";
import { DateInput } from "@mantine/dates";
import {
  IconChevronLeft,
  IconChevronRight,
  IconEdit,
} from "@tabler/icons-react";
import { RequirementsList } from "./RequirementsList";
import { pb } from "../../../../../../../config";
import { FiltrationTest } from "./filtration/FiltrationTest";
import { useEffect, useState } from "react";
import { vf } from "../../../../../../Functions";

export const MiddleForm = ({
  form,
  locale,
  translation,
  company,
  disabled,
}: any) => {
  const city = vf("city");
  const address_type = vf("address_type");
  const interview_type = vf("interview_type");
  const [filtrationTestModal, setFiltrationTestModal] = useState(false);
  const [filtrationTestData, setFiltrationTestData] = useState({id:"loading"});

  useEffect(() => {
    async function getFiltrationTest(id:any) {
      const record = await pb.collection("filtration_tests").getOne(id);
      setFiltrationTestData(record)
      return record;
    }
    if (form.values.filtration_test) {
      getFiltrationTest(form.values.filtration_test);
    }
  }, [form.values.filtration_test]);
  return (
    <div
      style={{
        width: "calc(50% - 10px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        background: "rgba(79, 81, 82, 0.05)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          width: "93%",
          background: "#21033F",
          height: "7px",
          borderRadius: "522px",
        }}
      />
      <Divider
        labelPosition="center"
        label={tn("Location data")}
        style={{
          width: "calc(100% - 20px)",
          margin: "5px 0px -10px",
        }}
      />
      <Radio.Group
        {...form.getInputProps("address_type")}
        withAsterisk
        size="xs"
        style={{ width: "100%" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "start",
            width: "100%",
            padding: "20px 10px 0px",
            gap: "10px",
          }}
        >
          {address_type?.[0] &&
            address_type.map((item: any) => (
              <Radio
                disabled={
                  (item.name == "primary_address" && !company?.location_data) ||
                  disabled
                }
                key={item.name}
                value={item.name}
                label={item[locale]}
              />
            ))}
        </div>
      </Radio.Group>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
         {form.values.address_type == "new_address" ? <SelectField
          disabled={disabled}
          data={mapOptions(city, locale, "name")}
          label="City"
          description="Enter the city where this training opportunity is located"
          error={form.errors?.city}
          inputProps={form.getInputProps("city")}
          withAsterisk
        />: <SelectField
        disabled={true}
        data={mapOptions(city, locale, "name")}
        label="City"
        description="Enter the city where this training opportunity is located"
        error={form.errors?.city}
        value={company?.location_data?.city}
        withAsterisk
      />}
       

       {form.values.address_type == "new_address" ?<TextField
          disabled={disabled}
          description="Type the short national address to access the geographical location"
          label="location"
          error={form.errors?.location}
          inputProps={form.getInputProps("location")}
          withAsterisk
        />:<TextField
        disabled={true}
        description="Type the short national address to access the geographical location"
        label="location"
        error={form.errors?.location}
        value={company?.location_data?.location}
        withAsterisk
      />}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
         {form.values.address_type == "new_address" ? <TextField
          disabled={disabled}
          description="Enter the building number"
          label="Building number"
          error={form.errors?.building_number}
          inputProps={form.getInputProps("building_number")}
          withAsterisk
        />: <TextField
        disabled={true}
        description="Enter the building number"
        label="Building number"
        error={form.errors?.building_number}
        value={company?.location_data?.building_number}
        withAsterisk
      />}
       {form.values.address_type == "new_address" ? <TextField
          disabled={disabled}
          description="Write the floor number in which the trainee will begin"
          label="The floor"
          error={form.errors?.floor}
          inputProps={form.getInputProps("floor")}
          withAsterisk
        />: <TextField
        disabled={true}
        description="Write the floor number in which the trainee will begin"
        label="The floor"
        error={form.errors?.floor}
        value={company?.location_data?.floor}
        withAsterisk
      />}
       
       
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
         {form.values.address_type == "new_address" ? <TextField
          disabled={disabled}
          description="Enter the office number"
          label="Office No"
          error={form.errors?.office_no}
          inputProps={form.getInputProps("office_no")}
          withAsterisk
        />: <TextField
        disabled={true}
        description="Enter the office number"
        label="Office No"
        error={form.errors?.office_no}
        value={company?.location_data?.office_no}
        withAsterisk
      />}
       
        <div style={{ width: "100%" }} />
      </div>
      <Divider
        labelPosition="center"
        label={tn("Requirements to join the program")}
        style={{
          width: "calc(100% - 20px)",
          margin: "15px 0px -10px",
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "10px 10px",
          marginTop: "10px",
        }}
      >
        <Checkbox
          size={"xs"}
          color="color3"
          {...form.getInputProps("has_interview")}
          checked={form.values?.has_interview}
          label={tn("Interview")}
          styles={() => ({
            label: {
              color: "#ffb61c",
            },
          })}
          disabled={disabled}
        />
        <Radio.Group
          {...form.getInputProps("interview_type")}
          withAsterisk
          size="xs"
        >
          <Group>
            {interview_type?.[0] &&
              interview_type.map((item: any) => (
                <Radio
                  key={item.name}
                  value={item.name}
                  label={item[locale]}
                  disabled={!form.values.has_interview || disabled}
                />
              ))}
          </Group>
        </Radio.Group>
      </div>
      <TextField
        marginTop="-10px"
        padding="0px 10px"
        disabled={
          form.values.has_interview !== true ||
          form.values.interview_type !== "remote" ||
          disabled
        }
        description="The link through which the personal interview is conducted"
        label="Personal interview link"
        error={form.errors?.personal_interview_link}
        inputProps={form.getInputProps("personal_interview_link")}
        withAsterisk
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
          padding: "0px 10px",
          marginTop: "10px",
        }}
      >
        <Checkbox
          size={"xs"}
          color="color3"
          {...form.getInputProps("has_filtration_test")}
          checked={form.values?.has_filtration_test}
          styles={() => ({
            label: {
              color: "#ffb61c",
            },
          })}
          disabled={disabled}
        />
        {(form?.values?.filtration_test && filtrationTestData?.id) ? (
          <Button
            color="color3"
            style={{
              filter:
                form.values.has_filtration_test !== true
                  ? "grayscale(100%)"
                  : "none",
            }}
            onClick={() => {
              setFiltrationTestModal(true);
            }}
            disabled={form.values.has_filtration_test !== true || disabled}
            size="xs"
            leftIcon={<IconEdit size="15px" />}
            variant="light"
            loading={filtrationTestData?.id == "loading"}
          >
            {/* {tn("Edit filtration test")} */}
            {filtrationTestData?.name}
          </Button>
        ) : (
          <Button
            color="color3"
            style={{
              filter:
                form.values.has_filtration_test !== true
                  ? "grayscale(100%)"
                  : "none",
            }}
            onClick={() => {
              setFiltrationTestModal(true);
            }}
            disabled={form.values.has_filtration_test !== true || disabled}
            size="xs"
            leftIcon={addIcon}
            variant="light"
          >
            {tn("Filtration test")}
          </Button>
        )}
      </div>
      <Divider
        labelPosition="center"
        label={tn("Other requirements")}
        style={{
          width: "calc(100% - 20px)",
          margin: "15px 0px 10px",
        }}
      />
      <div
        style={{
          padding: "0px 10px",
          width: "100%",
        }}
      >
        <RequirementsList form={form} disabled={disabled} />
      </div>
      {form?.errors?.other_requirements ? (
        <Text
          style={{
            color: "red",
            fontSize: "10px",
            // fontWeight: "500",
            height: "0px",
            width: "100%",
            margin: "10px 0px 0px",
            padding: "0px 10px",
            transform: "translate(-16px, -17px)",
          }}
        >
          {form?.errors?.other_requirements}
        </Text>
      ) : (
        <></>
      )}

      <Modal
        opened={filtrationTestModal}
        onClose={() => {
          setFiltrationTestModal(false);
        }}
        size={"750px"}
        style={{ zIndex: 10000, position: "relative" }}
        overlayProps={{
          zIndex: 2000,
          opacity: 0.55,
          blur: 3,
        }}
        styles={() => ({
          content: {
            zIndex: 10001,
            borderRadius: "10px",
            background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          header: { display: "none" },
          body: { padding: "9px" },
          inner: { zIndex: 10001 },
        })}
        centered
      >
        <FiltrationTest
          form={form}
          locale={locale}
          translation={translation}
          company={company}
          setFiltrationTestModal={setFiltrationTestModal}
        />
      </Modal>
    </div>
  );
};

const TextField = ({
  description,
  label,
  error,
  inputProps,
  withAsterisk = false,
  padding,
  disabled,
  marginTop,
  value
}: any) => (
  <div
    style={{
      width: "100%",
      padding: padding ? padding : "0px 0px",
      marginTop: marginTop ? marginTop : "0px",
    }}
  >
   {value? <TextInput
      disabled={disabled}
      label={tn(label)}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      value={value}
      size="xs"
      style={{ width: "100%" }}
    />:<TextInput
    disabled={disabled}
    label={tn(label)}
    withAsterisk={withAsterisk}
    styles={() => TextInputStyle(error)}
    {...inputProps}
    size="xs"
    style={{ width: "100%" }}
  />}
    <Text
      mt="2px"
      mb={error ? "12px" : "0px"}
      px="5px"
      fz="8px"
      color="#868e96"
    >
      {tn(description)}
    </Text>
  </div>
);
const TextInputStyle = (error: any) => ({
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  error: {
    marginBottom: "-14px",
    transform: "translate(4px, 13px)",
    // fontSize: "8px",
  },
});
const SelectField = ({
  data,
  label,
  description,
  error,
  inputProps,
  withAsterisk = false,
  disabled,
  value
}: any) => (
  <div
    style={{
      width: "100%",
    }}
  >
   {value? <Select
      disabled={disabled}
      data={data}
      label={tn(label)}
      withAsterisk={withAsterisk}
      styles={() => TextInputStyle(error)}
      value={value}
      size="xs"
      style={{ width: "100%" }}
    />:<Select
    disabled={disabled}
    data={data}
    label={tn(label)}
    withAsterisk={withAsterisk}
    styles={() => TextInputStyle(error)}
    {...inputProps}
    size="xs"
    style={{ width: "100%" }}
  />}
    <Text mt="2px" px="5px" fz="8px" color="#868e96">
      {tn(description)}
    </Text>
  </div>
);

const mapOptions = (items: any, labelField: any, valueField: any) =>
  items.map((item: any) => ({
    value: item[valueField],
    label: item[labelField],
  }));

const addIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M7.94239 1.40625C6.73716 1.40625 5.55899 1.76364 4.55688 2.43323C3.55477 3.10282 2.77372 4.05454 2.31249 5.16802C1.85127 6.28151 1.7306 7.50676 1.96573 8.68883C2.20085 9.8709 2.78123 10.9567 3.63345 11.8089C4.48568 12.6612 5.57148 13.2415 6.75355 13.4767C7.93563 13.7118 9.16088 13.5911 10.2744 13.1299C11.3878 12.6687 12.3396 11.8876 13.0092 10.8855C13.6787 9.88339 14.0361 8.70523 14.0361 7.5C14.0341 5.88446 13.3915 4.33566 12.2491 3.19329C11.1067 2.05093 9.55793 1.40827 7.94239 1.40625ZM10.2861 7.96875H8.41114V9.84375C8.41114 9.96807 8.36175 10.0873 8.27384 10.1752C8.18593 10.2631 8.06671 10.3125 7.94239 10.3125C7.81807 10.3125 7.69884 10.2631 7.61093 10.1752C7.52302 10.0873 7.47364 9.96807 7.47364 9.84375V7.96875H5.59864C5.47432 7.96875 5.35509 7.91936 5.26718 7.83146C5.17927 7.74355 5.12989 7.62432 5.12989 7.5C5.12989 7.37568 5.17927 7.25645 5.26718 7.16854C5.35509 7.08064 5.47432 7.03125 5.59864 7.03125H7.47364V5.15625C7.47364 5.03193 7.52302 4.9127 7.61093 4.82479C7.69884 4.73689 7.81807 4.6875 7.94239 4.6875C8.06671 4.6875 8.18593 4.73689 8.27384 4.82479C8.36175 4.9127 8.41114 5.03193 8.41114 5.15625V7.03125H10.2861C10.4105 7.03125 10.5297 7.08064 10.6176 7.16854C10.7055 7.25645 10.7549 7.37568 10.7549 7.5C10.7549 7.62432 10.7055 7.74355 10.6176 7.83146C10.5297 7.91936 10.4105 7.96875 10.2861 7.96875Z"
      fill="#ffc038"
    />
  </svg>
);
