import { Button, List, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useDebouncedValue } from "@mantine/hooks";
import { tn } from "../../../../../../translation";

export const ShowAbout = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data?.record, 200);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
        <Text style={{
        fontWeight:600,
        fontSize:"16px",
        color: "#3d3d3d",
      }} m="sm">
            {tn("About")}
        </Text>
        <div style={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "40px",
            width: "100%",
        }}>
            <Text style={{
        fontWeight:600,
        fontSize:"14px",
        color: "#5c5c5c",
      }} my="lg" >{debounced_data?.about}</Text>
        </div>
      

      <Button
        color="gray"
        variant="outline"
        // radius was 0 here
        compact
        style={{
          minWidth: "80px",
        }}
        mx={5}
        onClick={() => onClose({})}
      >
        {tn("Cancel")}
      </Button>
    </div>
  );
};
