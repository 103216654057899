// import {
//   IconCircleCheck,
//   IconEdit,
//   IconSquareRoundedX,
//   IconTrash,
// } from "@tabler/icons-react";
// import PocketBase from "pocketbase";
// import { CMS_ENDPOINT } from "../../constants";
// import { useContext, useEffect, useState } from "react";
// import { Button, Dialog, Group, Modal, Text, TextInput } from "@mantine/core";
// import {  tn } from "../../translation";
// import { useDebouncedState, useTimeout } from "@mantine/hooks";
// import { TranslationContext } from "../../context/TranslationContext";

import { DataGrid } from "../../main_components/data-grid/DataGrid";

export function RecruitersActivation() {
  // const pb: any = new PocketBase(CMS_ENDPOINT);



  return (
    <>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "30px",
          background: "#fcfcfc",
        }}
      >
        <DataGrid name="recruiters_activation"/>
      </div>
    </>
  );
}
