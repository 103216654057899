// import { useContext, useState } from "react";
// import {
//   createStyles,
//   Header,
//   Group,
//   Button,
//   Modal,
//   ActionIcon,
//   Container,
//   Text,
//   Burger,
//   rem,
// } from "@mantine/core";
// import { useDisclosure } from "@mantine/hooks";
// import {
//   IconBrandTwitter,
//   IconLogout,
//   IconBrandInstagram,
// } from "@tabler/icons-react";
// import { MantineLogo } from "@mantine/ds";
// import { AppContext } from "../../context/AppContext";
// import { ActionToggle } from "../theme_button";
// import { TabsContext } from "../../context/TabsContext";
// import { useNavigate } from "react-router-dom";
// import PocketBase from "pocketbase";
// import { CMS_ENDPOINT } from "../../constants";

// const useStyles = createStyles((theme) => ({
//   inner: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     height: rem(76),

//     [theme.fn.smallerThan("sm")]: {
//       justifyContent: "flex-start",
//     },
//   },

//   links: {
//     width: rem(260),

//     [theme.fn.smallerThan("sm")]: {
//       display: "none",
//     },
//   },

//   social: {
//     width: rem(260),

//     [theme.fn.smallerThan("sm")]: {
//       width: "auto",
//       marginLeft: "auto",
//     },
//   },

//   burger: {
//     marginRight: theme.spacing.md,

//     [theme.fn.largerThan("sm")]: {
//       display: "none",
//     },
//   },

//   link: {
//     display: "block",
//     lineHeight: 1,
//     padding: `${rem(8)} ${rem(12)}`,
//     borderRadius: theme.radius.sm,
//     textDecoration: "none",
//     color:
//       theme.colorScheme === "dark"
//         ? theme.colors.dark[0]
//         : theme.colors.gray[7],
//     fontSize: theme.fontSizes.sm,
//     fontWeight: 500,

//     "&:hover": {
//       backgroundColor:
//         theme.colorScheme === "dark"
//           ? theme.colors.dark[6]
//           : theme.colors.gray[0],
//     },
//   },

//   linkActive: {
//     "&, &:hover": {
//       backgroundColor: theme.fn.variant({
//         variant: "light",
//         color: theme.primaryColor,
//       }).background,
//       color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
//         .color,
//     },
//   },
// }));

// interface HeaderMiddleProps {
//   links: { value: string; title: string; onClick: any }[];
// }

// export default function MainHeaderAdmin({ links }: HeaderMiddleProps) {
//   const [modalOpen, setModalOpen]: any = useState(false);
//   const navigate = useNavigate();
//   const pb: any = new PocketBase(CMS_ENDPOINT);

//   const [opened, { toggle }] = useDisclosure(false);
//   const [active, setActive]: any = useState(links?.[0].value);
//   const { classes, cx } = useStyles();
//   const { adminMainTab, setAdminMainTab }: any = useContext(TabsContext);
//   const { theme }: any = useContext(AppContext);
//   const items = links.map((link) => (
//     <div onClick={link.onClick}>
//       <a
//         style={{ cursor: "pointer" }}
//         key={link.value}
//         //   href={link.link}
//         className={cx(classes.link, {
//           [classes.linkActive]: adminMainTab === link.value,
//         })}
//         onClick={(event) => {
//           event.preventDefault();
//           setActive(link.value);
//         }}
//       >
//         {link.title}
//       </a>
//     </div>
//   ));

//   return (
//     <Header
//       style={{ width: "100vw", marginBottom: "0px", zIndex: "10" }}
//       height={56}
//       mb={120}
//     >
//       <Container className={classes.inner}>
//         <Burger
//           opened={opened}
//           onClick={toggle}
//           size="sm"
//           className={classes.burger}
//         />
//         <Group className={classes.links} spacing={5}>
//           {items}
//         </Group>

//         <MantineLogo size={28} />

//         <Group spacing={0} className={classes.social} position="right" noWrap>
//           <ActionIcon onClick={() => setModalOpen(true)} size="lg">
//             <IconLogout size="1.1rem" stroke={1.5} />
//           </ActionIcon>

//           <ActionToggle />
//         </Group>
//       </Container>
//       <Modal
//         opened={modalOpen}
//         onClose={() => setModalOpen(false)}
//         title="logout"
//         overlayProps={{
//           // color:
//           //   theme === "dark"
//           //     ? theme.colors.dark[9]
//           //     : theme.colors.gray[2],
//           opacity: 0.55,
//           blur: 3,
//         }}
//       >
//         <Text>Do you want to logout from your account?</Text>
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "end",
//             marginTop: "20px",
//           }}
//         >
//           <Button
//             mx="sm"
//             color="red"
//             size="md"
//             compact
//             onClick={() => {
//               pb.authStore.clear();
//               navigate("/");
//             }}
//           >
//             Yes
//           </Button>
//           <Button
//             variant="outline"
//             color="gray"
//             size="md"
//             compact
//             onClick={() => setModalOpen(false)}
//           >
//             Cancel
//           </Button>
//         </div>
//       </Modal>
//     </Header>
//   );
// }
import { useState } from 'react';
import { createStyles, Header, Container, Anchor, Group, Burger, rem } from '@mantine/core';
import { useDisclosure, useViewportSize } from '@mantine/hooks';
import { MantineLogo } from '@mantine/ds';

const HEADER_HEIGHT = rem(84);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 140000,
   
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`,
  },

  mainLink: {
    textTransform: 'uppercase',
    fontSize: rem(13),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    padding: `${rem(7)} ${theme.spacing.sm}`,
    fontWeight: 700,
    borderBottom: `${rem(2)} solid transparent`,
    transition: 'border-color 100ms ease, color 100ms ease',

    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none',
    },
  },

  secondaryLink: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    transition: 'color 100ms ease',

    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none',
    },
  },

  mainLinkActive: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottomColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 5 : 6],
  },
}));

interface LinkProps {
  label: string;
  link: string;
}



export default function AdminHeader() {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const [active, setActive] = useState(0);
  const { height, width } = useViewportSize();

  return (
    <Header style={{width:"100vw" ,zIndex:1001, "background": "#F7F6F5",
    "boxShadow": "0px 2px 0px 0px #DCDCDC"}}  height={HEADER_HEIGHT} >
      <Container className={classes.inner}>
        <img src="/coal_text_logo.svg" style={{height:"100%", margin:`0px ${width>=1180?"0px":"20px"}`}}/>
        <div className={classes.links}>
         
        </div>
        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
      </Container>
    </Header>
  );
}