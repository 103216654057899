
import { useContext, useEffect, useState } from "react";
import { CMS_ENDPOINT } from "../../constants";
import { ltn, tn, vtn } from "../../translation";
import { Button, Modal, PasswordInput, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { useViewportSize } from "@mantine/hooks";


import { ProfileEdit } from "../components/ProfileEdit";
import { ProfileView } from "../components/ProfileView";
import { ChangePassword } from "../../main_components/ChangePassword";
export function AdminProfile() {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { height, width }: any = useViewportSize();
  const [editing, setEditing] = useState(false);
  const [warnning, setWarnning]: any = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  return width > 10 ? (
    <>
      {" "}
      <div
        style={{
          position: "relative",
          width: width >= 1180 ? "calc(100vw - 300px)" : "100vw",
          height: "calc(100vh - 84px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          padding: "20px",
          paddingTop: "30px",
          background: "#F6F5F7",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "10px 30px",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "1180px",
          }}
        >
          <Text
            style={{
              backgroundColor: "#f3ec78",
              backgroundImage:
                "linear-gradient(113deg, rgba(105,220,191,1) 0%, rgba(222,140,134,1) 19%, rgba(33,3,63,1) 56%)",
              WebkitBackgroundClip: "text",
              MozBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textAlign: "right",
              fontSize:  width >= 580 ?"72px":"48px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "95%",
              height: "175px",
              width:  width >= 580 ?"300px":"200px",
            }}
          >
            {tn("Profile")}
          </Text>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",

            justifyContent: "center",
            width: "100%",
          }}
        >
          {editing ? (
            <ProfileEdit
              setWarnning={setWarnning}
              setEditing={setEditing}
            />
          ) : (
            <ProfileView
              setEditing={setEditing}
              setChangePassword={setChangePassword}
            />
          )}
        </div>
        <Modal
          style={{ zIndex: 10000 }}
          overlayProps={{
            zIndex: 10000,
            opacity: 0.55,
            blur: 3,
          }}
          styles={() => ({
            content: {
              zIndex: 10001,
              borderRadius: "10px",
              background:
                "linear-gradient(153deg, #22c0c372 100%, #21033f7a 100%)",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            },
            header: { display: "none" },
            body: { padding: "9px" },
            inner: { zIndex: 10000 },
          })}
          opened={warnning}
          onClose={() => {
            setWarnning(false);
          }}
          centered
        >
          <div
            style={{
              background: "white",
              width: "100%",
              height: "100%",
              marginTop: "9px",
              borderRadius: "5px",
              paddingTop: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Text fw="600" color="grey">
                {ltn("discard_massage")}
              </Text>
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Button
                my="md"
                style={{ backgroundColor: "#ff5e5e" }}
                onClick={() => {

                  setWarnning(false);
                  setEditing(false);
                }}
              >
                {tn("Yes")}
              </Button>
              <Button
                my="md"
                mx="md"
                variant="outline"
                color="gray"
                onClick={() => {
                  setWarnning(false);
                }}
              >
                {tn("Cancel")}
              </Button>{" "}
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        size={500}
        style={{ zIndex: 10000 }}
        overlayProps={{
         zIndex: 10000,
          opacity: 0.55,
          blur: 3,
        }}
        styles={() => ({
          content: {
            
            borderRadius: "20px",
            background:
              "linear-gradient(153deg, #22c0c372 100%, #21033f7a 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          header: { display: "none" },
          body: { padding: "9px" },
          inner: { zIndex: 10000 },
        })}
        opened={changePassword}
        onClose={() => {
          setChangePassword(false);
        }}
        centered
      >
        <div
          style={{
            background: "white",
            width: "100%",
            height: "100%",
            marginTop: "9px",
            borderRadius: "15px",
            paddingTop: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ChangePassword
          table="admin"
            onClose={setChangePassword}
            // setBanner={setBannerOpen}
          />
        </div>
      </Modal>
    </>
  ) : (
    <></>
  );
}
