import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Loader, Select, Text } from "@mantine/core";
import { tn } from "../../../translation";
import _, { set } from "lodash";
import { CMS_ENDPOINT } from "../../../constants";
import PocketBase from "pocketbase";
import { TranslationContext } from "../../../context/TranslationContext";
import { Helmet } from "react-helmet";
import { IconTriangleFilled } from "@tabler/icons-react";
import ReactECharts from "echarts-for-react";
import { useForm } from "@mantine/form";
import { ValueBoxes } from "./ValueBoxes";
import { ChatBoxes } from "./ChartBoxes";

const pb: any = new PocketBase(CMS_ENDPOINT);

function Dashboard() {
  const { locale,translation }: any = useContext(TranslationContext);

  const { reloader }: any = useContext(AppContext);
  const [data, setData]: any = useState({});
console.log("datadeata33",data)
  useEffect(() => {
    pb.collection("percentages")
      .getFullList()
      .then((e: any) => {
        setData((data_:any)=>({
          ...data_,
          percentages: e?.[0],
        }));
      });

      pb.collection('x_majors_count').getFullList().then((e: any) => {
      setData((data_:any)=>({
        ...data_,
        x_majors_count: e,
      }));
    });
    pb.collection('x_universities_count').getFullList().then((e: any) => {
      setData((data_:any)=>({
        ...data_,
        x_universities_count: e,
      }));
    });
    pb.collection('x_gender_count').getFullList().then((e: any) => {
      setData((data_:any)=>({
        ...data_,
        x_gender_count: e,
      }));
    });
    pb.collection('x_programs_status_count').getFullList().then((e: any) => {
      setData((data_:any)=>({
        ...data_,
        x_programs_status_count: e,
      }));
    });
    pb.collection('x_programs_type_count').getFullList().then((e: any) => {
      setData((data_:any)=>({
        ...data_,
        x_programs_type_count: e,
      }));
    });
    pb.collection('x_programs_count_in_departments').getFullList().then((e: any) => {
      setData((data_:any)=>({
        ...data_,
        x_programs_count_in_departments: e,
      }));
    });
    pb.collection('applications_view').getFullList().then((e: any) => {
      setData((data_:any)=>({
        ...data_,
        applications_view: e,
      }));
    });
  }, []);
  
  if (!data?.percentages) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Helmet>
          <title>{tn("Dashboard") + " / " + tn("Coophub")}</title>
        </Helmet>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {" "}
      <Helmet>
        <title>{tn("Dashboard") + " / " + tn("Coophub")}</title>
      </Helmet>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",

          padding: "20px",
          paddingTop: "30px",
          background: "#f7f7f7",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "1300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            marginTop: "10px",
          }}
        >
          <Text
            style={{
              fontSize: "24px",
              fontWeight: 600,
              color: "rgba(33, 3, 63, 0.70)",
              width: "100%",
            }}
          >
            {tn("Dashboard")}
          </Text>
          <div
            style={{
              background: "#fff",
              borderRadius: "20px",
              padding: "20px",
              width: "100%",
              marginTop: "30px",
            }}
          >
            {/* <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Select
                variant="unstyled"
                style={{
                  width: "100px",
                  marginRight: "20px",
                }}
                data={[
                  { label: "2024", value: "2024" },
                  { label: "2023", value: "2023" },
                  { label: "2022", value: "2022" },
                ]}
              />
            </div> */}
       <ValueBoxes data={data.percentages} locale={locale} />

          </div>
         <ChatBoxes data={data} locale={locale} translation={translation}/>
          <div
            style={{
              borderRadius: "20px",
              width: "100%",
              marginTop: "30px",
              display: "flex",
              justifyContent: "start",
              gap: "30px",
            }}
          >
            <div
              style={{
                width: "200%",
              }}
            >
              <BarsChart
                data={
                  data?.x_programs_count_in_departments?.[0]
                    ? data?.x_programs_count_in_departments?.map((x) => ({
                        value: x.number,
                        name: x.name,
                      }))
                    : []
                }
                title={tn("The most published sections of the programs")}
              />
            </div>
            <div
              style={{
                width: "300%",
              }}
            >
              <LineChart
                data={data.applications_view}
                title="متوسط تقييم البرامج"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

function BarsChart({ data, title }) {
  const filteredData = data?.filter(function (item) {
    return parseInt(item.value) !== 0;
  });
  return (
    <div
      style={{
        background: "#fff",
        borderRadius: "20px",
        padding: "10px",
        maxWidth: "100%",
        height: "320px",
      }}
    >
      <Text
        style={{
          padding: "10px",
          fontSize: "20px",
          fontWeight: 600,
          color: "#21033F",
          width: "100%",
        }}
      >
        {title}
      </Text>
      <ReactECharts
        style={{ height: "100%", transform: "translateY(-45px)" }}
        notMerge={true}
        theme="echarts_theme"
        option={{
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: filteredData.map((item) => item.name),
            textStyle: {
              fontSize: 16,
              color: "#666",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
          },
          xAxis: {
            type: "category",
            data: filteredData.map((item) => item.name),
          },

          series: [
            {
              name: title,
              type: "bar",
              data: filteredData?.map((item: any, i) => ({
                value: item.value,
                name: item.name,
                itemStyle: {
                  color:
                    [
                      "#BAEDBD", // Soft Mint Green
                      "#B1E3FF", // Light Sky Blue
                      "#FFD66B", // Mellow Yellow
                      "#2a2a2a", // Charcoal Gray
                      "#E6E6FA", // Light Lavender
                      "#F88379", // Pale Coral
                      "#98FF98", // Soft Mint
                      "#D3A9A9", // Dusty Rose
                      "#87CEEB", // Sky Blue
                    ][i] ??
                    _.shuffle([
                      "#BAEDBD", // Soft Mint Green
                      "#B1E3FF", // Light Sky Blue
                      "#FFD66B", // Mellow Yellow
                      "#2a2a2a", // Charcoal Gray
                      "#E6E6FA", // Light Lavender
                      "#F88379", // Pale Coral
                      "#98FF98", // Soft Mint
                      "#D3A9A9", // Dusty Rose
                      "#87CEEB", // Sky Blue
                    ])[0],
                },
              })),
              itemStyle: {
                borderRadius: 15,
                borderColor: "#fff",
                borderWidth: 3,
              },
            },
          ],
        }} // Keep your chart options here
        className="h-full w-full mt-[-12px]"
      />
    </div>
  );
}

function LineChart({ data, title }) {
  const all_departments_text = tn("All Departments");
  const [departments, setDepartments]: any = useState([]);
  const form: any = useForm({
    initialValues: {
      year: "2024",
      departments: [],
      department: "all",
    },
  });

  useEffect(() => {
    const records = pb
      .collection("departments_view")
      .getFullList({
        sort: "-created",
        // filter : `university_id="${localStorage.getItem("university_id")}"`,
      })
      .then((e: any) => {
        setDepartments(e);

        form.setFieldValue("departments", [
          { value: "all", label: all_departments_text },
          ...e.map((dept) => ({
            value: dept.id,
            label: dept.name,
          })),
        ]);
      });
  }, []);

  // Calculate average program evaluation
  const calculateAverageEvaluation = (selectedDepartment, selectedYear) => {
    const filteredData = data?.filter(
      (item) =>
        (selectedDepartment === "all" ||
          item.department === selectedDepartment) &&
        new Date(item.program_evaluation_date).getFullYear().toString() ===
          selectedYear
    );
    const quarterlyData = { Q1: [], Q2: [], Q3: [], Q4: [] };

    filteredData?.forEach((item) => {
      const quarter = determineQuarter(item.program_evaluation_date);
      if (quarter && item.program_evaluation_precentage) {
        quarterlyData[quarter].push(parseFloat(item.program_evaluation_precentage));
      }
    });
    console.log("filteredData", quarterlyData);

    return Object.values(quarterlyData).map((evals) =>
      evals.length ? evals.reduce((a, b) => a + b, 0) / evals.length : 0
    );
  };

  // Helper function to determine quarter from date
  const determineQuarter = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth();
    if (month < 3) return "Q1";
    if (month < 6) return "Q2";
    if (month < 9) return "Q3";
    return "Q4";
  };

  return (
    <div
      style={{
        background: "#fff",
        borderRadius: "20px",
        padding: "10px",
        maxWidth: "100%",
        height: "320px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            padding: "10px",
            fontSize: "20px",
            fontWeight: 600,
            color: "#21033F",
            width: "100%",
          }}
        >
          {title}
        </Text>
        <Select
          variant="unstyled"
          size="xs"
          data={form.values.departments}
          value={form.values["department"]}
          onChange={(e) => form.setFieldValue("department", e)}
        />
        <Select
          variant="unstyled"
          size="xs"
          mx="xs"
          data={[
            { value: "2023", label: "2023" },
            { value: "2024", label: "2024" },
            { value: "2025", label: "2025" },
            { value: "2026", label: "2026" },
          ]}
          value={form.values["year"]}
          onChange={(e) => form.setFieldValue("year", e)}
        />

        {/* Department Selector */}
      </div>
      <ReactECharts
        style={{ height: "85%" }}
        notMerge={true}
        theme="echarts_theme"
        option={{
          xAxis: {
            type: "category",
            data: ["Q1", "Q2", "Q3", "Q4"],
          },
          yAxis: {
            type: "value",
            max: 100, // Set max value for yAxis
            textStyle: {
              fontSize: 11,
              color: "#666",
            },
          },
          grid: {
            left: "0",
            right: "0",
            top: "20",
            bottom: "20",
            containLabel: true,
          },
          series: [
            {
              lineStyle: {
                color: "#69DCBF", // Custom line color
                width: 5, // Line width
                type: "solid", // Solid, dashed, or dotted
              },
              // Dot style
              itemStyle: {
                color: "#5BC4FF", // Color of the dots
              },
              symbol: "circle", // Shape of the dots ('circle', 'rect', etc.)
              symbolSize: 10,
              data: calculateAverageEvaluation(
                form.values["department"],
                form.values["year"]
              ),
              type: "line",
              smooth: true,
            },
          ],
        }}
      />
    </div>
  );
}
