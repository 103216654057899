import { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { SelectableActionComponent } from "../SelectableActionComponent";

export const TerminationProgramWithoutDocument = (props: any) => {
  const { setTerminationProgramWithoutDocument }: any = useContext(DataContext);
  
  const { selectedRecords, action , table } = props;
  return (
    <SelectableActionComponent
    selectedRecords={selectedRecords}
       action={action}
       table={table}
       setData={async () => {
        setTerminationProgramWithoutDocument({
           id: selectedRecords?.[0],
           record: selectedRecords,
           action: action,
           table: table,
         });
       }}
       enabled={true}
    />
  );
};
