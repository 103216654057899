
import { useEffect, useState } from "react";
import { useTimeout } from "@mantine/hooks";

import { DataGrid } from "../../../main_components/data-grid/DataGrid";
import { Helmet } from "react-helmet";
import { ttn, tn } from "../../../translation";

 function PersonalInterviews() {

  const [bannerOpen, setBannerOpen] = useState(false);
  const { start, clear } = useTimeout(() => setBannerOpen(false), 3000);
  useEffect(() => {
    if (bannerOpen) {
      start();
    }
  }, [bannerOpen]);


  return (
    <>
     <Helmet>
<title>{ttn("personal_interviews") + " / " + tn("Coophub")}</title>
</Helmet>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "90px",
          background: "#fcfcfc",
        }}
      >
        <DataGrid name="personal_interviews"/>
      </div>
    </>
  );
}
export default PersonalInterviews;