import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const FillContractData = (props: any) => {
  const { setFillContractData }: any = useContext(DataContext);
  
  const { data, action , table } = props;

  const when_be_able = data?.contract_applicant_status != "under_revision" && data?.contract_applicant_status != "accepted"
  return (
    <ActionComponent
    is_mobile={true}
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setFillContractData({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
    }}
    enabled={when_be_able}
  />
  );
};
