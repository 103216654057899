import { Button, Dialog, Menu, Modal, Tooltip } from "@mantine/core";
import { TranslationContext } from "../../../../../context/TranslationContext";
import { useContext, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { AdminApplicantEdit } from "../../../../../admin/components/AdminApplicantEdit";
import { DataContext } from "../../../../../context/DataContext";
import { motion } from "framer-motion";

export const EditApplicantAction = (props: any) => {
  const { locale } = useContext(TranslationContext);
  const {  setAdminApplicantData, } = useContext(DataContext);

  const { data, action, table } = props;
  return (
    <div style={{
      width:"100%",
      height:"100%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    }}>
      {table?.acions_type?.name== "menu" ? (
        <Menu.Item
          color={action?.second_color}
          icon={<Icon icon={action?.icon} />}
          onClick={() => setAdminApplicantData(data)}
        >
          {action?.["label_" + locale]}
        </Menu.Item>
      ) : (
         <Tooltip offset={-8}label={action?.["label_" + locale]}>
        <motion.div whileHover={{ scale: 1.1 }} style={{
         cursor:"pointer",
         display:"flex",
         justifyContent:"center",
         alignItems:"center",
         width:"100%",
         height:"100%",
         margin:"0px 3px",
        }}
        onClick={() => setAdminApplicantData(data)}
        >
          <Icon style={{fontSize:"15px"}} icon={action?.icon} color={action?.color} />
        </motion.div>
        </Tooltip>
      )}
    </div>
  );
};
