import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";
import { pb } from "../../../../../../config";

export const WithdrawalRequest = (props: any) => {
  const { setWithdrawalRequest, setWithdrawal }: any = useContext(DataContext);

  const { data, action, table } = props;
  // if main_status = accepted,canceled program,excluded,rejected,terminated then disable

  const disabled = [
    "canceled_program",
    "excluded",
    "rejected",
    "terminated",
    "withdrawn",
    "withdrawal_request",
  ].includes(data?.main_status);

 

  return (
    <ActionComponent
    is_mobile={true}
      data={data}
      action={action}
      table={table}
      setData={async () => {
        const record = await pb.collection('applications_view').getOne(data?.id);
        const isRequest =
        ["document_review","accepted"].includes(record?.main_status) ||
        ["issuing_the_contract", "approval_of_the_contract"].includes(
          record?.contract_status
        );
        if (isRequest) {
          setWithdrawalRequest({
            id: record.id,
            record: record,
            action: action,
            table: table,
          });
        } else {
          setWithdrawal({
            id: record.id,
            record: record,
            action: action,
            table: table,
          });
        }
      }}
      enabled={!disabled}
    />
  );
};
