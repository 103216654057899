import {Text} from "@mantine/core";
import ReactECharts from "echarts-for-react";
import _ from "lodash";
import { tn } from "../../../translation";
import { fv } from "../../../main_components/Functions";



export const ChatBoxes = ({
    data,
    locale,
    translation
}:any)=>{

    return  <div
    className="scrollbar-none"
    style={{
      borderRadius: "20px",
      width: "100%",
      marginTop: "30px",
      display: "grid",
      justifyContent: "start",
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      gap: "30px",
    }}
  >
    <ChartBox
    title={tn("Most prominent majors")}
      colors={[
        "#BAEDBD",
        "#B1E3FF",
        "#FFD66B",
        "#2a2a2a",
        "#E6E6FA",
        "#F88379",
        "#98FF98",
        "#D3A9A9",
        "#87CEEB",
      ]}
      filteredData={
        data?.x_majors_count?.[0]
          ? data?.x_majors_count?.map((x) => ({
              value: x.number,
              name: fv(x?.name,"major", translation)?.[locale],
            }))
          : []
      }
      
    />
    <ChartBox
      title={tn("Most prominent universities")}
      colors={["#BAEDBD", "#F88379", "#98FF98", "#D3A9A9", "#87CEEB"]}
      filteredData={data?.x_universities_count?.[0]
        ? data?.x_universities_count?.map((x) => ({
            value: x.number,
            name:fv(x?.name,"university", translation)?.[locale],
          }))
        : []}
    />
    <ChartBox
       title={tn("Gender")}
      colors={[
        "#87CEEB",
        "#ffcaca",

      ]}
        filteredData={data?.x_gender_count?.[0] 
        ? [
            {
                "value": data?.x_gender_count?.[0]?.male_counts || 0,
                name:fv("male","gender", translation)?.[locale]
            },
            {
                "value": data?.x_gender_count?.[0]?.female_counts || 0,
                "name":fv("female","gender", translation)?.[locale]
            }
        ]
        : []}
    />
    <ChartBox
      title={tn("Types of programs")}
    
      filteredData={[
     
          {
            value: data?.x_programs_type_count?.[0]?.co_op_training_count || 0,
            name: fv("co_op_training","program_type", translation)?.[locale],
          },
          {
            value: data?.x_programs_type_count?.[0]?.summer_training_count || 0,
            name: fv("summer_training","program_type", translation)?.[locale],
          },
          {
            value: data?.x_programs_type_count?.[0]?.volunteering_count || 0,
            name: fv("volunteering","program_type", translation)?.[locale],
          },
          {
            value: data?.x_programs_type_count?.[0]?.tamheer_count || 0,
            name: fv("tamheer","program_type", translation)?.[locale],
          },
        ]
      }
    />
    <ChartBox
      title={tn("Programs statuses")}
      colors={[
        fv("under_the_procedure","program_status", translation)?.bg_color,
        fv("completed","program_status", translation)?.bg_color,
        fv("current","program_status", translation)?.bg_color,
        fv("canceled","program_status", translation)?.bg_color,
        fv("waiting_for_approval","program_status", translation)?.bg_color,
        fv("hanging","program_status", translation)?.bg_color,
        fv("new","program_status", translation)?.bg_color,
        fv("rejected","program_status", translation)?.bg_color
      ]}
      filteredData={data?.x_programs_status_count?.[0] 
        ? [
            {
                "value": data?.x_programs_status_count?.[0]?.under_the_procedure_count || 0,
                "name":fv("under_the_procedure","program_status", translation)?.[locale]
            },
            {
                "value": data?.x_programs_status_count?.[0]?.completed_count || 0,
                "name":fv("completed","program_status", translation)?.[locale]
            },
            {
                "value": data?.x_programs_status_count?.[0]?.current_count || 0,
                "name":fv("current","program_status", translation)?.[locale]
            },
            {
                "value": data?.x_programs_status_count?.[0]?.canceled_count || 0,
                "name":fv("canceled","program_status", translation)?.[locale]
            },
            {
                "value": data?.x_programs_status_count?.[0]?.waiting_for_approval_count || 0,
                "name":fv("waiting_for_approval","program_status", translation)?.[locale]
            },
            {
                "value": data?.x_programs_status_count?.[0]?.hanging_count || 0,
                "name":fv("hanging","program_status", translation)?.[locale]
            },
            {
                "value": data?.x_programs_status_count?.[0]?.new_count || 0,
                "name":fv("new","program_status", translation)?.[locale]
            },
            {
                "value": data?.x_programs_status_count?.[0]?.rejected_count || 0,
                "name":fv("rejected","program_status", translation)?.[locale]
            }
        ]
        : []}
    />
  </div>}





const ChartBox = ({ title, colors, filteredData }: any) => {
    return (
      <div
       className="scrollbar-none"
        style={{
          background: "#fff",
          borderRadius: "20px",
          padding: "10px",
          height: "320px",
          maxWidth: "100%",
        }}
      >
        <Text
          style={{
            fontSize: "15px",
            fontWeight: 600,
            color: "#6b6b6b",
            width: "100%",
            textAlign: "center",
          }}
        >
          {title}
        </Text>
        <ReactECharts
          style={{
            height: "160px",
            transform: "translateY(-12px)",
          }}
          notMerge={true}
          theme="echarts_theme"
          option={{
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            color: colors?.[0]
              ? colors
              : [
                  "#BAEDBD", // Soft Mint Green
                  "#B1E3FF", // Light Sky Blue
                  "#FFD66B", // Mellow Yellow
                  "#2a2a2a", // Charcoal Gray
                  "#E6E6FA", // Light Lavender
                  "#F88379", // Pale Coral
                  "#98FF98", // Soft Mint
                  "#D3A9A9", // Dusty Rose
                  "#87CEEB", // Sky Blue
                ],
            series: [
              {
                name: { title },
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                startAngle: 90,
                center: ["50%", "50%"], // Center the pie chart to account for the bottom legend
                data: filteredData,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
                label: {
                  show: false,
                  position: "center",
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  borderRadius: 10,
                  // borderRadius: [100, 0 , 100, -13],
  
                  borderColor: "#fff",
                  borderWidth: 3,
                },
              },
            ],
          }} // Keep your chart options here
          className="h-[200px] w-full mt-[-12px]"
        />
        <div
        className="scrollbar-none"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            gap: "5px",
            padding: "10px",
            height: "140px",
            overflow: "scroll",
            transform: "translateY(-32px)",
          }}
        >
          {filteredData.map((item: any, index: number) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    // if color index is large return to first and so on
                    background: colors?.[index % colors?.length],
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                  }}
                ></div>
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#545454",
                  }}
                >
                  {item.name}
                </Text>
              </div>
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#545454",
                }}
              >
                {((item.value / _.sumBy(filteredData, "value")) * 100).toFixed(1)}
                {"%"}
              </Text>
            </div>
          ))}
        </div>
      </div>
    );
  };
  