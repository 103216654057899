
  import { useEffect, useState } from "react";
import { DataGrid } from "../../main_components/data-grid/DataGrid";
import { useTimeout } from "@mantine/hooks";
  
  export function CompnaiesManagement() {
  
    const [bannerOpen, setBannerOpen] = useState(false);
    const { start, clear } = useTimeout(() => setBannerOpen(false), 3000);
    useEffect(() => {
      if (bannerOpen) {
        start();
      }
    }, [bannerOpen]);
  
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 84px)",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            padding: "20px",
            paddingTop: "30px",
            background: "#fcfcfc",
          }}
        >
          <DataGrid name="companies_manage"/>
        </div>
      </>
    );
  }
  