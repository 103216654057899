import _ from "lodash";
import { pb } from "../../../../../../../config";

export const generateProgramCode = async ({data,oldData}: any) => {

    if(oldData?.code?.charAt(0) == data.type.charAt(0)?.toUpperCase()){
        return data.code;
    }
    const programCodes = await pb.collection("program_codes").getList(1, 5, {
      sort: "-code_number",
      filter: `code_type = "${data.type.charAt(0)?.toUpperCase()}"`,
    });

    const maxCodeNumber = _.maxBy(programCodes?.items, "code_number")?.code_number || 0;

    return programCodes?.items?.[0]
      ? `${programCodes?.items[0]?.code_type}-${maxCodeNumber + 1}`
      : `${data.type.charAt(0)?.toUpperCase()}-1`;
  };

  export const generateRequestCode = async (values: any) => {

    const requestsCodes = await pb.collection("program_request_codes").getList(1, 5, {
      sort: "-code_number",
    });

    const maxCodeNumberRequest = _.maxBy(requestsCodes?.items, "code_number")?.code_number || 0;

    return `RI-${maxCodeNumberRequest + 1}`;
  };
