import { Helmet } from "react-helmet";

import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { useContext, useEffect, useState } from "react";
import { ltn, tn, ttn } from "../../../translation";

import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { BannersContext } from "../../../context/BannersContext";
import { handleApplyInProgramClick } from "../../../main_components/data-grid/components/actions/applicants/programs_search/ApplyForProgram";
import { DataGridMobile } from "../../../main_components/data-grid/DataGridMobile";

function ProgramsSearch() {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { id } = useParams();
  const { setApplyForProgram, setApplicantViewProgram }: any =
    useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const messageTaken = ltn("applied_already");
  const messageUserData = ltn("fill_your_data");
  const messageAccepted = ltn("accepted_already");
  const messageAcceptedAnswer = ltn("accepted_already_answer");
  const GPA_not_enough = ltn("GPA_not_enough");
  const university_not_in_program = ltn("university_not_in_program");
  const case_study_not_in_program = ltn("case_study_not_in_program");
  const major_not_in_program = ltn("major_not_in_program");
  const degree_not_in_program = ltn("degree_not_in_program");
  const navigate = useNavigate();

  useEffect(() => {
    const openModal = async () => {
      const record = await pb
        .collection("applicant_view_programs")
        .getFullList({
          filter: `id="${id}"`,
        });

     

      if (
        await handleApplyInProgramClick({
          setTopBanner,
          messages: {
            messageTaken,
            messageUserData,
            messageAccepted,
            messageAcceptedAnswer,
            GPA_not_enough,
            university_not_in_program,
            case_study_not_in_program,
            major_not_in_program,
            degree_not_in_program,
          },
          navigate,
          data: record?.[0],
        })
      ) {
        setApplyForProgram({
          id: id,
          record: record?.[0],
        });
      }
    };
    if (id) {
      openModal();
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>{ttn("programs_search") + " / " + tn("Coophub")}</title>
      </Helmet>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "90px",
          background: "#fcfcfc",
        }}
      >
        <DataGridMobile name="programs_search" />
      </div>
    </>
  );
}

export default ProgramsSearch;
