import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
  Loader,
} from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { AppContext } from "../../context/AppContext";
import { TranslationContext } from "../../context/TranslationContext";
import { lt, ltn, tn, vtn } from "../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { UserContext } from "../../context/UserContext";
import jwt_decode from "jwt-decode";

const useStyles = createStyles((theme) => ({
  wrapper: {
    maxHeight: "100vh",
    height: "100vh",
    display: "flex",
  },

  form: {
    zIndex: 1,
    background: "#F6F6F5",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // justifyContent: 'center',
    height: "100vh",
    position: "relative",

    //
    maxHeight: "100vh",
    maxWidth: rem(700),
    paddingTop: rem(80),
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },
  inner: {
    maxWidth: rem(500),
    width: "100%",
  },

  title: {
    color: "#69DCBF",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  subtitle: {
    color: "#21033F",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    maxWidth: "300px",
  },
}));
export function RecruiterVerificationSuccess() {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const navigate = useNavigate();
  const [error, setError] = useState(false)
  const [isLogin, setIsLogin] = useState(false)

  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const { locale } = useContext(TranslationContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const { userCurrentData, setUserCurrentData } = useContext(UserContext);

  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  const errorMassage = tn("Invalid email or password");

  const texts3 = [
    tn("terms and conditions"),
    tn("Usage policy"),
    tn("All rights are save"),
  ];
  const [isVerified, setIsVerified]: any = useState(false);
  useEffect(() => {
    pb.collection("recruiters").authRefresh({}, {
      expand: "company, permission" // replace with your relation field name
  })
    if (token) {
      try {
        pb.collection("recruiters")
          .confirmVerification(token)
          .then((res: any) => {
            setIsVerified(true);
          });
      } catch (err) {}
    } else {
      // navigate("/applicant/register");
    }
  }, []);
  const form = useForm({
    initialValues: {
      password: "",
    },

    validate: {
    },
  });

  return isVerified ? (
    <form
      onSubmit={form.onSubmit(async (value) => {
        try {
          const authData = await pb
            .collection("recruiters")
            .authWithPassword(jwt_decode(token)?.email, value?.password, {}, {
              expand: "company, permission" // replace with your relation field name
          })?.then(()=>{
              setIsLogin(true)
            });
        } catch (err) {
          console.log("err", err);
          setError(true);
        }
      })}
    >
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <div className={classes.inner}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginTop: "60px",
                flexDirection: width > 500 ? "row" : "column",
              }}
            >
              <img style={{ height: "93px" }} src="/dark_text_logo.svg" />
            </div>
            <Text mt="xl" className={classes.title}>
              {lt("applicant_acount_success_title")}
            </Text>
           {isLogin ? <Text mt={45} className={classes.subtitle}>
              {lt("recruiter_acount_success_subtitle")}
            </Text>:
            <Text mt={45} className={classes.subtitle}>
            {lt("recruiter_acount_success_subtitle_login")}
          </Text>
          }
            
            {!isLogin && <><Text
              style={{
                color: "#21033F",
                fontSize: "15px",
                fontWeight: "700",
                marginTop: "20px",
                border: "1px solid #21033F",
                borderRadius: "10px",
                padding: "10px",
                textAlign: "center",
              }}
            >
              {jwt_decode(token)?.email}
            </Text>
            <PasswordInput
            // error={error}
              {...form.getInputProps("password")}
              label={tn("Password")}
              styles={() => ({
                root: {
                  marginTop: "20px",
                },
                input: {
                  color: "#21033F",
                  backgroundColor: "transparent",
                  border: "0px solid #21033F",
                  borderBottom: "1px solid #21033F",
                  borderRadius: "0px",
                },
                label: {
                  color: "#21033F",
                  fontSize: "15px",
                  fontWeight: "700",
                  marginBottom: "0px",
                },
                innerInput: {
                  color: "#21033F",
                  fontSize: "15px",
                  fontWeight: "700",
                  marginBottom: "0px",
                },
              })}
            /></>}
           
          </div> 
         {!isLogin?<>{error && <Text style={{
                color: "#ff6060",
                fontSize: "15px",
                marginTop: "10px",
                borderRadius: "10px",
                textAlign: "center",
          }}>{vtn("Invalid password")}</Text>}<Button
            type="submit"
            size="lg"
            style={{
              bottom: "0",
              marginBottom: height > 1000 ? "10px" : "120px",
              marginTop: "30px",
            }}
            // mt="auto"
            variant="light"
            styles={(theme) => ({
              root: {
                backgroundColor: "#21033F",
                color: "#dcfff6",
                borderRadius: "2px",
                width: "80%",
                height: "60px",
                "&:not([data-disabled])": theme.fn.hover({
                  backgroundColor: theme.fn.darken("#4a167f", 0.05),
                }),
              },
            })}
          >
            {tn("Login")}
          </Button></>:
          
          <Button
            style={{
              position: height > 1000 ? "static" : "absolute",
              bottom: "0",
              marginBottom: "70px",
              marginTop: "200px",
            }}
            onClick={() => {
              navigate("/recruiter");
            }}
            // mt="auto"
            variant="light"
            color="gray"
            styles={(theme) => ({
              root: {
                backgroundColor: "#21033F",
                color: "#dcfff6",
                borderRadius: "2px",
                width: "80%",
                height: "60px",
                "&:not([data-disabled])": theme.fn.hover({
                  backgroundColor: theme.fn.darken("#4a167f", 0.05),
                }),
              },
            })}
          >
            {tn("Main page")}
          </Button>}{" "}
          {width > 577 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "absolute",
                margin: "70px 5px 0px 5px",
                width: "100%",
                maxWidth: "500px",
                flexDirection: width > 500 ? "row" : "column",
                bottom: "0",
                marginBottom: "30px",
              }}
            >
              <Text color="#21033F" weight={700}>
                {texts3?.[0]}
              </Text>{" "}
              {divider}
              <Text color="#21033F" weight={700}>
                {texts3?.[1]}
              </Text>{" "}
              {divider}
              <Text color="#21033F" weight={700}>
                {texts3?.[2]}
              </Text>{" "}
            </div>
          )}
        </Paper>

        <div
          style={{
            height: "100%",
            width: `calc(100vw - 700px)`,
            backgroundImage: "url(/shapes/hole_image.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            left: locale == "en" ? "1" : "0",
            right: locale == "en" ? "0" : "1",
            filter: "grayscale(100%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: `100%`,
            background:
              "linear-gradient(127deg, rgba(33,3,63,0.0970982142857143) 0%, rgba(33,3,63,0.6657256652661064) 100%)",
          }}
        >
          {/* <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: "url(/shapes/#21033F.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              fontSize: "40px",
              color: "#21033F",
              fontWeight: 700,
              textAlign: "left",
              padding: "270px 0px 0px 0px",
            }}
          >
           
          </div> */}
        </div>
      </div>
    </form>
  ) : (
    <div
      style={{
        // background: "#21033F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Loader color="#21033F" />
    </div>
  );
}

export default RecruiterVerificationSuccess;
