import { createContext, useEffect, useState } from "react";
import PocketBase from "pocketbase";
export const AppContext: any = createContext(null);

function setLocalStorage(key: any, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
}

function getLocalStorage(key: any, initialValue: any) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
}
const AppContextProvider = ({ children }: any) => {
  const [theme, setTheme]: any = useState(() =>
    getLocalStorage("theme", "light")
  );
  useEffect(() => {
    setLocalStorage("theme", theme);
  }, [theme]);
  const [contractViewerData, setContractViewerData]: any = useState(() =>
    getLocalStorage("contractViewerData", {})
  );
  useEffect(() => {
    setLocalStorage("contractViewerData", contractViewerData);
  }, [contractViewerData]);
  //
  const [reloader, setReloader]: any = useState(true);

  return (
    <AppContext.Provider
      value={{
        reloader,
        setReloader,
        theme,
        setTheme,
        contractViewerData,
        setContractViewerData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
