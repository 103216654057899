import { ltn, tn } from "../../../../../../translation";
import { Button, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";
import { AfterExclude } from "../Function";

export const AcceptWithdraw = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const { reloader, setReloader }: any = useContext(AppContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const [buttonLoading, setButtonLoading] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("accept_withdraws")
          : ltn("accept_withdraw")}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          loading={buttonLoading}
          color="green"
          mx={5}
          onClick={
            data?.record?.[0]
              ? async () => {
                  setButtonLoading(true);
                  for (const record of data?.record || []) {
                    await pb
                      .collection(data?.table?.table)
                      .update(record, {
                        applicant_status: "withdrawn",
                        recruiter_status: "withdrawn",
                        main_status: "withdrawn",
                        withdrawal_status:"accepted"
                      })
                      ?.then((application: any) => {
                        AfterExclude(application);
                      });
                  }
                  setReloader(!reloader);
                  onClose({});
                }
              : async () => {
                  setButtonLoading(true);

                  await pb
                    .collection(data?.table?.table)
                    .update(data?.record?.id, {
                      applicant_status: "withdrawn",
                      recruiter_status: "withdrawn",
                      main_status: "withdrawn",
                      withdrawal_status:"accepted"
                    })
                    ?.then(async (application: any) => {
                     
                         
                            await AfterExclude(application);
                              setReloader(!reloader);
                              onClose({});
                            
                    });
                }
          }
        >
          {tn("Accept")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
