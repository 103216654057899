// import {Button, Modal, Slider, Text} from "@mantine/core"
// import PocketBase from "pocketbase";
// import { useContext, useState } from "react";
// import { useDebouncedValue } from "@mantine/hooks";
// import { AppContext } from "../../../../../../context/AppContext";
// import { CMS_ENDPOINT } from "../../../../../../constants";
// import { ltn, tn } from "../../../../../../translation";


// export const  ProgramEvaluation = (props:any) => {
//   const pb: any = new PocketBase(CMS_ENDPOINT);
//   const { data , onClose} = props;

//   const {reloader, setReloader} = useContext(AppContext);

//   const [evaluation, setEvaluation] = useState(50)
//   const [debounced_data] = useDebouncedValue(data, 200);

//      return <div style={{
//         display:"flex",
//         flexDirection:"column",
//         alignItems:"start",
//         justifyContent:"start",
//         padding:"20px",
//         width:"100%",

//      }}>
//       <Text fw={600} color="#5c5c5c">
//       {ltn("rate_program")}
//       </Text>
//       <Slider
//        marks={[
//         { value: 20, label: '20%' },
//         { value: 50, label: '50%' },
//         { value: 80, label: '80%' },
//       ]}
//       label={(value:any) => value + "%"} 
//       value={evaluation}
//       onChange={(e:any)=>{
//         setEvaluation(e);
//       }} style={{
//         width:"100%",
//         marginTop:"20px",
//         marginBottom:"10px"
//       }}  min={0} max={100} step={1}
//   />
//       <div style={{
//         display:"flex",
//         flexDirection:"row",
//         alignItems:"center",
//         justifyContent:"space-between",
//         marginTop:"20px"

//       }}>
//         <Button mx={5}onClick={async () => {
//           await pb.collection("applications").update(debounced_data.id,{
//             program_evaluation:evaluation + "%",
//             program_evaluation_date:new Date(),
//           });
//           onClose({});
//           setReloader(!reloader);
          
//         }}>
//           {tn("Save")}
//         </Button>
//         <Button variant="outline" color="gray" mx={5} onClick={() =>  onClose({})}>
//           {tn("Cancel")}
//         </Button>
//       </div>
       
//      </div>
// }

import { ltn, tn } from "../../../../../../translation";
import {
  Button,
  Loader,
  Progress,
  Rating,
  Slider,
  Text,
  TextInput,
} from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { set } from "lodash";

export const ProgramEvaluation = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data:data_, onClose } = props;
  const [debounced_data] = useDebouncedValue(data_?.record ?? data_, 200);
  const data = data_?.record ?? data_

  const { reloader, setReloader } = useContext(AppContext);
  const [company, setCompany]: any = useState({});
  const [evaluation, setEvaluation]: any = useState({});
  const [buttonLoading, setButtonLoading] = useState(false)
  useEffect(() => {
    const getCompany = async () => {
      const company = await pb
        .collection("companies")
        .getOne(data?.company_id);
    
        if(company?.current_models
          ?.program_evaluation_form){
            const evaluation_model = await pb
            .collection("evaluation_models")
            .getOne(
              company?.current_models
                ?.program_evaluation_form
            );
          setEvaluation(evaluation_model);
          }else{
            const record___ = await pb.collection('evaluation_models').getFirstListItem('identifier="program_evaluation_form"', {});
            setEvaluation(record___); 
          }


      setCompany(company);
    };
    getCompany();
  }, []);

  const form = useForm({
    initialValues: {
      precentage: 0,
      criterias: [],
    },
  });
  useEffect(() => {
    form.setValues({
      precentage: data?.program_evaluation?.precentage ?? 0,
      criterias:
      data?.program_evaluation?.criterias ??
        evaluation?.criterias?.map((criterias) => {
          return {
            name: criterias.name,
            description: criterias.description,
            rating: 0,
            comment: "",
            weight: criterias.weight,
          };
        }),
    });
  }, [evaluation, company]);

  const [precentage, setPrecentage] = useState(0);
  useEffect(() => {
    // get the precentage ot the evaluation by using the waight of each criteria
    let total_weight = 0;
    let total_score = 0;
    form.values?.criterias?.map((item) => {
      total_weight += item.weight;
      total_score += (item.rating / 5) * item.weight;
    });
    setPrecentage(Math.round((total_score / total_weight) * 100));
  }, [form.values?.criterias]);
  if (!form.values?.criterias) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "700px",
        }}
      >
        <Loader />
      </div>
    );
  }

  console.log("evaluationevaluationevaluation", debounced_data);
  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        setButtonLoading(true)
        await pb
          .collection("applications")
          .update(debounced_data?.id, {
            program_evaluation: {
              precentage: precentage,
              criterias: values.criterias,
            },
            program_evaluation_date:new Date(),
          })
        onClose({});
        setReloader(!reloader);
        setButtonLoading(false)
      })}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text size="xl" fw={600} color="#444444">
        {tn("Program evaluation form")}
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          marginTop: "20px",
          width: "100%",
          padding: "0px 10px",
        }}
      >
      
        <DataBox
          title={tn("Program name")}
          value={debounced_data?.program_name}
          onClick={undefined}
        />
        {/* اسم الجهة : */}
        <DataBox
          title={tn("Company")}
          value={company?.name}
          onClick={
          ()=>{
            if (data?.web) {
              window.open(
                data?.web?.includes("https://") ? data?.web : "http://" + data?.web,
                "_blank",
                "noreferrer"
              );
            } else if (data?.expand?.company?.web) {
              window.open(
                data?.expand?.company?.web?.includes("https://")
                  ? data?.expand?.company?.web
                  : "http://" + data?.expand?.company?.web,
                "_blank",
                "noreferrer"
              );
            } else {
            }
          }
          }
        />
      
      {/* department name */}
      <DataBox
          title={tn("Department")}
          value={debounced_data?.department_name}
          onClick={undefined}
        />
        {/* منسق البرنامج */}

        <DataBox
          title={tn("Program coordinator")}
          value={debounced_data?.recruiter_name}
          onClick={undefined}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: "calc(40% - 20px)",
            padding: "0px 10px",
            gap: "10px",
          }}
        >
          <Text size="xl" fw="800" color="color3">
            {precentage > -1 && (precentage + "%")}
          </Text>
          <Progress
            color="color3"
            radius="lg"
            size="xl"
            value={precentage}
            style={{
              width: "66%",
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "20px",
          border: "1px solid #E0E0E0",
          borderRadius: "10px",
          minHeight: "100px",
          maxHeight: "650px",
          overflowY: "auto",
        }}
      >
        {form?.values?.criterias?.map((item: any, index) => {
          return <CriteriaBox item={item} form={form} index={index} data={data}/>;
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
          width: "100%",
          gap: "10px",
        }}
      >
        <Button variant="outline" color="gray" onClick={() => onClose({})}>
          {tn("Cancel")}
        </Button>
       {!data?.program_evaluation && <Button loading={buttonLoading} color="color2" type="submit">
          {tn("Save")}
        </Button>}
      </div>
    </form>
  );
};
{
  /* <Slider
       marks={[
        { value: 20, label: '20%' },
        { value: 50, label: '50%' },
        { value: 80, label: '80%' },
      ]}
      label={(value:any) => value + "%"} 
      value={evaluation}
      onChange={(e:any)=>{
        setEvaluation(e);
      }} style={{
        width:"100%",
        marginTop:"20px",
        marginBottom:"10px"
      }}  min={0} max={100} step={1}
  /> */
}

const DataBox = ({ value, title, onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        marginTop: "10px",
        gap: "5px",
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <Text size="sm" color="#444444">
        {title}
        {": "}
      </Text>
      <Text size="sm" color={onClick ? "#FFB004" : "#444444"}>
        {value}
      </Text>
    </div>
  );
};

const CriteriaBox = ({ item, form, index,data }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          width: "calc(40% - 10px)",
          display: "flex",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Text size="sm" color="#444444">
          {index + 1}
        </Text>
        <Text size="sm" color="#444444">
          {/* title with bold + - + description */}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {item.name}
          </span>
          {" - "}
          {item.description}
        </Text>
      </div>
   {data?.program_evaluation ? 
    <Rating
   value={item.rating}
    size="lg"
    style={{
      opacity: 0.5,
    }}
  />
   :   <Rating
        {...form.getInputProps(`criterias.${index}.rating`)}
        defaultValue={2}
        size="lg"
      />}
      <TextInput
      disabled={data?.program_evaluation}
        placeholder={tn("Add comment")}
        {...form.getInputProps(`criterias.${index}.comment`)}
        style={{
          width: "calc(50% - 10px)",
        }}
        styles={() => ({
          input: {
            height: "40px",
            borderRadius: "10px",
            border: "1px solid #E0E0E0",
            padding: "10px",
            fontSize: "14px",
            color: "#444444",
          },
        })}
      />
    </div>
  );
};
