import { useViewportSize } from "@mantine/hooks";
import { styled } from "styled-components"



 export function ViewChanger ({
    web,
    mobile,
 }:any) {
   const { height, width } = useViewportSize();

    return (
       <>
       
        {width> 550? <Web>
            {web}
            </Web>: <Mobile>
            {mobile}
            </Mobile>}
           
       
       </>
    )
 }

 const Web = styled.div`
    width: 100%;
    display: none;
    @media (min-width: 550px) {
        display: block;
        }
        `
const Mobile = styled.div`
 width: 100%;
display: block;
@media (min-width: 551px) {
    display: none;
    }
    `
    