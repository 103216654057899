import { isNotEmpty, useForm } from "@mantine/form";
import { ltn, t, tn, vtn } from "../../../../../../translation";
import dayjs from "dayjs";
import _ from "lodash";
import { Button } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../context/AppContext";
import { ProgramForm } from "./program_form/ProgramForm";
import { IconSquareRoundedX } from "@tabler/icons-react";
import { BannersContext } from "../../../../../../context/BannersContext";
import { fv } from "../../../../../Functions";
import { generateRequestCode, generateProgramCode } from "./components/Functions";

const pb = new PocketBase(CMS_ENDPOINT);

export const AddProgram = ({ data, onClose }: any) => {
  const { reloader, setReloader }: any = useContext(AppContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const [company, setCompany]: any = useState({});
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const fill_all_fields = vtn("fill_all_fields");
  const required_field = vtn("required_field");
  const { locale, translation }: any = useContext(TranslationContext);
  const address_validation = (value: any, values: any) => {
    if (values?.address_type === "new_address") {
      return value ? null : required_field;
    } else {
      return null;
    }
  };
  const accessible_data =
  pb.authStore?.model?.expand?.permission?.accessible_data;
  const department_not_found = ltn("no_departments_found")
  useEffect(() => {
    loadDepartments();
    loadCompany();
  }, []);

  const loadDepartments = async () => {
    try {
      const res = await pb.collection("departments_view").getFullList();
      setDepartments(res);
      
      if (res.length < 1) {
        setTopBanner({
          message: department_not_found,
          icon: <IconSquareRoundedX />,
          type: "error",
          backgroundColor: "#FDE2E2",
          color: "#D64545",
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Failed to load departments:", error);
    }
  };

  const loadCompany = async () => {
    try {
      const res = await pb.collection("companies_view").getFirstListItem("");
      setCompany(res);
    } catch (error) {
      console.error("Failed to load company:", error);
    }
  };

  const validatePersonalInterviewLink = (value: any, values: any) => {
    if (values?.has_interview && values?.interview_type === "remote") {
      return value ? null : required_field;
    }
    return null;
  };

  const validateCandidateInNeed = (value) => {
    const current_department: any = departments?.find(
      (item: any) => item?.id == form?.values?.department
    );
    if (value) {
      if (current_department?.[`current_acceptance_ceiling`] < value) {
        return `لا يمكن ان يكون عدد المتدربين اكثر من ${
          current_department?.[`current_acceptance_ceiling`]
        }`;
      }
    } else {
      return required_field;
    }
  };

  const validateOtherRequirements = (value: any) => {
    if (value?.[0] && value?.filter((item: any) => item?.name === "")?.[0]) {
      return fill_all_fields;
    }
    return null;
  };

  const form: any = useForm({
    initialValues: {
      department:
        accessible_data == "all_data" ? "" : pb.authStore?.model?.department,
      type: "",
      nature: "field",
      name: "",
      brief: "",
      objective: "",
      period: "",
      degree: [],
      targeted_majors: [],
      target_educational_segment: "",
      candidate_in_need: "",
      is_incentive: false,
      incentive: 0,
      last_submission_date: "",
      start_date: "",
      address_type: pb.authStore?.model?.expand?.company?.location_data ?"primary_address":"new_address",
      city: "",
      location: "",
      building_number: "",
      floor: "",
      office_no: "",
      has_interview: false,
      interview_type: "in_person",
      personal_interview_link: "",
      has_filtration_test: false,
      other_requirements: [],
      required_skills: [],
      additional_instructions: "",
      attachment_1: null,
      attachment_2: null,
      universities: [],
      academic_average_4: 0,
      academic_average_5: 0,
      status: "",
      allUniversities: true,
      filtration_test: "",
    },

    validate: {
      personal_interview_link: validatePersonalInterviewLink,
      department: isNotEmpty(required_field),
      type: isNotEmpty(required_field),
      name: isNotEmpty(required_field),
      brief: isNotEmpty(required_field),
      objective: isNotEmpty(required_field),
      targeted_majors: isNotEmpty(required_field),
      target_educational_segment: isNotEmpty(required_field),
      period: isNotEmpty(required_field),
      candidate_in_need: validateCandidateInNeed,
      city: address_validation,
      location: address_validation,
      building_number: address_validation,
      floor: address_validation,
      office_no: address_validation,
      last_submission_date: isNotEmpty(required_field),
      start_date: isNotEmpty(required_field),
      degree: isNotEmpty(required_field),
      universities: (value, values) => {
        if (!values?.allUniversities) {
          return !value?.[0] ? required_field : null;
        }
      },
      other_requirements: validateOtherRequirements,
    },
  });

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const is_for_approval = values?.status == "waiting_for_approval";
    const is_hanging = values?.status == "hanging";

    try {
      const data = await prepareProgramData(values);
      const programCode =(!is_for_approval && !is_hanging)? await generateProgramCode({
        data,
        oldData: {},
      }):null;
      const requestCode =is_for_approval? await generateRequestCode(values):null;

      const result = await pb.collection("programs").create({
        ...data,
        code: programCode,
        request_code: requestCode,
      });

      if (result?.id) {
        await uploadAttachments(result?.id, values);
        setReloader(!reloader);
        onClose({});
      }
    } catch (error) {
      console.error("Failed to submit program:", error);
    } finally {
      // setLoading(false);
    }
  };

  const prepareProgramData = async (values: any) => {
    const is_new_address = values?.address_type == "new_address";
    const is_for_approval = values?.status == "waiting_for_approval";

    return {
      department: values?.department,
      name: values?.name,
      brief: values?.brief,
      objective: values?.objective,
      candidate_in_need: values?.candidate_in_need,
      incentive: values?.is_incentive ? values?.incentive?.toString() : null,
      type: values.type,
      period: values.period,
      universities: values.allUniversities
        ? ["all_universities"]
        : values.universities,
      target_educational_segment: values.target_educational_segment,
      major: values.targeted_majors,
      degree: values.degree,
      last_submission_date: dayjs(values.last_submission_date)
        .hour(23)
        .minute(59)
        .toDate(),
      start_date: values?.start_date,
      end_date: dayjs(values.start_date)
        .add(parseInt(fv(values.period, "period", translation).value), "month")
        .hour(23)
        .minute(59)
        .toDate(),
      other_requirements: values?.other_requirements,
      GPA_4: values?.academic_average_4,
      GPA_5: values?.academic_average_5,
      status: values?.status,
      publish_date: values?.status == "new" ? new Date() : null,
      company: pb.authStore?.model?.company,
      code: "C-1",
      nature: values.nature,
      address_type: values.address_type,

      building_number: is_new_address
        ? values.building_number
        : company?.location_data?.building_number,
      floor: is_new_address ? values.floor : company?.location_data?.floor,
      office_no: is_new_address ? values.office_no : company?.location_data?.office_no,
      city: is_new_address ? values.city : company?.location_data?.city,
      location: is_new_address ? values?.location : company?.location_data?.location,
      has_interview: values?.has_interview,
      interview_type: values.interview_type,
      personal_interview_link: values?.personal_interview_link,
      required_skills: values.required_skills,
      additional_instructions: values?.additional_instructions,
      filtration_test: values?.filtration_test,
      has_filtration_test: values?.has_filtration_test && !!values?.filtration_test,
      request_date: is_for_approval ? new Date() : null,
    };
  };

  const uploadAttachments = async (programId: string, values: any) => {
    const formData = new FormData();
    if (values?.attachment_1) formData.append("attachment_1", values?.attachment_1);
    if (values?.attachment_2) formData.append("attachment_2", values?.attachment_2);

    await pb.collection("programs").update(programId, formData);
  };

  return (
    <form
      style={{
        width: "100%",
      }}
      onSubmit={form.onSubmit(handleSubmit)}
    >
      <ProgramForm
        form={form}
        data={data}
        title={tn("Add a new program")}
        company={company}
        buttons={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              padding: "20px 0px",
            }}
          >
            {accessible_data == "all_data" ? (
              <>
                <Button
                  loading={loading}
                  type="submit"
                  mx="xs"
                  compact
                  style={{
                    backgroundColor: "#69DCBF",
                    color: "white",
                    minWidth: "100px",
                    borderRadius: "5.112px",
                  }}
                  onClick={() => {
                    form.setFieldValue("status", "new");
                  }}
                >
                  {tn("Create and publish")}
                </Button>
                <Button
                  loading={loading}
                  type="submit"
                  // mx="xs"
                  compact
                  style={{
                    backgroundColor: "#69DCBF",
                    color: "white",
                    width: "100px",
                    borderRadius: "5.112px",
                  }}
                  onClick={() => {
                    form.setFieldValue("status", "hanging");
                  }}
                >
                  {tn("Save")}
                </Button>
              </>
            ) : (
              <Button
              loading={loading}
                type="submit"
                // mx="xs"
                compact
                style={{
                  backgroundColor: "#69DCBF",
                  color: "white",
                  width: "100px",
                  borderRadius: "5.112px",
                }}
                onClick={() => {
                  form.setFieldValue("status", "waiting_for_approval");
                }}
              >
                {tn("Submit for approval")}
              </Button>
            )}

            <Button
              compact
              mx="sm"
              style={{
                color: "white",
                width: "100px",
                borderRadius: "5.112px",
                backgroundColor: "#F9837C",
              }}
              color="red"
              onClick={() => {
                onClose({});
              }}
            >
              {tn("Cancel")}
            </Button>
          </div>
        }
      />
    </form>
  );
};
