import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const CorrectionMessageApplicant = (props: any) => {
  const { setCorrectionMessageApplicant }: any = useContext(DataContext);
  
  const { data, action , table } = props;

  const when_be_able = data?.correction_letter_contract_issuance
  return (
    <ActionComponent
    is_mobile={true}
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setCorrectionMessageApplicant({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
    }}
    enabled={when_be_able}
  />
  );
};
