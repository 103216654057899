import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import MainHeaderRecruirter from "../components/MainHeaderRecruiter";
import { Navbars } from "../../main_components/navbar/Navbar";
import { useEffect } from "react";
import { useDebouncedState } from "@mantine/hooks";

const MainLayoutPartial = () => {
  const navigate = useNavigate();
  const pb: any = new PocketBase(CMS_ENDPOINT);
  //  useEffect(() => {
  //    if(  !company?.name ){
  //     navigate("/")
  //   }
  //  }, [])
  const [delayer, setDelayer] = useDebouncedState(false, 200);

  useEffect(() => {
  //     expand: "company" // replace with your relation field name
  // })
      if(pb?.authStore?.model?.collectionName !== "recruiters" || pb?.authStore?.model?.verified !== true){
      navigate("/recruiter/login")
    }
  }, []);
  useEffect(() => {
     pb.collection("recruiters").authRefresh({}, {
      expand: "company, permission" // replace with your relation field name
  })
  setDelayer(true);
  if(!pb?.authStore?.model?.expand?.company?.id){
    setDelayer(false);
  }
  },[delayer])
  const isAllowed = pb?.authStore?.model?.collectionName === "recruiters" && pb?.authStore?.model?.verified === true ;
  return (
    isAllowed?<Body>
      <div style={{position: "absolute",top:"0",zIndex: "10000"}}>
      <MainHeaderRecruirter/>
      </div>
      
      <div style={{
        width: "100vw",
        display: "flex",
        alignItems: "end",
        justifyContent: "center",
      }}>
        <Navbars name="partial_recruiter_nav"/>
     
      <Content>
        <Outlet />
      </Content>
      </div>
    </Body>:<></>)
};
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const Content = styled.div`
  min-width:  calc(100vw - 300px);
  max-width:  calc(100vw - 300px);
  @media (max-width: 1179px) {
    min-width:  calc(100vw - 0px);
    max-width:  calc(100vw - 0px);
  }
  max-height: calc(100vh - 84px);
  min-height: calc(100vh - 84px);
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: scroll;
`;
export default MainLayoutPartial;
