import { TextInput, Text, NumberInput, Button } from "@mantine/core";
import { tn, vtn } from "../../../translation";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";

export function EvaluationForm({ data, onSubmit, onCancelButtonClick,submitButtonText,cancelButtonText,model,
submitLoading
}: any) {
  const [errorMassage, setErrorMassage] = useState("")
  const form = useForm({
    initialValues: {
      name: data?.name ?? "",
      criterias: data?.criterias ?? [
        {
          name: "",
          description: "",
          weight: 1,
        },
      ],
    },
  });
  useEffect(() => {
    form.setValues({
      name: data?.name ?? "",
      criterias: data?.criterias ?? [
        {
          name: "",
          description: "",
          weight: 1,
        },
      ],
    });
  }, [
    model
  ]);
  useEffect(() => {
    // prevent any wait to be null or undefined if it null it  must be 0
    form.values.criterias.forEach((item: any) => {
      if (
        item.weight === null ||
        item.weight === undefined ||
        item.weight === ""
      ) {
        form.setFieldValue(
          `criterias.${form.values.criterias.indexOf(item)}.weight`,
          1
        );
      }
    });
    setErrorMassage("")
  }, [form.values?.criterias]);
  const error = vtn("fill_all_criterias");
  return (
    <form
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        height: "calc(100% - 120px)",
        maxHeight: "calc(100% - 120px)",
        backgroundColor: "#fff",
        borderRadius: "10px",
        border: "2px solid #21033F",
        position: "relative",       
      }}
      onSubmit={
       
       form?.onSubmit(() => {
        if (form.values?.criterias?.some((item: any) => item.name === "" || item.description === "")) {
          setErrorMassage(error)
           return;
         }
        
        onSubmit(form.values);
       })
      }
    >
      <div
        style={{
          width: "calc(100% - 40px)",
          height: "4px",
          backgroundColor: "#21033F",
          borderRadius: "100px",
          marginBottom: "10px",
          
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
          width: "100%",
          padding: "0px 20px",
          marginTop: "10px",
        }}
      >
        <TextInput
        required
          style={{
            position: "relative",
          }}
          label={tn("Model name")}
        {...form.getInputProps("name")}
          styles={{
            input: {
              backgroundColor: "#fff",
              borderRadius: "10px",
              border: "2px solid #21033F",
            },
            label: {
              color: "#21033F",
              transform: "translate(10px, -14px) scale(1)",
              pointerEvents: "none",
              backgroundColor: "#fff",
              padding: "0px 5px",
              borderRadius: "5px",
              position: "absolute",
              zIndex: 1,
            },
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "10px 40px",
          marginTop: "10px",

        }}
      >
        <Text
          style={{
            color: "#4F5152",
            fontSize: "15px",
            fontWeight: "500",
            width: "40px",
            textAlign: "center",
          }}
        >
          #
        </Text>
        <Text
          style={{
            color: "#4F5152",
            fontSize: "15px",
            fontWeight: "500",
            width: "calc(40% - 20px)",
            textAlign: "center",
          }}
        >
          {tn("Criterion")}
        </Text>
        <div />
        <Text
          style={{
            color: "#4F5152",
            fontSize: "15px",
            fontWeight: "500",
            width: "calc(40% - 20px)",
            textAlign: "center",
          }}
        >
          {tn("Criterion description")}
        </Text>
        <Text
          style={{
            color: "#4F5152",
            fontSize: "15px",
            fontWeight: "500",
            width: "80px",
            textAlign: "center",
          }}
        >
          {tn("Weight")}
        </Text>
      </div>
      <div style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        gap: "5px",
        maxHeight: "calc(100% - 170px)",
        overflowY: "scroll",
      
     }}>
      {form.values?.criterias?.map((item: any, index: number) => {
        return (
          <InputBlock
            item={item}
            index={index}
            form={form}
            data={data}
            is_createble={false}
            is_deleteble={form.values?.criterias?.length > 1 ? true : false}
          />
        );
      })}
      <InputBlock
        index={form.values?.criterias?.length}
        form={form}
        data={data}
        is_createble={true}
        item={{}}
        is_deleteble={false}
      />
      </div>
      <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "10px",
          width: "100%",
          position: "absolute",
          bottom: "-10px",
          padding: "0px 20px",
       }}>
        <Text style={{
          color: "#F1623F",
          fontSize: "15px",
          fontWeight: "500",
          }}>
          {errorMassage}
          </Text>
      {cancelButtonText&& <Button  style={{
          color: "#fff",
          backgroundColor: "#F9837C",
          borderRadius: "10px",
          padding: "5px 15px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
          onClick={() => {
            onCancelButtonClick();
          }
          }
        >
          {cancelButtonText}
          </Button>}
          <Button style={{
          backgroundColor: "#69DCBF",
          color: "#fff",
          borderRadius: "10px",
          padding: "5px 15px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
        loading={submitLoading}
       type="submit"
        >
          {
            submitButtonText
          }
          </Button>
          
       </div>
    </form>
  );
}

const InputBlock = ({
  index,
  form,
  item,
  data,
  is_createble,
  is_deleteble,
}: {
  index: number;
  form: any;
  item: any;
  data: any;
  is_createble: boolean;
  is_deleteble: boolean;
}) => {
  const total_waight = form.values?.criterias?.reduce(
    (acc: number, item: any) => acc + item.weight,
    0
  );
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "0px 40px",
        gap: "20px",
      }}
    >
      {is_createble ? (
        <div
          onClick={() => {
            form.setFieldValue("criterias", [
              ...form.values.criterias,
              {
                name: "",
                description: "",
                weight: 1,
              },
            ]);
          }}
          style={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {ADD_TEMPLATE}
        </div>
      ) : (
        <Text
          style={{
            color: "#3f4041",
            fontSize: "15px",
            fontWeight: "500",
            width: "40px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {is_deleteble && (
            <div
              onClick={() => {
                form.setFieldValue(
                  "criterias",
                  form.values.criterias.filter(
                    (item: any, i: number) => i !== index
                  )
                );
              }}
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {DELETE_SVG}
            </div>
          )}
          {index + 1}
        </Text>
      )}
      <TextInput
        disabled={is_createble}
        {...form.getInputProps("criterias." + index + ".name")}
        style={{
          width: "calc(40% - 20px)",
        }}
        styles={{
          input: {
            backgroundColor: "#ffffff",
            borderRadius: "10px",
          },
        }}
      />
      <TextInput
        disabled={is_createble}
        {...form.getInputProps("criterias." + index + ".description")}
        style={{
          width: "calc(40% - 20px)",
        }}
        styles={{
          input: {
            backgroundColor: "#ffffff",
            borderRadius: "10px",
          },
        }}
      />
      <NumberInput
        disabled={is_createble}
        {...form.getInputProps("criterias." + index + ".weight")}
        rightSection={
          <Text
            style={{
              color: "#000",
              fontSize: "13px",
            }}
          >
            {is_createble
              ? total_waight
              : "%" +
                ((item.weight / (total_waight ? total_waight : 1)) * 100
                )?.toFixed(0)}
          </Text>
        }
        style={{
          width: "calc(80px)",
        }}
        styles={{
          input: {
            backgroundColor: "#ffffff",
            borderRadius: "10px",
          },
        }}
        rightSectionWidth={40}
      />
    </div>
  );
};
const ADD_TEMPLATE = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.5917 5.88446 12.9491 4.33566 11.8067 3.19329C10.6643 2.05093 9.11555 1.40827 7.5 1.40625ZM9.84375 7.96875H7.96875V9.84375C7.96875 9.96807 7.91937 10.0873 7.83146 10.1752C7.74355 10.2631 7.62432 10.3125 7.5 10.3125C7.37568 10.3125 7.25645 10.2631 7.16855 10.1752C7.08064 10.0873 7.03125 9.96807 7.03125 9.84375V7.96875H5.15625C5.03193 7.96875 4.9127 7.91936 4.8248 7.83146C4.73689 7.74355 4.6875 7.62432 4.6875 7.5C4.6875 7.37568 4.73689 7.25645 4.8248 7.16854C4.9127 7.08064 5.03193 7.03125 5.15625 7.03125H7.03125V5.15625C7.03125 5.03193 7.08064 4.9127 7.16855 4.82479C7.25645 4.73689 7.37568 4.6875 7.5 4.6875C7.62432 4.6875 7.74355 4.73689 7.83146 4.82479C7.91937 4.9127 7.96875 5.03193 7.96875 5.15625V7.03125H9.84375C9.96807 7.03125 10.0873 7.08064 10.1752 7.16854C10.2631 7.25645 10.3125 7.37568 10.3125 7.5C10.3125 7.62432 10.2631 7.74355 10.1752 7.83146C10.0873 7.91936 9.96807 7.96875 9.84375 7.96875Z"
      fill={"#21033F"}
    />
  </svg>
);

const DELETE_SVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g opacity="0.85">
      <path
        d="M7.62207 1.40625C6.41684 1.40625 5.23868 1.76364 4.23657 2.43323C3.23445 3.10282 2.4534 4.05454 1.99218 5.16802C1.53096 6.28151 1.41028 7.50676 1.64541 8.68883C1.88054 9.8709 2.46091 10.9567 3.31314 11.8089C4.16537 12.6612 5.25117 13.2415 6.43324 13.4767C7.61531 13.7118 8.84056 13.5911 9.95405 13.1299C11.0675 12.6687 12.0193 11.8876 12.6888 10.8855C13.3584 9.88339 13.7158 8.70523 13.7158 7.5C13.7141 5.88436 13.0716 4.33538 11.9291 3.19295C10.7867 2.05052 9.23771 1.40796 7.62207 1.40625ZM9.82871 9.04336C9.87227 9.08691 9.90681 9.13861 9.93038 9.19552C9.95395 9.25242 9.96608 9.31341 9.96608 9.375C9.96608 9.43659 9.95395 9.49758 9.93038 9.55448C9.90681 9.61139 9.87227 9.66309 9.82871 9.70664C9.78516 9.75019 9.73346 9.78474 9.67656 9.80831C9.61965 9.83188 9.55866 9.84401 9.49707 9.84401C9.43548 9.84401 9.37449 9.83188 9.31759 9.80831C9.26069 9.78474 9.20898 9.75019 9.16543 9.70664L7.62207 8.1627L6.07871 9.70664C6.03516 9.75019 5.98346 9.78474 5.92656 9.80831C5.86965 9.83188 5.80866 9.84401 5.74707 9.84401C5.68548 9.84401 5.62449 9.83188 5.56759 9.80831C5.51069 9.78474 5.45898 9.75019 5.41543 9.70664C5.37188 9.66309 5.33733 9.61139 5.31376 9.55448C5.29019 9.49758 5.27806 9.43659 5.27806 9.375C5.27806 9.31341 5.29019 9.25242 5.31376 9.19552C5.33733 9.13861 5.37188 9.08691 5.41543 9.04336L6.95938 7.5L5.41543 5.95664C5.32748 5.86868 5.27806 5.74939 5.27806 5.625C5.27806 5.50061 5.32748 5.38132 5.41543 5.29336C5.50339 5.2054 5.62268 5.15599 5.74707 5.15599C5.87146 5.15599 5.99076 5.2054 6.07871 5.29336L7.62207 6.8373L9.16543 5.29336C9.20898 5.24981 9.26069 5.21526 9.31759 5.19169C9.37449 5.16812 9.43548 5.15599 9.49707 5.15599C9.55866 5.15599 9.61965 5.16812 9.67656 5.19169C9.73346 5.21526 9.78516 5.24981 9.82871 5.29336C9.87227 5.33691 9.90681 5.38861 9.93038 5.44552C9.95395 5.50242 9.96608 5.56341 9.96608 5.625C9.96608 5.68659 9.95395 5.74758 9.93038 5.80448C9.90681 5.86139 9.87227 5.91309 9.82871 5.95664L8.28477 7.5L9.82871 9.04336Z"
        fill="#F1623F"
      />
    </g>
  </svg>
);
