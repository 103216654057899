import {Text} from "@mantine/core"
import { useNavigate } from "react-router-dom"
import { tn } from "../../translation";


 export const NotiBox = ({width,
  text,
  date,
  time,
  image,
  path
}:any) => {


  const navigate = useNavigate();
     return <div
     onClick={() => {
        navigate(path);
     }}
     style={{
       width: "calc(100% - 20px)",
       background: "rgba(34, 4, 63, 0.05)",
       padding: "10px",
       borderRadius: "10px",
       gap: "1px",
       display: "flex",
       flexDirection: "column",
     }}
     >
     <div
       style={{
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "start",
         width: "100%",
         margin: "10px 0px",
         gap: "10px",
       }}
     >
       <img
         src={image??"/Salamon_short.svg"}
         style={{
           width: "60px",
           borderRadius: "10px",
           margin: "0px 10px",
           backgroundColor: "#fff",
           padding: "5px",
         }}
       />
       <Text style={{
        fontSize: width> 320 ? "15px" : "12px",
       }}>
        {text}
       </Text>
     </div>
     <div
       style={{
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "space-between",
         width: "100%",
         margin: "10px 0px",
         padding: "0px 10px",
         gap: "10px",
       }}
     >
       <Text style={{
         textAlign: "right",
         fontSize: width> 320 ? "12px" : "10px",
         fontStyle: "normal",
         fontWeight: "400",
         lineHeight: "95%",
         display: "flex",
         gap: "10px",
       }}>
        <Text style={{
         textAlign: "right",
         fontSize: width> 320 ? "12px" : "10px",
         fontStyle: "normal",
         fontWeight: "400",
         lineHeight: "95%",
       }}> {date}</Text>

       <Text style={{
         textAlign: "right",
         fontSize: width> 320 ? "12px" : "10px",
         fontStyle: "normal",
         fontWeight: "400",
         lineHeight: "95%",
       }}>{time}</Text>
         
          
       </Text>
       <Text
        style={{
         textAlign: "right",
         fontSize: width> 320 ? "12px" : "10px",
         fontStyle: "normal",
         fontWeight: "400",
         lineHeight: "95%",
         cursor: "pointer",
       }}>{
          tn("More")
       }</Text>
     </div>
     </div>
 }