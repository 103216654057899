import { Menu, Tooltip, Text, Button } from "@mantine/core";
import { TranslationContext } from "../../../context/TranslationContext";
import { useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { motion } from "framer-motion";
import { useViewportSize } from "@mantine/hooks";

export const ActionComponent = ({
  data,
  action,
  table,
  setData,
  enabled,
  visible = true,
  is_mobile
}: any) => {
  const { height, width } = useViewportSize();
  const { locale }: any = useContext(TranslationContext);
  const isSVG = action?.icon?.length > 500;
  if (!visible) return <></>;
  if(is_mobile && width <= 550) return (
    <Button
    disabled={!enabled}
    radius={10}
    size="md"
    style={{
      backgroundColor: enabled?action?.color:"",
      fontSize: "12px",
      fontWeight: 700,
      width: "100%",
      // maxWidth: `calc(100% / ${actions.length})`,
    }}
    leftIcon={
      isSVG ? (
        <div dangerouslySetInnerHTML={{ __html: action?.icon }}></div>
      ) : (
        <Icon style={{
          fontSize: "15px",
        }} icon={action?.icon} />
      )
    }
    onClick={() => {
      if (enabled) {
        setData();
      }
    }}
  >
    {action?.["label_" + locale]}
  </Button>
  )
  switch (table?.acions_type?.name) {
    case "menu":
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Menu.Item
            disabled={!enabled}
            color={action?.second_color}
            icon={
              isSVG ? (
                <div dangerouslySetInnerHTML={{ __html: action?.icon }}></div>
              ) : (
                <Icon icon={action?.icon} />
              )
            }
            onClick={() => {
              if (enabled) {
                setData();
              }
            }}
          >
            {action?.["label_" + locale]}
          </Menu.Item>
        </div>
      );
    case "items":
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip  label={action?.["label_" + locale]} withinPortal={true} >
            <motion.div
              whileHover={{ scale: enabled ? 1.1 : 1 }}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                margin: "0px 3px",
              }}
              onClick={() => {
                if (enabled) {
                  setData();
                }
              }}
            >
              {isSVG ? (
                <div  dangerouslySetInnerHTML={{ __html: action?.icon }}></div>
              ) : (
                <Icon
                  style={{ fontSize: "15px", opacity: enabled ? "1" : "0.5", }}
                  icon={action?.icon}
                  color={action?.color}
                />
              )}
            </motion.div>
          </Tooltip>
        </div>
      );
    case "text":
      return (
        <div
          style={{
            width: "100",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <motion.div
            whileHover={{ scale: enabled ? 1.1 : 1 }}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              margin: "0px 3px",
            }}
            onClick={() => {
              if (enabled) {
                setData();
              }
            }}
          >
            {isSVG ? (
              <div style={{
                marginBottom:"-8px"
              }} dangerouslySetInnerHTML={{ __html: action?.icon }}></div>
            ) : (
              <Icon
                style={{ fontSize: "15px", opacity: enabled ? "1" : "0.5" }}
                icon={action?.icon}
                color={action?.color}
              />
            )}
            <Text style={{
              color: enabled ? "black" : "gray",
              margin:"0px 5px",
            }}>{action?.["label_" + locale]}</Text>
          </motion.div>
        </div>
      );
  }
};
