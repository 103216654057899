import {
  TextInput,
  PasswordInput,
  Button,
  Text,
  Anchor,
  Modal,
  Select,
  Checkbox,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { isNotEmpty, useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { IconKey, IconMail, IconSchool } from "@tabler/icons-react";
import { Helmet } from "react-helmet";
import { TopShapeAuth } from "../components/TopShapeAuth";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { styled } from "styled-components";
import { UserContext } from "../../../context/UserContext";
import ReactMarkdown from "react-markdown";
import { vf } from "../../../main_components/Functions";

function Register() {
  const [opened, { open, close }] = useDisclosure(false);
  const { locale, translation }: any = useContext(TranslationContext);
  const { userCurrentData, setUserCurrentData }: any = useContext(UserContext);

  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [isErrorE, setisErrorE]: any = useState(false);
  // const {userCurrentData , setUserCurrentData} = useContext(UserContext);
  const validate_text = [
    vtn("case_study"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name"),
  ];
  const validatorsPassword = [
    vtn("password"),
    vtn("password1"),
    vtn("password2"),
    vtn("password3"),
    vtn("password4"),
    vtn("password5"),
    vtn("password_comfirm"),
  ];
  const validatePassword = (value: any, values: any) => {
    // Check for minimum 8 characters
    if (value.length < 8) return validatorsPassword[2];

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(value)) return validatorsPassword[3];

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(value)) return validatorsPassword[4];

    // Check for at least one of the special characters !@#%?
    if (!/[!”#$%&’()*+,-./:;<=>?@[\]^_`{|}~]/.test(value))
      return validatorsPassword[5];
    if (/^\S+ \S+$/.test(value)) return validatorsPassword?.[0];

    return null;
  };
  const form: any = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      caseStudy: "",
      password: "",
      confirm: "",
      terms: false,
    },

    validate: {
      caseStudy: isNotEmpty(validate_text?.[0]),
      firstName: (value) =>
        !/^\S+ \S+$/.test(value) && value.length > 2 && !/[0-9]/.test(value)
          ? /^[\u0621-\u064A\s]+$/.test(value)
            ? null
            : validate_text?.[7]
          : validate_text?.[1],
      lastName: (value) =>
        /^[\u0621-\u064A\s]+$/.test(value) ? null : validate_text?.[7],
      email: (value) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
          ? isErrorE
            ? validate_text?.[3]
            : null
          : validate_text?.[4],

      password: validatePassword,
      confirm: (value) =>
        value === form.values.password ? null : validate_text?.[6],
    },
  });
  const [debounced] = useDebouncedValue(form.values.email, 400);

  useEffect(() => {
    const resultList = pb
      .collection("applicants")
      .getList(1, 2, {
        filter: `email = "${form.values.email}"`,
      })
      .then((res: any) => {
        if (res.items.length === 0) {
          setisErrorE(false);
        } else {
          setisErrorE(true);
        }
      });
  }, [debounced]);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading]: any = useState(false);
  const [termsError, setTermsError]: any = useState(false);

  const errorMassage = tn("Invalid email or password");
  const termsTXT = tn("You must accept the terms and conditions");
  const terms = translation?.terms?.[0];

  const case_studies = vf("case_study");

  return (
    <>
      <Helmet>
        <title>{tn("Login") + " / " + tn("Coophub")}</title>
      </Helmet>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100vh",
        }}
        onSubmit={form.onSubmit(async (e: any) => {
          if (!form.values.terms) {
            setTermsError(true);
          } else {
            const data = {
              // username: name.split(" ").join("_").toLowerCase()+Math.floor(Math.random() * (1000 - 0 + 1)) + 0,
              email: e.email?.toLowerCase(),
              emailVisibility: true,
              password: e.password,
              case_study: e.caseStudy,
              passwordConfirm: e.confirm,
              first_name: e.firstName.trim(),
              last_name: e.lastName.trim(),
              gender: "male",
              GPA: 0,
              status: "inactive",
              country_mobile_number: "+966",
            };
            try {
              const record = await pb
                .collection("applicants")
                .create(data)
                .then(async (res: any) => {
                  // const authData = await pb
                  //   .collection("applicants")
                  //   .authWithPassword(res.email, e.password);
                  const senEmail = await pb
                    .collection("applicants")
                    .requestVerification(res.email?.toLowerCase());
                  setUserCurrentData({
                    ...res,
                    password: form?.values?.password,
                  });
                });

              navigate("/applicant/register_success");
            } catch (err) {
              setisError(true);
            }
          }

          // (optional) send an email verification request
          // await pb
          //   .collection("applicants")
          //   .requestVerification(email);
        })}
      >
        <TopShapeAuth />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            gap: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              width: "100%",
            }}
          >
            <TextInput
              placeholder={tn("First Name")}
              {...form.getInputProps("firstName")}
              {...InputStyle}
            />
            <TextInput
              placeholder={tn("Last Name")}
              {...form.getInputProps("lastName")}
              {...InputStyle}
            />
          </div>
          <TextInput
            icon={<IconMail {...IconStyle} />}
            placeholder={tn("Email Address")}
            {...form.getInputProps("email")}
            {...InputStyle}
          />
           <Select
            icon={<IconSchool {...IconStyle} />}
            placeholder={tn("Case Study")}
            {...form.getInputProps("caseStudy")}
            {...InputStyle}
            data={
              case_studies?.[0]
                ? case_studies?.map((option:any) => ({
                    value:option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
          />
          <PasswordInput
            icon={<IconKey {...IconStyle} />}
            placeholder={tn("Password")}
            {...form.getInputProps("password")}
            {...InputStyle}
          />
          <PasswordInput
            icon={<IconKey {...IconStyle} />}
            placeholder={tn("Password Confirm")}
            {...form.getInputProps("confirm")}
            {...InputStyle}
          />
        
          <div
            style={{
              width: "100%",
            }}
          >
            <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  marginTop: "0px",
                }}
              >
                <Checkbox
                color="color3"
                  checked={form.values.terms}
                  {...form.getInputProps("terms")}
                  mt="5px"
                  size="sm"
                  styles={() => ({
                    label: { cursor: "pointer", color: "white" },
                    input: {
                      cursor: "pointer",
                    },
                  })}
                />
                <Anchor<"a">
                  mx="md"
                  color="#FFB004"
                  href="#"
                  weight={700}
                  onClick={open}
                  style={{
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  {tn("I agree to the CoopHub Privacy Policy and Terms of Use")}
                </Anchor>
              </div>
            {termsError && (
              <Text size="13px" color="red">
                {termsTXT}
              </Text>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            width: "100%",
            padding: "0px 20px",
          }}
        >
          <Button
            loading={buttonLoading}
            color="color2"
            style={{
              width: "100%",
              height: "60px",
              borderRadius: "15px",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
            type="submit"
          >
            {tn("Login")}
          </Button>
          <Anchor
            onClick={() => navigate("/applicant/login")}
            color="#767676"
            style={{
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "700",
            }}
          >
            {tn("Login")}
          </Anchor>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",

              width: "100%",
              maxWidth: "500px",
              flexDirection: "row",
            }}
          >
           <a style={{textDecoration: "none"}} target="_blank" href="https://www.coophub.co/terms">
          <Text
              style={{
                textAlign: "center",
              }}
              fz="12px"
              color="#767676"
              weight={700}
            >
              {tn("terms and conditions")}
            </Text>
        </a>
        {divider}
        <a style={{textDecoration: "none"}} target="_blank" href="https://www.coophub.co/terms">
        <Text
              style={{
                textAlign: "center",
              }}
              fz="12px"
              color="#767676"
              weight={700}
            >
              {tn("Usage policy")}
            </Text>
        </a>
            {divider}
            <Text
              style={{
                textAlign: "center",
              }}
              fz="12px"
              color="#767676"
              weight={700}
            >
              {tn("All rights are save")}
            </Text>{" "}
          </div>
        </div>
        <Modal
          size="sm"
          opened={opened}
          onClose={close}
          centered
          styles={() => ({
            content: {
              borderRadius: "10px",
           
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            },
            header: { display: "none" },
            body: { padding: "5px" },
          })}
        >
          <div
            style={{
              marginTop: "20px",
              width: "100%",
              height: "60vh",
              background: "white",
              borderRadius: "5px",
              padding: "20px",
              overflowY: "scroll",
            }}
          >
            <MarkDownContainer>
              <ReactMarkdown children={terms?.[locale]} />
            </MarkDownContainer>
          </div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <Button
            color="gray"
            variant="outline"
              onClick={close}
              m="md"
              style={{ height: "30px" }}
            >
              {tn("close")}
            </Button>
          </div>
        </Modal>
      </form>
    </>
  );
}

export default Register;

const divider = (
  <div
    style={{
      width: "1px",
      height: "15px",
      background: "#767676",
      margin: "0px 5px",
    }}
  />
);

const InputStyle = {
  size: "lg",
  style: {
    width: "100%",
  },
  styles: () => ({
    input: {
      color: "#4b4b4b",
      border: "1px solid rgba(33, 3, 63, 0.50)",
      borderRadius: "15px",
    },
  }),
};

const IconStyle = {
  size: "1.5rem",
  stroke: 1.5,
  style: {
    color: "#a1a1a1",
  },
};

const MarkDownContainer = styled.div`
  // add styles for markdown here
  /* Headings */
  h1 ,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 1.5em;
    color: #21033f;
  }
  h2 {
    font-size: 1em;
    color: #21033f;
  }
  h3 {
    font-size: 0.7em;
  }
  h4 {
    font-size: 0.6em;
  }
  h5 {
    font-size: 0.5em;
  }
  h6 {
    font-size: 0.4em;
  }

  /* Paragraphs */
  p {
    line-height: 1.6;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  /* Links */
  a {
    color: #0077cc;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  /* Images */
  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  /* Lists */
  ul,
  ol {
    margin: 1em 0;
    padding-left: 2em;
  }

  li {
    margin-bottom: 0.5em;
  }

  /* Blockquotes */
  blockquote {
    border-left: 4px solid #ddd;
    padding-left: 1em;
    margin-left: 0;
    color: #666;
  }

  /* Code blocks and inline code */
  pre,
  code {
    font-family: "Courier New", monospace;
  }

  pre {
    overflow-x: scroll;
    padding: 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  code {
    background-color: #f5f5f5;
    padding: 0 0.5em;
    border-radius: 3px;
  }

  /* Horizontal Rules */
  hr {
    border: none;
    border-top: 1px solid #eee;
    margin: 2em 0;
  }

  /* Tables */
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 1em 0;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px 12px;
  }

  th {
    background-color: #f5f5f5;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;
