import { useContext } from "react";
import { DataContext } from "../../../../../../../context/DataContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import { BannersContext } from "../../../../../../../context/BannersContext";
import { ltn } from "../../../../../../../translation";
import { IconSquareRoundedX } from "@tabler/icons-react";
import { ActionComponent } from "../../../../ActionComponent";
export const AcceptScheduledInterview = (props: any) => {
  const { setAcceptScheduledInterview }: any = useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);

  const pb: any = new PocketBase(CMS_ENDPOINT);
  const dialogMassage = ltn("applicant_view_programs_full");
  const dialogMassage2 = ltn("applicant_accepted");

  const { data, action, table } = props;
  return (
    <ActionComponent
      data={data}
      action={action}
      table={table}
      setData={async () => {
        const record = await pb
          .collection("applicant_view_programs")
          .getOne(data?.program, {})
          ?.then(async (res: any) => {
            if (
              parseInt(res?.candidate_in_need) > res?.accepted_applicants_count
            ) {
              const applicant = await pb
                .collection("applicants_view")
                .getOne(data?.applicant);
              if (applicant?.number_of_accepted_applications == 0) {
                setAcceptScheduledInterview({
                  id: data.id,
                  record: data,
                  action: action,
                  table: table,
                });
              } else {
                setTopBanner({
                  message: dialogMassage2,
                  color: "#c02d2d",
                  top: "83px",
                  backgroundColor: "#fff2f2eb",
                  icon: <IconSquareRoundedX />,
                });
              }
            } else {
              setTopBanner({
                message: dialogMassage,
                color: "#c02d2d",
                top: "83px",
                backgroundColor: "#fff2f2eb",
                icon: <IconSquareRoundedX />,
              });
            }
          });
      }}
      enabled={true}
    />
  );
};
