import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";
import { pb } from "../../../../../../config";

export const ResendForApproval = (props: any) => {
  const { setResendForApproval }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  const when_be_able =
    (data?.status == "canceled" ||
    data?.status == "rejected" ||
    data?.status == "completed")&& pb.authStore.model.expand?.permission?.accessible_data != "all_data" ;;
  return (
     <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setResendForApproval({
      id: data.id,
      record: data,
      action: action,
      table: table,
    })}
    enabled={when_be_able}
    visible={when_be_able}
    />
  );
};
