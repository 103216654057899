
  import { Helmet } from "react-helmet";
import { DataGrid } from "../../../main_components/data-grid/DataGrid";
import { ttn, tn } from "../../../translation";
  
   function InvitationsToJoin() {
    return (
      <>
      <Helmet>
        <title>{ttn("invitations_to_join") + " / " + tn("Coophub")}</title>
      </Helmet>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 84px)",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            padding: "20px",
            paddingTop: "90px",
            background: "#fcfcfc",
          }}
        >
          <DataGrid name="invitations_to_join"/>
        </div>
      </>
    );
  }
  export default InvitationsToJoin;
