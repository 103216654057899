import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useState } from "react";
import {
  Avatar,
  Button,
  Loader,
  NativeSelect,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { tn } from "../../../../../../translation";
import {
  useDebouncedState,
  useDebouncedValue,
  useViewportSize,
} from "@mantine/hooks";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { BannersContext } from "../../../../../../context/BannersContext";
import { DataContext } from "../../../../../../context/DataContext";
import { pb } from "../../../../../../config";
import { SystemContext } from "../../../../../../context/SystemContext";
import { use } from "echarts";

export function ViewRecruiter({ onClose, data }: any) {
  const [debounced_data] = useDebouncedValue(data?.record, 200);

  const { locale }: any = useContext(TranslationContext);
  const { systemData }: any = useContext(SystemContext);

  const { width } = useViewportSize();

  const [loading, setLoading] = useDebouncedState(false, 800);

  const { setEditRecruiter }: any = useContext(DataContext);
  const userPremtions = pb.authStore?.model?.expand?.permission?.permissions;
  const allowed = userPremtions?.some(
    (p: any) =>
      p ==
      systemData?.permissions?.find((p: any) => p?.action == "edit_recruiter")
        ?.id
  );
  return (
    <>
      {!loading ? (
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              background: "white",
              width: "100%",
              height: "380px",
              marginTop: "9px",
              borderRadius: "5px",
              paddingTop: "30px",
              paddingBottom: "40px",
              display: "flex",
              // flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "calc(27%)",
                height: "100%",
                display: "flex",
                margin: "10px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                background: "rgba(79, 81, 82, 0.05)",
              }}
            >
              <Avatar
                variant="outline"
                radius="100%"
                size={100}
                styles={() => ({
                  placeholderIcon: {
                    width: "120%",
                    height: "120%",
                    color: "#4F5152",
                    minWidth: "120%",
                    minHeight: "120%",
                  },
                })}
                src={`${CMS_ENDPOINT}/api/files/${debounced_data?.collectionName}/${debounced_data?.id}/${debounced_data?.avatar}?token=`}
              >
                <img
                  style={{
                    width: "125%",
                    height: "125%",
                  }}
                  src={"/avatar_placeholder.svg"}
                />
              </Avatar>
              <Text mt="md" fw={700} color="#4D4D4D" fz={16}>
                {debounced_data?.name?.split(" ")?.[0] +
                  " " +
                  debounced_data?.name?.split(" ")[1]}
              </Text>
              <Text mt={5} fw={700} color="#4D4D4D" fz={12}>
                {debounced_data?.role?.[locale]}
              </Text>
              <Text mt={5} fw={700} color="#4D4D4D" fz={12}>
                {debounced_data?.email}
              </Text>
              <div
                style={{
                  display: "flex",
                }}
              >
                {/* <Button
                    type="submit"
                    // radius was 0 here
                    onClick={() => {}}
                    style={{
                      top: "0px",
                      left: "0px",
                      position: "absolute",
                      background: "#69DCBF",
                      height: "25px",
                    //   width: "100px",
                      margin: "0px 20px",
                    }}
                  >
                    {tn("Edit data")}
                  </Button> */}
              </div>
            </div>
            <div
              style={{
                maxWidth: "calc(100% - 260px)",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                padding: "0px 20px",
                margin: "10px",
                justifyContent: "space-between",
                flexDirection: width < 850 ? "column" : "row",
                borderRadius: "10px",
                background: "rgba(79, 81, 82, 0.05)",
              }}
            >
              <div
                style={{
                  width: width < 850 ? "calc(100% - 10px)" : "calc(50% - 10px)",
                }}
              >
                <Text mb="xl" color="#676767" size="lg" fw="700">
                  {tn("Personal information")}
                </Text>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text color="#676767" size={12} fw="700">
                    {tn("First Name")}
                  </Text>
                  <StyleBox name={debounced_data?.name?.split(" ")?.[0]} />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "13px",
                  }}
                >
                  <Text color="#676767" size={12} fw="700">
                    {tn("Mobile")}
                  </Text>
                  <StyleBox name={debounced_data?.mobile} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "13px",
                  }}
                >
                  <Text color="#676767" size={12} fw="700">
                    {tn("Department")}
                  </Text>
                  <StyleBox name={debounced_data?.department_name} />
                  {/* <div
                      style={{
                        height: "30px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        boxSizing: "border-box",
                        fontSize: "0.875rem",
                        width: "60%",
                        color: "#727272",
                        backgroundColor: "#f1f3f5",
                        paddingRight: "calc(2.25rem / 3)",
                        paddingLeft: "calc(2.25rem / 3)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        border: "1px solid #000",
                        borderRadius: "5px",
                      }}
                    >
                      {debounced_data?.department?.[locale]}
                    </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "13px",
                  }}
                >
                  <Text color="#676767" size={12} fw="700">
                    {tn("Premmissions")}
                  </Text>
                  <StyleBox name={debounced_data?.expand?.permission?.name} />
                </div>
              </div>
              <div
                style={{
                  width: width < 850 ? "calc(100% - 10px)" : "calc(50% - 10px)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  marginTop: "-0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    //   marginTop: "48px",
                  }}
                >
                  <Text color="#676767" size={12} fw="700">
                    {tn("Last Name")}
                  </Text>
                  <StyleBox
                    name={debounced_data?.name?.split(" ")?.slice(1)?.join(" ")}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "13px",
                  }}
                >
                  <Text color="#676767" size={12} fw="700">
                    {tn("Email")}
                  </Text>
                  <StyleBox
                  end={locale=="ar"?true:false}
                    name={
                      debounced_data?.email +
                      debounced_data?.email +
                      debounced_data?.email
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "13px",
                  }}
                >
                  <Text color="#676767" size={12} fw="700">
                    {tn("Extension")}
                  </Text>
                  <StyleBox name={debounced_data?.extension} />
                </div>
              </div>
            </div>
            {allowed && (
              <Button
                // radius was 0 here
                onClick={() => {
                  setEditRecruiter(data);
                  onClose({});
                }}
                style={{
                  background: "#69DCBF",
                  top: "0px",
                  left: "0px",
                  position: "absolute",
                  height: "25px",
                  // width: "100px",
                  margin: "0px 20px",
                }}
              >
                {tn("Edit data")}
              </Button>
            )}
            <Button
              // radius was 0 here
              onClick={() => onClose({})}
              style={{
                background: "#F9837C",
                bottom: "0px",
                left: "0px",
                position: "absolute",
                height: "25px",
                width: "100px",
                margin: "8px 20px",
              }}
            >
              {tn("close")}
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            background: "white",
            height: "512.9px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "9px",
            borderRadius: "5px",
          }}
        >
          <Loader />
        </div>
      )}
    </>
  );
}
const StyleBox = ({ name,end }: any) => {
  return (
    <div dir={end?"ltr":""}
      style={{
        height: "30px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        boxSizing: "border-box",
        fontSize: "12px",
        width: "60%",
        color: "#242424",
        backgroundColor: "#f1f3f5",
        paddingRight: "calc(2.25rem / 3)",
        paddingLeft: "calc(2.25rem / 3)",
        display: "flex",
        alignItems: "center",
        border: "1px solid #000",
        borderRadius: "5px",
        // if text long then add ....
      }}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </div>
    </div>
  );
};
