import { tn } from "../../../../../../../translation";
import { DateInput } from "@mantine/dates";
import _ from "lodash";

import {
  Checkbox,
  Divider,
  Group,
  Loader,
  MultiSelect,
  NumberInput,
  Radio,
  Select,
  Slider,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import PocketBase from "pocketbase";

import { RequirementsList } from "./RequirementsList";
import { FirstForm } from "./FirstForm";
import { MiddleForm } from "./MiddleForm";
import { LastForm } from "./LastForm";
const pb = new PocketBase(CMS_ENDPOINT);
export const ProgramForm = ({
  form,
  data,
  buttons,
  title,
  company,
  disabled
}: any) => {
  const { translation, locale }: any = useContext(TranslationContext);

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const resultList = pb
      .collection("departments_view")
      .getList(1, 150, {})
      .then((res: any) => {
        setDepartments(res?.items);
      });
  }, []);
 
  useEffect(() => {
    if (form.values?.incentive == "") {
      form.setFieldValue("incentive", 0);
    }
  }, [form.values?.incentive]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          width: "100%",
          padding: "10px 20px 10px ",
          color: "rgba(0, 0, 0, 0.70)",
          textAlign: "right",
          fontSize: "22px",
          fontWeight: "700",
        }}
      >
        {title}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 10px",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
       <FirstForm
          form={form}
          locale={locale}
          translation={translation}
          departments={departments}
          company={company}
          disabled={disabled}
       />
       <MiddleForm
         form={form}
         locale={locale}
         translation={translation}
         company={company}
         disabled={disabled}
       />
         <LastForm
         form={form}
         locale={locale}
         translation={translation}
          company={company}
          disabled={disabled}
       />
       
      </div>
      {buttons}
    </div>
  );
};



