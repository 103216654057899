import { ltn, tn } from "../../../../../../../translation";
import { Button, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../../constants";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";

export const CancelInterview = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data, 200);

  const { reloader, setReloader } = useContext(AppContext);
  const [buttonLoading, setButtonLoading] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("cancel_interviews")
          : ltn("cancel_interview")}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
          width: "100%",
          gap: "10px",
        }}
      >
        <Button loading={buttonLoading}
          color="red"
          onClick={
            debounced_data?.record?.[0]
              ? async () => {
                  setButtonLoading(true);
                  for (const record of debounced_data?.record || []) {
                    await pb
                      .collection(debounced_data?.table?.table)
                      .update(record, {
                        main_status: "waiting_for_scheduling",
                        applicant_status: "under_review",
                        recruiter_status: "under_review",
                        date: "",
                        time: "",
                        duration: null,
                      });
                  }
                  onClose({});
                  setReloader(!reloader);
                }
              : async () => {
                setButtonLoading(true);

                  await pb
                    .collection(debounced_data?.table?.table)
                    .update(debounced_data?.record?.id, {
                      main_status: "waiting_for_scheduling",
                      applicant_status: "under_review",
                      recruiter_status: "under_review",
                      date: "",
                      time: "",
                      duration: null,
                    });
                  onClose({});
                  setReloader(!reloader);
                }
          }
        >
          {tn("Cancel interview")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
