import { ltn, tn } from "../../../../../translation";
import { Button, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";

export const HangPartialProgram = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const { reloader, setReloader } = useContext(AppContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const [buttonLoading, setButtonLoading] = useState(false);


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("hang_programs")
          : ltn("hang_program")}
      </Text>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          loading={buttonLoading}
          color="red"
          mx={5}
          onClick={
            data?.record?.[0]
              ? () => {
                  data?.record?.forEach(async (record: any) => {
                    await pb.collection(data?.table?.table).update(record, {
                      partial_status: "closed",
                    });
                  });	
                  onClose({});
                  setReloader(!reloader);
                }
              : async () => {
                  await pb
                    .collection(data?.table?.table)
                    .update(data?.record?.id, {
                      partial_status: "closed",
                    })
                  onClose({});
                  setReloader(!reloader);
                }
          }
        >
          {data?.record?.[1] ? tn("Hang Progarms") : tn("Hang Progarm")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
