import { ltn, tn } from "../../../../../../translation";

import { Button, Loader, Text } from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { BannersContext } from "../../../../../../context/BannersContext";
import { IconAlertTriangle, IconSquareRoundedX } from "@tabler/icons-react";
import { ProgramDetails } from "../../../../../../applicant/mobile/components/ProgramDetails";
import { DataContext } from "../../../../../../context/DataContext";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { handleApplyInProgramClick } from "../../../actions/applicants/programs_search/ApplyForProgram";
import { useNavigate } from "react-router-dom";
import { fv, vf } from "../../../../../Functions";
export const ApplicantViewProgram = ({ data, onClose }: any) => {
  const [debounced_data] = useDebouncedValue(data?.record, 200);
  const expandColumn = data?.record?.expand?.program;
  const messageTaken = ltn("applied_already");
  const messageUserData = ltn("fill_your_data");
  const messageAccepted = ltn("accepted_already");
  const messageAcceptedAnswer = ltn("accepted_already_answer");
  const GPA_not_enough = ltn("GPA_not_enough");
  const university_not_in_program = ltn("university_not_in_program");
  const case_study_not_in_program = ltn("case_study_not_in_program");
  const major_not_in_program = ltn("major_not_in_program");
  const degree_not_in_program = ltn("degree_not_in_program");
  const navigate = useNavigate();

  const { setApplyForProgram }: any = useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const { locale,translation}:any = useContext(TranslationContext);

  return <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        mx="xl"
        style={{
          width: "100%",
          padding: "10px 30px 10px ",
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "700",
          background:
            "var(--sc, linear-gradient(90deg, #21033F 0%, #69DCBF 100%))",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {tn("program details")}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 0px",
          flexDirection:  "column",
          alignItems:  "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            width:"calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "170px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                objectFit: "contain",
                width: "100%",
              }}
              src={
                debounced_data?.expand?.company?.logo
                  ? `${CMS_ENDPOINT}/api/files/${debounced_data?.expand?.company?.collectionName}/${debounced_data?.expand?.company?.id}/${debounced_data?.expand?.company?.logo}?token=`
                  : debounced_data?.company_logo
                  ? `${CMS_ENDPOINT}/api/files/${debounced_data?.collectionId}/${debounced_data?.id}/${debounced_data?.company_logo}?token=`
                  : "/dark_text_logo.svg"
              }
            />
            <Text
              mt="xl"
              style={{
                color: "#4D4D4D",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              {debounced_data?.expand?.company?.name ??
                debounced_data?.company_name}
            </Text>
          </div>
   
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: "10px",
              padding: "0px",
              flexWrap: "wrap",
              marginTop: "10px",
            }}
          >
         
            <TwoValuesBox
              value1={tn("Program Type")}
              value2={
               fv( expandColumn?.type ?? debounced_data?.type,"program_type",translation)?.[locale]
              }
            />
            {/* add program period */}
            <TwoValuesBox
              value1={tn("program period")}
              value2={
                fv( (expandColumn?.period ?? debounced_data?.period) , "period",translation)?.[locale]
               
              }
            />
            <TwoValuesBox
              value1={tn("City")}
              value2={
                fv( ( expandColumn?.city ?? debounced_data?.city) , "city",translation)?.[locale]
               
              }
            />
            {/* add program nature */}
            <TwoValuesBox
              value1={tn("Program nature")}
              value2={
                fv((expandColumn?.nature ?? debounced_data?.nature),"program_nature",translation)?.[locale]
              }
            />
              {/* add start date */}
              <TwoValuesBox
              value1={tn("Start date")}
              value2={new Date(
                expandColumn?.start_date ??
                  debounced_data?.start_date
              )?.toLocaleDateString("en-GB")}
            />
            <TwoValuesBox
              value1={tn("Last submission date")}
              value2={new Date(
                expandColumn?.last_submission_date ??
                  debounced_data?.last_submission_date
              )?.toLocaleDateString("en-GB")}
            />
               <TwoValuesBox
              value1={tn("program title")}
              value2={expandColumn?.name ?? debounced_data?.name}
            />
          </div>
          <div />
        </div>
        <div
          style={{
            width: "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            borderRadius: "10px",
            marginTop: "10px",
            // height: width > 850 ? "350px" : "",
          }}
        >
          <ProgramDetails data={debounced_data}  />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 0px",
          marginTop: "10px",
        }}
      >
        {debounced_data?.main_status != "document_review" && (
          <Button
          size="lg"
          radius="14px"
            style={{
              // color: "white",
              width: "100px",
              backgroundColor: "#69DCBF",
            }}
            // color="red"
            onClick={async () => {
                if (
                  await handleApplyInProgramClick({
                    setTopBanner,
                    messages: {
                      messageTaken,
                      messageUserData,
                      messageAccepted,
                      messageAcceptedAnswer,
                      GPA_not_enough,
                      university_not_in_program,
                      case_study_not_in_program,
                      major_not_in_program,
                      degree_not_in_program,
                    },
                    navigate,
                    data,
                  })
                ) {
                  setApplyForProgram({
                    id: data.id,
                    record: data,
                  });
                }
  
                onClose({});
              }}
          >
            {tn("Apply")}
          </Button>
        )}
        <Button
           size="lg"
           radius="14px"
          mx="sm"
          style={{
            color: "white",
            width: "100px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            onClose({});
          }}
        >
          {tn("close")}
        </Button>
      </div>
    </div>
};

const DataBlock = ({ name, value, width }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "0px 10px",
        marginBottom: "10px",
      }}
    >
      {" "}
      <Text
        style={{
          width: "150px",
          color: "#4F5152",
          fontSize: width > 550 ? "14px" : "10px",
          fontWeight: "700",
        }}
      >
        {name}
      </Text>
      <Text
        style={{
          minWidth: width > 550 ? "150px" : "100px",
          textAlign: "center",
          background: "rgb(255, 255, 255)",
          borderRadius: "5px",
          padding: "5px 10px",
          fontSize: width > 550 ? "14px" : "10px",
          fontWeight: "500",
          color: "#FFB004",
        }}
      >
        {value}
      </Text>
    </div>
  );
};

const TwoValuesBox = ({ value1, value2 }) => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: value2 ? "space-between" : "center",
        alignItems: "center",
        paddingBottom: "5px",
        borderRadius: "20px",
        background: "#FAF9F9",
        padding: "10px",
        gap: "10px",
        minWidth: "calc(50% - 5px)",
      }}
    >
      <Text
        style={{
          color: "#rgba(33, 3, 63, 0.62)",
          fontWeight: 400,
          fontSize: "10px",
        }}
      >
        {value1}
      </Text>
      <Text
        style={{
          color: "#FFB004",
          fontWeight: 400,
          fontSize: "10px",
        }}
      >
        {value2}
      </Text>
    </div>
  );
};
