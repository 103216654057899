import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
//
import AppContextProvider from "./context/AppContext";
import TabsContextProvider from "./context/TabsContext";
import DataContextProvider from "./context/DataContext.tsx";
import UserContextProvider from "./context/UserContext";
import TranslationContextProvider from "./context/TranslationContext";
import SystemContextProvider from "./context/SystemContext.tsx";
import BannersContextProvider from "./context/BannersContext.tsx";
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet";

Sentry.init({
  dsn: "https://6103ac92ac0104e9ef0008aaaacaf5b1@o4505936395304960.ingest.sentry.io/4505942892478464",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode> 
    <SystemContextProvider>
    <AppContextProvider>
      <UserContextProvider>
        {/* <TabsContextProvider> */}
          <DataContextProvider>
            <BannersContextProvider>
            <TranslationContextProvider>
            <App />
            </TranslationContextProvider>
            </BannersContextProvider>
          </DataContextProvider>
        {/* </TabsContextProvider> */}
      </UserContextProvider>
    </AppContextProvider>
    </SystemContextProvider>
  </React.StrictMode>
);
