import { useViewportSize } from "@mantine/hooks";
import { Text, Avatar, Switch, Button, FileButton } from "@mantine/core";
import { CMS_ENDPOINT } from "../../../constants";
import PocketBase from "pocketbase";
import { ReactNode, useContext, useEffect, useState } from "react";
import { tn } from "../../../translation";
import { TranslationContext } from "../../../context/TranslationContext";
import { motion } from "framer-motion";
import { IconPencil, IconSquareRoundedCheck, IconX } from "@tabler/icons-react";
import { MIME_TYPES } from "@mantine/dropzone";
import { BannersContext } from "../../../context/BannersContext";
import { AppContext } from "../../../context/AppContext";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function MainDataProfile() {

  const {reloader,setReloader}:any = useContext(AppContext)

  const [userData, setUserData] = useState(pb?.authStore?.model);
 
  
  const [discoverable, setDiscoverable] = useState(
    userData?.discoverable
  );
  const [onHover, setOnHover]: any = useState(false);

  const [avatar, setAvatar] = useState(
    userData?.avatar &&
      `${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.avatar}?token=`
  );

  useEffect(() => {
    setUserData(pb?.authStore?.model)
  }, [reloader])
  return (
    <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Switch
                checked={discoverable}
                onChange={async (e) => {
                  const record = pb
                    .collection("applicants")
                    .update(userData?.id, {
                      discoverable: e.target.checked,
                    })
                    .then((res: any) => {
                      setDiscoverable(res?.discoverable);
                      setReloader(!reloader);
                    });
                }}
                mx="sm"
                size="lg"
                color="yellow"
              />
              <Text mx="sm" color="#4D4D4D" size="md" fw="700">
                {tn("Discoverable")}
              </Text>
            </div>
            <FileButton
              maxSize={1024 ** 2}
              onChange={async (file: any) => {
                const formData = new FormData();
                formData.append("avatar", file);
                const record = pb
                  .collection("applicants")
                  .update(userData?.id, formData);
                setAvatar(URL.createObjectURL(file));
                setReloader(!reloader);
              }}
              accept="image/png,image/jpeg"
            >
              {(props) => (
                <motion.div
                  {...props}
                  style={{
                    cursor: "pointer",
                    padding: "1px",
                    position: "relative",
                  }}
                  onHoverStart={() => {
                    setOnHover(true);
                  }}
                  onHoverEnd={() => {
                    setOnHover(false);
                  }}
                >
                  <Avatar
                    styles={() => ({
                      placeholderIcon: {
                        width: "120%",
                        height: "120%",
                        color: "#4F5152",
                        minWidth: "120%",
                        minHeight: "120%",
                      },
                    })}
                    src={avatar}
                    size={100}
                    radius="100%"
                    variant="outline"
                  >
                    <img
                      style={{
                        width: "125%",
                        height: "125%",
                        opacity: "0.5",
                      }}
                      src={"/avatar_placeholder.svg"}
                    />
                  </Avatar>
                  {avatar && (
                      <motion.div
                        onClick={async (e) => {
                          e.stopPropagation();
                          setAvatar("");
                          const record = pb
                            .collection("applicants")
                            .update(userData?.id, {
                              avatar: null,
                            });
                          setReloader(!reloader);
                        }}
                       
                        style={{
                          width: "23px",
                          height: "23px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#F9837C",
                          borderRadius: "1000px",
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          padding: "2px",
                          transform: "translate(4px,4px)",
                          flexDirection: "column",
                        }}
                      >
                        <IconX color="white" size={20} />
                      </motion.div>
                    )}
                  <motion.div
                    animate={{
                      width: onHover ? "130px" : "36px",
                      height: onHover ? "130px" : "36px",
                    }}
                    style={{
                      width: "23px",
                      height: "23px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#21033fac",
                      border: "1px solid #ffffff9b",
                      borderRadius: "1000px",
                      position: "absolute",
                      bottom: "0px",
                      right: "0px",
                      padding: "2px",
                      transform: "translate(4px,4px)",
                      flexDirection: "column",
                    }}
                  >
                   
                    <IconPencil color="white" />
                    <motion.div
                      style={{
                        marginTop: "2px",
                        fontSize: "12px",
                        fontWeight: "900",
                        display: "flex",
                      }}
                      animate={{
                        display: onHover ? "flex" : "none",
                        color: "#FFF",
                        width: "70%",
                      }}
                    >
                      {tn("Uploading avatar")}
                    </motion.div>
                  </motion.div>
                </motion.div>
              )}
            </FileButton>
          </div>
  );
}
