import { DataGrid } from "../../../main_components/data-grid/DataGrid";
import { tn } from "../../../translation";



export function CompanyDepartments() {

    return (
        <div>
           {tn("Company departments")}
          
        </div>
    )
}