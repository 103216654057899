import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const InviteApplicant = (props: any) => {
  const { setInviteApplicant }: any = useContext(DataContext);

  const { data, action, table } = props;
  return (
    <ActionComponent
      data={data}
      action={action}
      table={table}
      setData={async () => {
        setInviteApplicant({
          id: data.id,
          record: data,
          action: action,
          table: table,
        });
      }}
      enabled={true}
    />
  );
};
