import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import { ltn, tn, vtn } from "../../../../../translation";
import dayjs from "dayjs";
import { DateInput } from "@mantine/dates";
import _ from "lodash";

import {
  Button,
  Checkbox,
  Divider,
  Group,
  Loader,
  MultiSelect,
  NativeSelect,
  NumberInput,
  RangeSlider,
  Select,
  Slider,
  Text,
  TextInput,
  Textarea,
  Transition,
} from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../constants";
import PocketBase from "pocketbase";
import { UserContext } from "../../../../../context/UserContext";
import { AppContext } from "../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { BannersContext } from "../../../../../context/BannersContext";
import {
  IconChevronLeft,
  IconChevronRight,
  IconSquareRoundedCheck,
} from "@tabler/icons-react";
import { RequirementsList } from "../recruiter_action/published_programs/program_form/RequirementsList";
import { format, set } from "date-fns";
import { it } from "date-fns/locale";
import { SystemContext } from "../../../../../context/SystemContext";
import { vf } from "../../../../Functions";
const pb = new PocketBase(CMS_ENDPOINT);
export const PublishPartialProgram = ({ data, onClose }: any) => {
  const [isErrorE, setisErrorE]: any = useState(false);
  const [debounced_data] = useDebouncedValue(data?.record, 200);

  const { translation, locale }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);
  const { systemData }: any = useContext(SystemContext);

  const [allUniversities, setAllUniversities] = useState(true);
  const requirements =vf("requirements");
  const program_type = vf("program_type");
  const city = vf("city");
  const major = vf("major");
  const case_study = vf("case_study");
  const period = vf("period");
  const university = vf("university");
  const fill_all_fields = vtn("fill_all_fields");
  const permissions = systemData?.permissions?.filter((item: any) =>
    pb.authStore?.model?.expand?.permission?.permissions.includes(item.id)
  );

  const form: any = useForm({
    initialValues: {
      department: debounced_data?.department,
      type: debounced_data?.type,
      name: debounced_data?.name,
      brief: debounced_data?.brief,
      objective: debounced_data?.objective,
      targeted_majors: debounced_data?.major?.[0]
        ? debounced_data?.major
        : [],
      target_educational_segment: debounced_data
        ?.target_educational_segment?.[0]
        ? debounced_data?.target_educational_segment
        : [],
      period: debounced_data?.period,
      candidate_in_need: parseInt(debounced_data?.candidate_in_need),
      city: debounced_data?.city,
      location: debounced_data?.location,
      last_submission_date: debounced_data?.last_submission_date?new Date(debounced_data?.last_submission_date):"",
      start_date: debounced_data?.start_date?new Date(debounced_data?.start_date):"",
      incentive: parseFloat(debounced_data?.incentive)
        ? parseFloat(debounced_data?.incentive)
        : 0,
      requirements: debounced_data?.requirements?.[0]
        ? debounced_data?.requirements
        : [],
      other_requirements: debounced_data?.other_requirements?.[0]
        ? debounced_data?.other_requirements
        : [],
      is_incentive: debounced_data?.incentive ? true : false,
      universities: debounced_data?.universities?.[0]
        ? debounced_data?.universities
        : [],
      academic_average_4: parseFloat(debounced_data?.GPA_4),
      academic_average_5: parseFloat(debounced_data?.GPA_5),
      application_link: debounced_data?.application_link
      // status: {},
    },

    validate: {
      department: isNotEmpty(vtn("required_field")),
      type: isNotEmpty(vtn("required_field")),
      name: isNotEmpty(vtn("required_field")),
      brief: isNotEmpty(vtn("required_field")),
      objective: isNotEmpty(vtn("required_field")),
      targeted_majors: isNotEmpty(vtn("required_field")),
      target_educational_segment: isNotEmpty(vtn("required_field")),
      period: isNotEmpty(vtn("required_field")),
      candidate_in_need: isNotEmpty(vtn("required_field")),
      city: isNotEmpty(vtn("required_field")),
      location: isNotEmpty(vtn("required_field")),
      last_submission_date: isNotEmpty(vtn("required_field")),
      start_date: isNotEmpty(vtn("required_field")),
      application_link: isNotEmpty(vtn("required_field")),
      universities: !allUniversities
        ? isNotEmpty(vtn("required_field"))
        : () => null,
      // requirements: hasLength({
      //   min: 1,
      //   message: vtn("required_field"),
      // }),
      other_requirements: (value: any) => {
        if (value?.[0]) {
          if (value?.filter((item: any) => item?.name == "")?.[0]) {
            return fill_all_fields;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
      // universities: hasLength({
      //   min: 1,
      //   message: vtn("required_field"),
      // }),
    },
  });
  useEffect(() => {
    if (form.values?.incentive == "") {
      form.setFieldValue("incentive", 0);
    }
  }, [form.values?.incentive]);

  const { width } = useViewportSize();

  const [buttonLoading, setButtonLoading] = useState(false)
  return width > 10 ? (
    <form
      style={{
        width: "100%",
      }}
      onSubmit={form?.onSubmit(async (value:any) => {
        const data = {
          department: value?.department,
          name: value?.name,
          brief: value?.brief,
          objective: value?.objective,
          candidate_in_need: value?.candidate_in_need,
          location: value?.location,

          incentive: value?.is_incentive ? value?.incentive?.toString() : null,
          type:  value?.type,
          period: value?.period,
          city: value?.city,

          requirements: value?.requirements,
          other_requirements: value?.other_requirements,
          universities: allUniversities
            ? [
                "all_universities",
              ]
            : value?.universities,
          target_educational_segment: value?.target_educational_segment,
          major: value?.targeted_majors,
          last_submission_date:dayjs(value?.last_submission_date).hour(23).minute(59).toDate(),
          start_date: value?.start_date,
          end_date: dayjs(value?.start_date)
            .add(
              parseInt(
                period?.find((item: any) => item?.name == value?.period)
                  ?.value
              ),
              "month"
            )
            .hour(23).minute(59).toDate(),
          publish_date: new Date(),
          partial_status: value?.status,
          GPA_4: value?.academic_average_4,
          GPA_5: value?.academic_average_5,
         

          company: pb.authStore?.model?.company,
          code: "C-1",
        };
        const codeFilter = await pb
          .collection("program_codes")
          .getList(1, 50, {
            sort: "code_number",
            order: "desc",
            filter: `code_type = "${data.type?.charAt(0)}"`,
          })
          ?.then((res: any) => {
            const item_1 =  _.maxBy(res?.items, 'code_number');
            if(debounced_data?.partial_status == "closed"){
              if (res?.items?.[0]) {
                const result = pb
                  .collection("programs")
                  .update(debounced_data.id,{
                    ...data,
                    code:debounced_data?.code,
                  })
                  ?.then((res: any) => {
                    if (res?.id) {
                      // setTopBanner({
                      //   show: true,
                      //   message: dialogMassage,
                      //   type: "success",
                      // });
                      setReloader(!reloader);
                      onClose({});
                    }
                  });
              } else {
                const result = pb
                  .collection("programs")
                  .update(debounced_data.id,{
                    ...data,
                    code: `${data.type?.charAt(0)}-1`,
                  })
                  ?.then((res: any) => {
                    if (res?.id) {
                      // setTopBanner({
                      //   show: true,
                      //   message: dialogMassage,
                      //   type: "success",
                      // });
                      setReloader(!reloader);
                      onClose({});
                    }
                  });
              }
            }else{

              if (res?.items?.[0]) {
                const result = pb
                  .collection("programs")
                  .create({
                    ...data,
                    code: `${item_1?.code_type}-${item_1?.code_number + 1}`,
                  })
                  ?.then((res: any) => {
                    if (res?.id) {
                      // setTopBanner({
                      //   show: true,
                      //   message: dialogMassage,
                      //   type: "success",
                      // });
                      setReloader(!reloader);
                      onClose({});
                    }
                  });
              } else {
                const result = pb
                  .collection("programs")
                  .create({
                    ...data,
                    code: `${data.type?.charAt(0)}-1`,
                  })
                  ?.then((res: any) => {
                    if (res?.id) {
                      // setTopBanner({
                      //   show: true,
                      //   message: dialogMassage,
                      //   type: "success",
                      // });
                      setReloader(!reloader);
                      onClose({});
                    }
                  });
              }
            }
            
          });
      })}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            width: "100%",
            padding: "10px 20px 10px ",
            color: "rgba(0, 0, 0, 0.70)",
            textAlign: "right",
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          {tn("Add a new program")}
        </Text>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 10px",
            flexDirection: width > 850 ? "row" : "column",
            alignItems: width > 850 ? "stretch" : "stretch",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "calc(100% - 10px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              background: "rgba(79, 81, 82, 0.05)",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                width: "93%",
                  background: "#21033F",
                height: "5px",
                borderRadius: "522px",
              }}
            />
            <Text
              style={{
                width: "100%",
                padding: "10px 20px",
                color: "rgba(0, 0, 0, 0.70)",
                textAlign: "right",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {tn("Basic data of the program:")}
            </Text>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
            
              <Select
                data={
                  program_type?.[0]
                    ? program_type?.map((item: any) => {
                        return {
                          value: item?.name,
                          label: item?.[locale],
                        };
                      })
                    : []
                }
                description={tn(
                  "Choose the type of training program you want to publish"
                )}
                withAsterisk
                label={tn("Program type")}
                {...form.getInputProps("type")}
                styles={() => TextInputStyle(form.errors?.type)}
                size="xs"
                style={{ width: "100%" }}
              />
               <TextInput
              style={{
                width: "100%",
                padding: "0px 10px",
              }}
              description={tn(
                "The name of the program, which will appear when it is published"
              )}
              withAsterisk
              label={tn("Program name")}
              {...form.getInputProps("name")}
              styles={() => TextInputStyle(form.errors?.name)}
              size="xs"
            />
            </div>
           
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <Textarea
                description={tn("Write a brief description of the program")}
                withAsterisk
                label={tn("Program brief")}
                {...form.getInputProps("brief")}
                styles={() => TextInputStyle(form.errors?.brief)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
              <Textarea
                description={tn(
                  "Write the program objectives and expected outcomes for the trainee"
                )}
                withAsterisk
                label={tn("Program objectives")}
                {...form.getInputProps("objective")}
                styles={() => TextInputStyle(form.errors?.objectives)}
                size="xs"
                style={{ width: "100%" }}
              />
            </div>{" "}
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <MultiSelect
                maxValues={2}
                searchable
                data={
                  major?.[0]
                    ? major?.map((item: any) => {
                        return {
                          value: item?.name,
                          label: item?.[locale],
                        };
                      })
                    : []
                }
                description={tn(
                  "University majors targeted in this training program"
                )}
                withAsterisk
                label={tn("Targeted majors")}
                {...form.getInputProps("targeted_majors")}
                styles={() => TextInputStyle(form.errors?.targeted_majors)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
              <MultiSelect
                data={
                  case_study?.[0]
                    ? case_study?.map((item: any) => {
                        return {
                          value: item?.name,
                          label: item?.[locale],
                        };
                      })
                    : []
                }
                description={tn(
                  "At the stage of study, or about to graduate or graduates"
                )}
                withAsterisk
                label={tn("Target educational segment")}
                {...form.getInputProps("target_educational_segment")}
                styles={() =>
                  TextInputStyle(form.errors?.target_educational_segment)
                }
                size="xs"
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <Select
                data={
                  period?.[0]
                    ? period?.map((item: any) => {
                        return {
                          value: item?.name,
                          label: item?.[locale],
                        };
                      })
                    : []
                }
                description={tn("Choose the duration of the program")}
                withAsterisk
                label={tn("program period")}
                {...form.getInputProps("period")}
                styles={() => TextInputStyle(form.errors?.period)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
              <NumberInput
                hideControls={true}
                description={tn(
                  "Enter the number of training seats available in this program"
                )}
                withAsterisk
                label={tn("Candidate in need")}
                {...form.getInputProps("candidate_in_need")}
                styles={() => TextInputStyle(form.errors?.candidate_in_need)}
                size="xs"
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <Select
                data={
                  city?.[0]
                    ? city?.map((item: any) => {
                        return {
                          value: item?.name,
                          label: item?.[locale],
                        };
                      })
                    : []
                }
                description={tn(
                  "Enter the city where this training opportunity is located"
                )}
                withAsterisk
                label={tn("City")}
                {...form.getInputProps("city")}
                styles={() => TextInputStyle(form.errors?.city)}
                size="xs"
                style={{ width: "100%" }}
              />{" "}
              <TextInput
                description={tn(
                  "To write the short national address to access the geographical location"
                )}
                withAsterisk
                label={tn("location")}
                {...form.getInputProps("location")}
                styles={() => TextInputStyle(form.errors?.location)}
                size="xs"
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <DateInput
                style={{ width: "100%", direction: "ltr" }}
                nextIcon={
                  <IconChevronRight size={20} style={{ color: "#1d604f" }} />
                }
                previousIcon={
                  <IconChevronLeft size={20} style={{ color: "#1d604f" }} />
                }
                firstDayOfWeek={0}
                minDate={new Date()}
                description={tn("Start date of the training program")}
                withAsterisk
                label={tn("Start Date")}
                {...form.getInputProps("start_date")}
                styles={() => TextInputStyleLTR(form.errors?.start_date)}
                size="xs"
              />
              <DateInput
                nextIcon={
                  <IconChevronRight size={20} style={{ color: "#1d604f" }} />
                }
                previousIcon={
                  <IconChevronLeft size={20} style={{ color: "#1d604f" }} />
                }
                firstDayOfWeek={0}
                disabled={!form?.values?.start_date}
                maxDate={form?.values?.start_date}
                description={tn(
                  "The last date for receiving applications to join"
                )}
                withAsterisk
                label={tn("Last submission date")}
                {...form.getInputProps("last_submission_date")}
                styles={() =>
                  TextInputStyleLTR(form.errors?.last_submission_date)
                }
                size="xs"
                style={{ width: "100%", direction: "ltr" }}
              />
            </div>
            <TextInput
              style={{
                marginTop: "10px",
                width: "100%",
                padding: "0px 10px",
              }}
              description={tn(
                "Link to apply for the training opportunity on the official website"
              )}
              withAsterisk
              label={tn("Application link")}
              {...form.getInputProps("application_link")}
              styles={() => TextInputStyle(form.errors?.name)}
              size="xs"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "30px 0px 10px",
                padding: "0px 5px",
              }}
            >
              <Checkbox
                {...form.getInputProps("is_incentive")}
                defaultChecked={true}
                mx="5px"
                size="xs"
                styles={() => ({
                  label: {
                    fontSize: "12px",
                    color: "#FFB004",
                  },
                })}
                label={tn(
                  "Is there a financial reward for the trainee in this program?"
                )}
              />
              <NumberInput
              disabled={!form?.values?.is_incentive}
                {...form.getInputProps("incentive")}
                style={{
                  width: "100px",
                }}
                rightSection={<Text color="#5c5c5c">{tn("riyal")}</Text>}
                rightSectionProps={{
                  fontSize: "10px",
                }}
                rightSectionWidth={40}
                mx="5px"
                size="xs"
                hideControls={true}
                styles={() => TextInputStyle(form.errors?.incentive)}
              />
            </div>
            
            <Divider mb="md" style={{
              width: "100%",}}/>
            <div
                onClick={() => {
                  setAllUniversities(true);
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  padding: "0px 10px",
                  margin: "0px 0px 0px",
                }}
              >
                <Checkbox
                  checked={allUniversities}
                  style={{
                    margin: "0px 0px 0px",
                  }}
                />
                <Text color={allUniversities ? "black" : "dimmed"} mx="lg">
                  {tn("all universities")}
                </Text>
              </div>
              <div
                onClick={() => {
                  setAllUniversities(false);
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  padding: "0px 10px",
                }}
              >
                <Checkbox
                  checked={!allUniversities}
                  style={{
                    margin: "20px 0px 0px",
                  }}
                />

                <MultiSelect
                  disabled={allUniversities}
                  searchable
                  data={
                    university?.[0]
                      ? university?.map((item: any) => {
                        return {
                          value: item?.name,
                          label: item?.[locale],
                        };
                        })
                      : []
                  }
                  style={{
                    marginTop: "0px",
                    width: "100%",
                    padding: "0px 10px",
                  }}
                  description={tn(
                    "Choose the universities whose students are targeted for this program"
                  )}
                  withAsterisk
                  label={tn("Target universities")}
                  {...form.getInputProps("universities")}
                  styles={TextInputStyle(form.errors?.universities)}
                  size="xs"
                />
              </div>
              <div
                style={{
                  height: "82px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "20px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <Text fz="xs">{tn("Academic average of 5")}</Text>
                  <Slider
                    onChangeEnd={(e) =>
                      form?.setFieldValue("academic_average_5", e)
                    }
                    // {...form.getInputProps("academic_average_5")}
                    defaultValue={3}
                    min={0}
                    max={5}
                    step={0.1}
                    // inverted
                    thumbSize={12}
                    styles={{
                      thumb: {
                        borderWidth: 0,
                        background: "white",
                        fill: "var(--neutral-white, #FFF)",
                        filter:
                          "drop-shadow(0px 0.5px 1.8px rgba(0, 0, 0, 0.351))",
                      },
                    }}
                    size="xs"
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <Text fz="xs">{tn("Academic average of 4")}</Text>
                  <Slider
                    onChangeEnd={(e) =>
                      form?.setFieldValue("academic_average_4", e)
                    }
                    // {...form.getInputProps("academic_average_4")}
                    defaultValue={3}
                    min={0}
                    // inverted
                    max={4}
                    step={0.1}
                    size="xs"
                    thumbSize={12}
                    styles={{
                      thumb: {
                        borderWidth: 0,
                        background: "white",
                        fill: "var(--neutral-white, #FFF)",
                        filter:
                          "drop-shadow(0px 0.5px 1.8px rgba(0, 0, 0, 0.351))",
                      },
                    }}
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
              </div>
          </div>
         
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            padding: "20px 0px",
          }}
        >
              <Button
              loading={buttonLoading}
                type="submit"
                mx="xs"
                compact
                style={{
                  backgroundColor: "#69DCBF",
                  color: "white",
                  minWidth: "100px",
                  borderRadius: "5.112px",
                }}
                onClick={() => {	
                  form.setFieldValue("status", "published");
                }}
                  
              >
                {tn("Create and publish")}
              </Button>
              
            

          <Button
            compact
            mx="sm"
            style={{
              color: "white",
              width: "100px",
              borderRadius: "5.112px",
              backgroundColor: "#F9837C",
            }}
            color="red"
            onClick={() => {
              onClose({});
            }}
          >
            {tn("Cancel")}
          </Button>
        </div>
      </div>
    </form>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};
const TextInputStyle = (error: any) => ({
  description: {
    marginBottom: "-17px",
    transform: "translate(5px, 35px)",
    fontSize: "8px",
    opacity: error ? "0" : "1",
  },
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  error: {
    marginBottom: "-17px",
    transform: "translate(4px, 3px)",
    // fontSize: "8px",
  },
});
const TextInputStyleLTR = (error: any) => ({
  root: {
    position: "relative",
    marginTop: "23px",
  },
  description: {
    marginBottom: "-17px",
    transform: "translate(5px, 35px)",
    fontSize: "8px",
    opacity: error ? "0" : "1",
  },
  label: {
    zIndex: "10",
    position: "absolute",
    left: "0px",
    margin: "0px 10px",
    transform: "translate(0px, -10px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  calendarHeader: {},
  error: {
    marginBottom: "-17px",
    transform: "translate(4px, 3px)",
    // fontSize: "8px",
  },
});

const CheckboxStyle = {
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    background: "rgb(255, 255, 255)",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "5px",
  },
};

const CheckIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.5917 5.88446 12.9491 4.33566 11.8067 3.19329C10.6643 2.05093 9.11555 1.40827 7.5 1.40625ZM9.84375 7.96875H7.96875V9.84375C7.96875 9.96807 7.91937 10.0873 7.83146 10.1752C7.74355 10.2631 7.62432 10.3125 7.5 10.3125C7.37568 10.3125 7.25645 10.2631 7.16855 10.1752C7.08064 10.0873 7.03125 9.96807 7.03125 9.84375V7.96875H5.15625C5.03193 7.96875 4.9127 7.91936 4.8248 7.83146C4.73689 7.74355 4.6875 7.62432 4.6875 7.5C4.6875 7.37568 4.73689 7.25645 4.8248 7.16854C4.9127 7.08064 5.03193 7.03125 5.15625 7.03125H7.03125V5.15625C7.03125 5.03193 7.08064 4.9127 7.16855 4.82479C7.25645 4.73689 7.37568 4.6875 7.5 4.6875C7.62432 4.6875 7.74355 4.73689 7.83146 4.82479C7.91937 4.9127 7.96875 5.03193 7.96875 5.15625V7.03125H9.84375C9.96807 7.03125 10.0873 7.08064 10.1752 7.16854C10.2631 7.25645 10.3125 7.37568 10.3125 7.5C10.3125 7.62432 10.2631 7.74355 10.1752 7.83146C10.0873 7.91936 9.96807 7.96875 9.84375 7.96875Z"
      fill="#69DCBF"
    />
  </svg>
);

const Xicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g opacity="0.85">
      <path
        d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM11.648 10.852C11.7002 10.9043 11.7417 10.9663 11.77 11.0346C11.7983 11.1029 11.8128 11.1761 11.8128 11.25C11.8128 11.3239 11.7983 11.3971 11.77 11.4654C11.7417 11.5337 11.7002 11.5957 11.648 11.648C11.5957 11.7002 11.5337 11.7417 11.4654 11.77C11.3971 11.7983 11.3239 11.8128 11.25 11.8128C11.1761 11.8128 11.1029 11.7983 11.0346 11.77C10.9663 11.7417 10.9043 11.7002 10.852 11.648L9 9.79523L7.14797 11.648C7.09571 11.7002 7.03367 11.7417 6.96538 11.77C6.8971 11.7983 6.82391 11.8128 6.75 11.8128C6.67609 11.8128 6.60291 11.7983 6.53462 11.77C6.46634 11.7417 6.4043 11.7002 6.35203 11.648C6.29977 11.5957 6.25832 11.5337 6.23003 11.4654C6.20175 11.3971 6.18719 11.3239 6.18719 11.25C6.18719 11.1761 6.20175 11.1029 6.23003 11.0346C6.25832 10.9663 6.29977 10.9043 6.35203 10.852L8.20477 9L6.35203 7.14797C6.24649 7.04242 6.18719 6.89927 6.18719 6.75C6.18719 6.60073 6.24649 6.45758 6.35203 6.35203C6.45758 6.24648 6.60074 6.18719 6.75 6.18719C6.89927 6.18719 7.04242 6.24648 7.14797 6.35203L9 8.20477L10.852 6.35203C10.9043 6.29977 10.9663 6.25831 11.0346 6.23003C11.1029 6.20174 11.1761 6.18719 11.25 6.18719C11.3239 6.18719 11.3971 6.20174 11.4654 6.23003C11.5337 6.25831 11.5957 6.29977 11.648 6.35203C11.7002 6.40429 11.7417 6.46634 11.77 6.53462C11.7983 6.6029 11.8128 6.67609 11.8128 6.75C11.8128 6.82391 11.7983 6.8971 11.77 6.96538C11.7417 7.03366 11.7002 7.09571 11.648 7.14797L9.79524 9L11.648 10.852Z"
        fill="#F1623F"
      />
    </g>
  </svg>
);
