



import { tn } from "../../../translation";



export function AppearanceIdentity() {

    return (
        <div>
           {tn("Appearance and identity")}
        </div>
    )
}