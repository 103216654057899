
import { tn } from "../../../translation";



export function ManagingEvaluationForms() {

    return (
        <div>
           {tn("Managing evaluation forms")}
        </div>
    )
}