import { useParams } from "react-router-dom";
import { DataGrid } from "../../../main_components/data-grid/DataGrid";
import { tn } from "../../../translation";



export function UsersData() {
 const {id} = useParams();
    return (
        <div>
           {/* {tn("Users data")} */}
           <div
        style={{
          width: "100%",
          height: "calc(100vh - 240px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "30px",
          background: "#fcfcfc",
        }}
      >
        <DataGrid name="recruiter_manage" filterText={`company = "${id}"`}/>
      </div>
        </div>
    )
}