import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const TerminateApplicant = (props: any) => {
  const { setTerminateApplicant }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setTerminateApplicant(
      {
        id: data.id,
        record:data,
        action:action,
        table:table
      }
    )}
    enabled={true}
    />
  );
};
