import { DataGrid } from "../../main_components/data-grid/DataGrid"

export const PartialRecruitersPage = () => {
    return (
        <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "30px",
          background: "#fcfcfc",
        }}
      >
            <DataGrid name="partial_recruiters_manage"/>
        </div>
    )
}