import { TextInput } from "@mantine/core";
import { tn } from "../../../../../../../translation";

export const RequirementsList = ({form,disabled}:any) => <>
              {form?.values?.other_requirements.map((item: any) => {
               
              return <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "0px",
                  margin: "0px 0px 10px",
                }}
              >
                <div
                  style={{
                    margin: "0px 10px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    opacity: disabled?"0.2":"1"
                  }}
                  onClick={()=>{
                    if(!disabled){
                      form.setFieldValue("other_requirements", [
                        ...form.values?.other_requirements?.filter(
                          (value: any) => value?.id != item?.id
                        ),
                      ]);
                    }
                  
                  }}
                >
                  {Xicon}
                </div>
                <TextInput
                disabled={disabled}
                error={form?.errors?.other_requirements?true:false}
                value={form.values?.other_requirements?.find(
                  (value: any) => value?.id == item?.id
                )?.name}
                 onChange={(e: any) => {
                  form.setFieldValue("other_requirements", [
                    ...form.values?.other_requirements?.map((value: any) => {
                      if (value?.id == item?.id) {
                        return {
                          ...value,
                          name: e.target.value,
                        };
                      } else {
                        return value;
                      }
                    }),
                  ]);
                 }}

                  size="xs"
                  style={{
                    width: "100%",
                  }}
                  styles={() => textInputStyle}
                /></div>})}
                 { Array(2 - form.values?.other_requirements?.length)?.fill().map((item,i) => {
               
               return <div
                 style={{
                   width: "100%",
                   display: "flex",
                   alignItems: "center",
                   justifyContent: "space-between",
                   gap: "0px",
                   marginBottom: "10px",
                 }}
               >
                 <div
                   style={{
                     margin: "0px 10px",
                     display: "flex",
                     alignItems: "center",
                     cursor: !disabled ?(i == 0?"pointer":"not-allowed"): "not-allowed",
                     filter: i == 0?"":"grayscale(30%)",
                     opacity: !disabled ?(i == 0?"1":"0.2"): "0.2"
                   }}
                   onClick={()=>{
                    if(!disabled){
                      if(i == 0){
                        form.setFieldValue("other_requirements", [
                          ...form.values?.other_requirements,
                          {
                            name: "",
                            id: Math.random(),
                          }]);
                    }
                    }
                   
                   }}
                 >
                   {CheckIcon}
                 </div>
                 <TextInput
                 placeholder={i == 0?tn("Add other requirement"):""}
                 disabled 
 
                   size="xs"
                   style={{
                     width: "100%",
                   }}
                   styles={() => textInputStyle}
                 /></div>})}</>
                 const textInputStyle = {
                    description: {
                      marginBottom: "-17px",
                      transform: "translate(5px, 33px)",
                      fontSize: "8px",
                    },
                    label: {
                      zIndex: "10",
                      position: "relative",
                      margin: "0px 10px",
                      transform: "translate(0px, 7px)",
                      background: "#f6f6f6",
                      borderRadius: "5px",
                      padding: "0px 5px",
                      fontSize: "10px",
                    },
                    input: {
                      borderRadius: "5px",
                      border: "1px solid #000000",
                    },
                  };
                  const CheckboxStyle = {
                    root: {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      background: "rgb(255, 255, 255)",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "5px",
                    },
                  };
                  
                  const CheckIcon = (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.5917 5.88446 12.9491 4.33566 11.8067 3.19329C10.6643 2.05093 9.11555 1.40827 7.5 1.40625ZM9.84375 7.96875H7.96875V9.84375C7.96875 9.96807 7.91937 10.0873 7.83146 10.1752C7.74355 10.2631 7.62432 10.3125 7.5 10.3125C7.37568 10.3125 7.25645 10.2631 7.16855 10.1752C7.08064 10.0873 7.03125 9.96807 7.03125 9.84375V7.96875H5.15625C5.03193 7.96875 4.9127 7.91936 4.8248 7.83146C4.73689 7.74355 4.6875 7.62432 4.6875 7.5C4.6875 7.37568 4.73689 7.25645 4.8248 7.16854C4.9127 7.08064 5.03193 7.03125 5.15625 7.03125H7.03125V5.15625C7.03125 5.03193 7.08064 4.9127 7.16855 4.82479C7.25645 4.73689 7.37568 4.6875 7.5 4.6875C7.62432 4.6875 7.74355 4.73689 7.83146 4.82479C7.91937 4.9127 7.96875 5.03193 7.96875 5.15625V7.03125H9.84375C9.96807 7.03125 10.0873 7.08064 10.1752 7.16854C10.2631 7.25645 10.3125 7.37568 10.3125 7.5C10.3125 7.62432 10.2631 7.74355 10.1752 7.83146C10.0873 7.91936 9.96807 7.96875 9.84375 7.96875Z"
                        fill="#7114d3"
                      />
                    </svg>
                  );
                  
                  const Xicon = (
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g opacity="0.85">
                      <path d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM11.648 10.852C11.7002 10.9043 11.7417 10.9663 11.77 11.0346C11.7983 11.1029 11.8128 11.1761 11.8128 11.25C11.8128 11.3239 11.7983 11.3971 11.77 11.4654C11.7417 11.5337 11.7002 11.5957 11.648 11.648C11.5957 11.7002 11.5337 11.7417 11.4654 11.77C11.3971 11.7983 11.3239 11.8128 11.25 11.8128C11.1761 11.8128 11.1029 11.7983 11.0346 11.77C10.9663 11.7417 10.9043 11.7002 10.852 11.648L9 9.79523L7.14797 11.648C7.09571 11.7002 7.03367 11.7417 6.96538 11.77C6.8971 11.7983 6.82391 11.8128 6.75 11.8128C6.67609 11.8128 6.60291 11.7983 6.53462 11.77C6.46634 11.7417 6.4043 11.7002 6.35203 11.648C6.29977 11.5957 6.25832 11.5337 6.23003 11.4654C6.20175 11.3971 6.18719 11.3239 6.18719 11.25C6.18719 11.1761 6.20175 11.1029 6.23003 11.0346C6.25832 10.9663 6.29977 10.9043 6.35203 10.852L8.20477 9L6.35203 7.14797C6.24649 7.04242 6.18719 6.89927 6.18719 6.75C6.18719 6.60073 6.24649 6.45758 6.35203 6.35203C6.45758 6.24648 6.60074 6.18719 6.75 6.18719C6.89927 6.18719 7.04242 6.24648 7.14797 6.35203L9 8.20477L10.852 6.35203C10.9043 6.29977 10.9663 6.25831 11.0346 6.23003C11.1029 6.20174 11.1761 6.18719 11.25 6.18719C11.3239 6.18719 11.3971 6.20174 11.4654 6.23003C11.5337 6.25831 11.5957 6.29977 11.648 6.35203C11.7002 6.40429 11.7417 6.46634 11.77 6.53462C11.7983 6.6029 11.8128 6.67609 11.8128 6.75C11.8128 6.82391 11.7983 6.8971 11.77 6.96538C11.7417 7.03366 11.7002 7.09571 11.648 7.14797L9.79524 9L11.648 10.852Z" fill="#F1623F"/>
                    </g>
                  </svg>
                  );
                  