import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import { TranslationContext } from "../../../../../context/TranslationContext";
import { useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { DataContext } from "../../../../../context/DataContext";
import { motion } from "framer-motion";
import { SystemContext } from "../../../../../context/SystemContext";
import { AppContext } from "../../../../../context/AppContext";

export const Activator = (props: any) => {
  const { locale }: any = useContext(TranslationContext);
  const { systemData }: any = useContext(SystemContext);
  const { setActiveAcount,setDeactiveAcount }: any = useContext(DataContext);
  
  const { data, action , table } = props;

  const activate = systemData?.actions?.find((item:any)=>item.name == "account_activation");
  const deactivate = systemData?.actions?.find((item:any)=>item.name == "account_deactivation");
  return (
    <div style={{ width:"100%",
    height:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"}}>
    {table?.acions_type?.name== "menu"?  <Menu.Item
        color={data?.status =="active"?deactivate?.second_color:activate?.second_color}
        icon={data?.status =="active"?<Icon icon={deactivate?.icon} />:<Icon icon={activate?.icon} />}
        onClick={data?.status =="active"?() => {
          setDeactiveAcount({
            id: data.id,
            record:data,
            action:action,
            table:table
          });
        
        }:() => {
          setActiveAcount ({
            id: data.id,
            record:data,
            action:action,
            table:table
          });
        }}
      >
       {data?.status =="active"?deactivate?.["label_" + locale]:activate?.["label_" + locale]}
      </Menu.Item>: <Tooltip withinPortal={true} label={data?.status =="active"?deactivate?.["label_" + locale]:activate?.["label_" + locale]}>
        <motion.div whileHover={{ scale: 1.1 }} style={{
         cursor:"pointer",
         display:"flex",
         justifyContent:"center",
         alignItems:"center",
         width:"100%",
         height:"100%",
         margin:"0px 3px",
        }}
        onClick={data?.status =="active"?() => {
          setDeactiveAcount({
            id: data.id,
            record:data,
            action:action,
            table:table
          });
        }:() => {
          setActiveAcount ({
            id: data.id,
            record:data,
            action:action,
            table:table
          });
        }}
        >
          {data?.status =="active"?<Icon style={{fontSize:"15px"}} icon={deactivate?.icon} color={deactivate?.color} />:<Icon style={{fontSize:"15px"}} icon={activate?.icon} color={activate?.color} />}
        </motion.div>
        </Tooltip>}
    </div>
  );
};
