import { ltn, tn } from "../../../../../../translation";
import {Button, Text} from "@mantine/core"
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";

export const  TerminationProgramWithoutDocument = (props:any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data , onClose} = props;
  const {reloader, setReloader}:any = useContext(AppContext)
  const [debounced_data] = useDebouncedValue(data, 200);

     return <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"start",
        padding:"20px",
        
        width:"100%",

     }}>
      <Text fw={600} color="#5c5c5c">
      {debounced_data?.record?.[1]?ltn("termination_program_applicants"):ltn("termination_program_applicant")}
      </Text>
      <div style={{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        marginTop:"20px"

      }}>
        <Button color="green" mx={5}onClick={data?.record?.[0]? async () => {
  for (const record of data?.record) {
    await pb.collection(data?.table?.table).update(record, {
      termination_status: "completed"
    });
  }
  onClose({});
  setReloader(!reloader);
        }:async () => {
          await pb.collection(data?.table?.table).update(data?.record?.id,{	
            termination_status:"completed"
          });
          onClose({})
          setReloader(!reloader)
        }}>
          {tn("Terminate")}
        </Button>
        <Button variant="outline" color="gray" mx={5} onClick={() => onClose({})}>
          {tn("Cancel")}
        </Button>
      </div>
       
     </div>
}