import { ltn, tn } from "../../../../../translation";

import { Button, Loader, Text } from "@mantine/core";

import { useContext, useEffect, useState } from "react";
import { CMS_ENDPOINT } from "../../../../../constants";
import PocketBase from "pocketbase";
import {
  useDebouncedValue,
  useMediaQuery,
  useViewportSize,
} from "@mantine/hooks";
import { BannersContext } from "../../../../../context/BannersContext";
import { IconAlertTriangle, IconSquareRoundedX } from "@tabler/icons-react";
import { ProgramDetails } from "../../../../../applicant/web/components/ProgramDetails";
import { DataContext } from "../../../../../context/DataContext";
import { TranslationContext } from "../../../../../context/TranslationContext";
import { handleApplyInProgramClick } from "../../actions/applicants/programs_search/ApplyForProgram";
import { useNavigate } from "react-router-dom";
import { fv } from "../../../../Functions";
export const ApplicantViewProgram = ({ data, onClose }: any) => {
  const [debounced_data] = useDebouncedValue(data?.record, 200);
  const expandColumn = data?.record?.expand?.program;
  const isTab = useMediaQuery("(min-width: 850px)");
  const isMobile = useMediaQuery("(min-width: 550px)");
  const messageTaken = ltn("applied_already");
  const messageUserData = ltn("fill_your_data");
  const messageAccepted = ltn("accepted_already");
  const messageAcceptedAnswer = ltn("accepted_already_answer");
  const GPA_not_enough = ltn("GPA_not_enough");
  const university_not_in_program = ltn("university_not_in_program");
  const case_study_not_in_program = ltn("case_study_not_in_program");
  const major_not_in_program = ltn("major_not_in_program");
  const degree_not_in_program = ltn("degree_not_in_program");
  
  const { setApplyForProgram }: any = useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const { locale,translation } = useContext(TranslationContext);
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        mx="xl"
        style={{
          width: "100%",
          padding: "10px 30px 10px ",
          color: "rgba(0, 0, 0, 0.70)",
          textAlign: "right",
          fontSize: "22px",
          fontWeight: "700",
        }}
      >
        {tn("program details")}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 10px",
          flexDirection: isTab ? "row" : "column",
          alignItems: isTab ? "stretch" : "stretch",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            width: isTab ? "calc(30% - 20px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "93%",
              background: "#FFB004",
              minHeight: "8px",
              marginBottom: "10px",

              borderRadius: "522px",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "150px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                objectFit: "contain",
                width: "100%",
              }}
              src={
                debounced_data?.expand?.company?.logo
                  ? `${CMS_ENDPOINT}/api/files/${debounced_data?.expand?.company?.collectionName}/${debounced_data?.expand?.company?.id}/${debounced_data?.expand?.company?.logo}?token=`
                  : debounced_data?.company_logo
                  ? `${CMS_ENDPOINT}/api/files/${debounced_data?.collectionId}/${debounced_data?.id}/${debounced_data?.company_logo}?token=`
                  : "/dark_text_logo.svg"
              }
            />
            <Text
              mt="xl"
              style={{
                color: "#4D4D4D",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              {debounced_data?.expand?.company?.name ??
                debounced_data?.company_name}
            </Text>
          </div>
          <DataBlock
            isMobile={isMobile}
            name={tn("program title")}
            value={expandColumn?.name ?? debounced_data?.name}
          />
          <DataBlock
            name={tn("Program Type")}
            value={
              fv((expandColumn?.type ?? debounced_data?.type),"program_type",translation)?.[locale]
              
            }
            isMobile={isMobile}
          />
          <DataBlock
            name={tn("City")}
            value={
              fv((expandColumn?.city ?? debounced_data?.city),"city",translation)?.[locale]
              
            }
            isMobile={isMobile}
          />
          <DataBlock
            name={tn("Last submission date")}
            value={new Date(
              expandColumn?.last_submission_date ??
                debounced_data?.last_submission_date
            )?.toLocaleDateString("en-GB")}
            isMobile={isMobile}
          />
          <div />
        </div>
        <div
          style={{
            width: isTab ? "calc(70% - 20px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
            marginTop: isTab ? "" : "10px",
            // height: isTab ? "350px" : "",
          }}
        >
          <div
            style={{
              width: "93%",
              background: "#FFB004",
              minHeight: "8px",
              marginBottom: "10px",
              borderRadius: "522px",
            }}
          />
          <ProgramDetails
            data={debounced_data}
            isTab={isTab}
            isMobile={isMobile}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "10px 0px",
        }}
      >
        {(debounced_data?.main_status != "document_review" && data?.table?.name == "programs_search") && (
          <Button
            compact
            style={{
              // color: "white",
              width: "100px",
              borderRadius: "5.112px",
              backgroundColor: "#69DCBF",
            }}
            // color="red"
            onClick={async () => {
              if (
                await handleApplyInProgramClick({
                  setTopBanner,
                  messages: {
                    messageTaken,
                    messageUserData,
                    messageAccepted,
                    messageAcceptedAnswer,
                    GPA_not_enough,
                    university_not_in_program,
                    case_study_not_in_program,
                    major_not_in_program,
                    degree_not_in_program,
                  },
                  navigate,
                  data: data?.record,
                })
              ) {
                setApplyForProgram(data);
              }

              onClose({});
            }}
          >
            {tn("Apply")}
          </Button>
        )}
        <Button
          compact
          mx="sm"
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            onClose({});
          }}
        >
          {tn("close")}
        </Button>
      </div>
    </div>
  );
};

const DataBlock = ({ name, value, isMobile }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "0px 10px",
        marginBottom: "10px",
      }}
    >
      {" "}
      <Text
        style={{
          width: "150px",
          color: "#4F5152",
          fontSize: isMobile ? "14px" : "10px",
          fontWeight: "700",
        }}
      >
        {name}
      </Text>
      <Text
        style={{
          minWidth: isMobile ? "150px" : "100px",
          textAlign: "center",
          background: "rgb(255, 255, 255)",
          borderRadius: "5px",
          padding: "5px 10px",
          fontSize: isMobile ? "14px" : "10px",
          fontWeight: "500",
          color: "#FFB004",
        }}
      >
        {value}
      </Text>
    </div>
  );
};
