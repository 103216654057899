import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const AcceptAppointment = (props: any) => {
  const { setAcceptAppointment }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return (
    <ActionComponent
    is_mobile={true}
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setAcceptAppointment({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
      
    }}
    enabled={data?.interview_status == "waiting_for_confirmation"}
  />
   
  );
};
