import { Avatar, Button, Loader, Text } from "@mantine/core";
import { DataGrid } from "../../main_components/data-grid/DataGrid";
import { CMS_ENDPOINT } from "../../constants";
import { useContext, useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { tn } from "../../translation";
import dayjs from "dayjs";
import { TranslationContext } from "../../context/TranslationContext";
import styled from "styled-components";
import _ from "lodash";

import { he } from "date-fns/locale";
import { SystemContext } from "../../context/SystemContext";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { Modals } from "../../main_components/data-grid/components/modals/Modals";
import { transform } from "framer-motion";
import { Helmet } from "react-helmet";
import { Icon } from "@iconify/react/dist/iconify.js";
const pb: any = new PocketBase(CMS_ENDPOINT);

export const TaskBoard = () => {
  const { setAccountAdd }: any = useContext(DataContext);

  const { systemData }: any = useContext(SystemContext);

  const published_programs = systemData.tables?.find((item: any) => {
    return item?.name == "published_programs";
  });
  const navigate = useNavigate();
  const { reloader }: any = useContext(AppContext);

  const [numbers, setNumbers]: any = useState({});
  const [numbersDepartment, setNumbersDepartment]: any = useState({});
  const main_page_stats = _.sortBy(systemData?.main_page_stats, [
    "tabs_order",
  ])?.filter((page: any) =>
   ( page?.permissions != null
      ? pb.authStore.model?.expand?.permission?.permissions
          ?.includes(page?.permissions)
      : true) && page?.enable
  );
  useEffect(() => {
    const record = pb
      .collection("recruiters_numbers")
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbers(e);
      });
    const record_ = pb
      .collection("recruiters_department_number")
      .getFirstListItem("", {})
      ?.then((e: any) => {
        setNumbersDepartment(e);
      });
  }, [reloader]);
  const numbers_main =
    pb.authStore.model.expand?.permission?.accessible_data == "all_data"
      ? numbers
      : numbersDepartment;
  const { locale }: any = useContext(TranslationContext);
  const [userData, setUserData] = useState(pb?.authStore?.model);
  const days_en = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const days_ar = [
    "الأحد",
    "الاثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];
  if (numbers?.new_applications == undefined) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        <Helmet>
          <title>{tn("Welcome to Coophub")}</title>
        </Helmet>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{tn("Welcome to Coophub")}</title>
      </Helmet>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",

          padding: "20px",
          paddingTop: "30px",
          background: "#fcfcfc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <div
          style={{
            maxWidth: "1340px",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <WIDTH_620
            style={{
              width: "100%",
              display: "flex",
              alignItems: "stretch",
              justifyContent: "space-between",
              marginBottom: "20px",
              gap: "20px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                alignItems: "center",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <WIDTH_768
                style={{
                  height: "calc(100% - 7px)",
                  width: "100%",
                  display: "flex",
                  borderBottom: "1px solid #21033f4e",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "20px 0px",
                paddingBottom: "30px",
                }}
              >
                <WIDTH_620
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    mx="lg"
                    styles={() => ({
                      placeholderIcon: {
                        width: "120%",
                        height: "120%",
                        color: "#4F5152",
                        minWidth: "120%",
                        minHeight: "120%",
                      },
                    })}
                    src={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.avatar}?token=`}
                    size={100}
                    radius="100%"
                    variant="outline"
                  >
                    <img
                      style={{
                        width: "125%",
                        height: "125%",
                      }}
                      src={"/avatar_placeholder.svg"}
                    />
                  </Avatar>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <Text
                      style={{
                        color: "rgba(33, 3, 63, 0.67)",
                        fontWeight: "700",
                        fontSize: "23px",
                      }}
                    >
                      {tn("welcome back")}
                    </Text>
                    <Text
                      style={{
                        color: "#69DCBF",
                        fontWeight: "700",
                        fontSize: "30px",
                      }}
                    >
                      {userData?.first_name + " " + userData?.last_name + "،"}
                    </Text>
                  </div>
                </WIDTH_620>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0px 20px",
                    gap: "20px",
                  }}
                >
                  <Button  onClick={() => {
              setAccountAdd({
                id: "1",
                name: published_programs?.name,
                table: published_programs,
              });
            }}
                  size="md"
                    radius={12}
                    style={{
                      backgroundColor: "#21033F",
                    }}
                  >
                    {tn("Create New Program")}
                  </Button>
                  <Text
                    style={{
                      color: "#6e6e6e",
                      fontWeight: "400",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Icon
                      icon="oui:calendar"
                      style={{
                        fontSize: "20px",
                      }}
                    />
                    {locale == "ar"
                      ? days_ar[dayjs().day()]
                      : days_en[dayjs().day()]}{" "}
                    {dayjs().format("DD/M/YYYY")}
                  </Text>
                </div>
              </WIDTH_768>
            </div>
            {/* <WIDTH_620_100
            onClick={() => {
              setAccountAdd({
                id: "1",
                name: published_programs?.name,
                table: published_programs,
              });
            }}
            style={{
              background: "#C7C0CF",
              display: "flex",
              flexDirection: "column",
              borderRadius: "10px",
              alignItems: "center",
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "140px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                width: "calc(100% - 15px)",
                minHeight: "7px",
                background: "#21033F",
                borderRadius: "100px",
              }}
            />
            <Text
              style={{
                color: "#ffffffa4",
                fontWeight: "700",
                fontSize: "26px",
                textAlign: "center",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px 10px",
              }}
            >
              {tn("Create New Program")}
            </Text>
          </WIDTH_620_100> */}
          </WIDTH_620>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {main_page_stats?.map((item) => {
              return (
                <StatBox
                  title={item?.["label_" + locale]}
                  number={
                    item?.number_column?.split(",").reduce((acc, key) => {
                      return acc + (numbers_main?.[key] ?? 0);
                    }
                    ,0)
                    // numbers_main?.[item?.number_column] ??
                    // numbers?.[item?.number_column]
                  }
                  background={item?.color}
                  line_color={item?.line_color}
                  path={item?.path}
                />
              );
            })}
          </div>
          <div
            style={{
              width: "100%",
              height: "calc(100vh - 84px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              marginTop: "30px",
              borderRadius: "35px",
              boxShadow: "5px 5px 15px 2px #0000000d",
              background: "#ffffff",
              padding: "20px",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "1240px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: 1,
              }}
            >
              <div />
              <Text
                style={{
                  color: "#FFB004",
                  cursor: "pointer",
                 
                  marginRight: "20px",
                  marginLeft: "20px", 
                }}
                onClick={() => {
                  navigate("/recruiter/under_process/scheduled_interviews");
                }}
              >
                {tn("More")}
              </Text>
            </div>
            <div style={{
               marginTop: "-65px",
               width: "100%",
            }}>
 <DataGrid name="scheduled_interview_today" />
            </div>
           
          </div>{" "}
          <div
            style={{
              width: "20px",
              minHeight: "30px",
            }}
          />
        </div>
      </div>
    </>
  );
};
// main_page_stats
const WIDTH_768 = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const WIDTH_620 = styled.div`
  @media (max-width: 620px) {
    flex-direction: column;
  }
`;
const WIDTH_620_100 = styled.div`
  width: 200px;
  @media (max-width: 620px) {
    width: 100%;
  }
`;
const StatBox_1320 = styled.div`
  width: calc(100% / 5 - 16px);
  @media (max-width: 1320px) {
    width: calc(100% / 4 - 15px);
  }
  @media (max-width: 800px) {
    width: calc(100% / 3 - 14px);
  }
  @media (max-width: 550px) {
    width: calc(100% / 2 - 11px);
  }
  @media (max-width: 330px) {
    width: calc(100% / 1 - 11px);
  }
`;

// const WIDTH_768 = styled.div`
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

const StatBox = ({ title, number,  path }: any) => {
  const navigate = useNavigate();
  return (
    <StatBox_1320
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        borderRadius: "25px",
        padding: "10px 5px",
        boxShadow: "0px 0px 15px 2px #0000000d",
      }}
    >
  
      <Text
        style={{
          width: "100%",
          color: "#292a2b",
          fontWeight: "500",
          fontSize: "15px",
          padding: "3px 13px",
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          width: "100%",
          color: "#1c1d1d",
          fontWeight: "500",
          fontSize: "26px",
          padding: "0px 13px",
        }}
      >
        {number}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button
          onClick={() => {
            navigate(path);
          }}
          mb="5px"
          color="yellow"
          size="xs"
          compact
          mx="sm"
          variant="unstyled"
          radius="xl"
          style={{
            fontSize: "13px",
            color: "#FFB004",
           marginTop: "-10px",
           fontWeight: "700",
          }}
        >
          {tn("View details")}
        </Button>
      </div>
    </StatBox_1320>
  );
};
