import { TranslationContext } from "../../../../../../context/TranslationContext";
import { useContext } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { CMS_ENDPOINT, WEB_ENDPOINT } from "../../../../../../constants";
import { ActionComponent } from "../../../ActionComponent";
import { fv } from "../../../../../Functions";

export const DownloadTheDraftContract = (props: any) => {
  const { locale,translation }: any = useContext(TranslationContext);

  const { data, action, table } = props;
  const { setContractViewerData }: any = useContext(AppContext);
  const openNewTab = () => {
    const url = WEB_ENDPOINT + "/contract_viewer"; // Replace with your desired URL
    window.open(url, "_blank", "noopener,noreferrer");
  };
  //  console.log("contractfViewerData",data?.contract_data)
  const when_be_able = true;
  return (
    <ActionComponent
      is_mobile={true}
      data={data}
      action={action}
      table={table}
      setData={async () => {
        setContractViewerData({
          applicant: {
            ...data?.contract_data?.applicant,
            religion: fv(
              data?.contract_data?.applicant?.religion,
              "religion",translation
            )?.[locale],
            marital_status: fv(
              data?.contract_data?.applicant?.marital_status,
              "marital_status",translation
            )?.[locale],
            gender: fv(data?.contract_data?.applicant?.gender, "gender", translation)?.[
              locale
            ],
            // religion: data?.contract_data?.applicant?.religion?.[locale],
            // marital_status:
            //   data?.contract_data?.applicant?.marital_status?.[locale],
            // gender: data?.contract_data?.applicant?.gender?.[locale],
          },
          company: {
            ...data?.contract_data?.company,
            job_title: data?.contract_data?.company?.job_title?.[locale],
            logo: `${CMS_ENDPOINT}/api/files/${data?.collectionName}/${data?.id}/${data?.company_logo}?token=`,
          },
          program: {
            ...data?.contract_data?.program,
            programs_majors: data?.contract_data?.program?.programs_majors
              ?.map((item: any) => fv(item,"major",translation)?.[locale])
              .join(", "),
            program_type_translated:
            fv(data?.contract_data?.program?.program_type_translated,"program_type",translation)?.[locale]
          },
        });
        openNewTab();
      }}
      enabled={when_be_able}
    />
  );
};
