
import { tn } from "../../../translation";



export function SubscriptionDetails() {

    return (
        <div>
           {tn("Subscription details")}
        </div>
    )
}