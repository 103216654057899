import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { DataContext } from "../../../../../../context/DataContext";
import { motion } from "framer-motion";
import { IconSquareRoundedCheck } from "@tabler/icons-react";
import { BannersContext } from "../../../../../../context/BannersContext";
import { ltn } from "../../../../../../translation";
import { CMS_ENDPOINT, WEB_ENDPOINT } from "../../../../../../constants";
import { ActionComponent } from "../../../ActionComponent";

export const ShareLink = (props: any) => {
  const { locale }: any = useContext(TranslationContext);
  const { setTopBanner }: any = useContext(BannersContext);
   const dialogMassage = ltn("share_link");
  const { data, action , table } = props;
  if(data?.status != "new" && data?.main_status != "under_the_procedure") return null;
  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>{
      setTopBanner({
        message: dialogMassage,
        color: "#2DC071",
        top: "83px",
        backgroundColor: "#F2FFF7EB",
        icon: <IconSquareRoundedCheck />,
      });
      navigator.clipboard.writeText(WEB_ENDPOINT + "/applicant/programs_search/" + data.id);
    }}
    enabled={true}
    />
   
  );
};
