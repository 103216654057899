import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import AdminHeader from "../components/AdminHeader";
import { CMS_ENDPOINT } from "../../constants";
import { Navbars } from "../../main_components/navbar/Navbar";
import { useEffect } from "react";

const MainLayoutAdmin = () => {
  const navigate = useNavigate();
  const pb: any = new PocketBase(CMS_ENDPOINT);
  useEffect(() => {
    const refresh = pb.collection("admin").authRefresh();
      if(pb?.authStore?.model?.collectionName !== "admin" || pb?.authStore?.model?.verified !== true){
      navigate("/admin/login")
    }
  }, [])
  const isAllowed = pb?.authStore?.model?.collectionName === "admin" || pb?.authStore?.model?.verified === true;
  // const { company, setCompany}: any = useContext(UserContext);
  return (
   isAllowed? <Body>
      <div style={{zIndex:1001}}>
      <AdminHeader/></div>
      <div style={{
        width: "100vw",
        display: "flex",
        alignItems: "end",
        justifyContent: "center",
        
      }}>

     
      <Navbars name="admin_nav"/>
     
      <Content>
        <Outlet />
      </Content>
      </div>
    </Body>:<></>
  );
};
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

const Content = styled.div`
  width: 100%;
  max-height: calc(100vh - 84px);
  min-height: calc(100vh - 84px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: scroll;
`;
export default MainLayoutAdmin;
