import styled from "styled-components";
import { HomeHeader } from "../components/home_header";
import { MainHero } from "../components/main_hero";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Loader } from "@mantine/core";
import PocketBase from "pocketbase";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { CMS_ENDPOINT } from "../constants";

const AppLayout = () => {
  const navigate = useNavigate();
  const pb: any = new PocketBase(CMS_ENDPOINT);
  //   const { loading } = useContext(AppContext);
  //   const [debounced] = useDebouncedValue(loading, 200);

  return (
    <>
      {/* {debounced ? (
        <Body>
         
        </Body>
      ) : (
        <></>
      )}
      <Outlet /> */}
      <Outlet />
    </>
  );
};

const Body = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 10000000;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// const Content = styled.div`
// width: 100%;
// max-height:calc(100vh - 3.75rem);
// min-height:calc(100vh - 3.75rem);

//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: start;
//   overflow-y: scroll;
// `;

export default AppLayout;
