import { ltn, tn, vtn } from "../../../../../../../translation";

import {
  Button,
  Center,
  Checkbox,
  FileButton,
  FileInput,
  Loader,
  Select,
  Text,
  TextInput,
  Textarea,
  rem,
} from "@mantine/core";

import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../../../context/TranslationContext";
import { CMS_ENDPOINT, WEB_ENDPOINT } from "../../../../../../../constants";
import PocketBase from "pocketbase";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { isNotEmpty, useForm } from "@mantine/form";
import { AppContext } from "../../../../../../../context/AppContext";
import { MIME_TYPES } from "@mantine/dropzone";
import { IconUpload, IconPhoto } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import { fv, vf } from "../../../../../../Functions";
import { pb } from "../../../../../../../config";
export const FillContractData = ({ data, onClose }: any) => {
  const { translation, locale }: any = useContext(TranslationContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const { reloader, setReloader }: any = useContext(AppContext);
  const [buttonLoading, setButtonLoading] = useState(false);
  const applicant_exclude_verbs = translation?.applicant_correction_verbs;
  const religion = vf("religion");
  const marital_status = vf("marital_status");

  const form = useForm({
    initialValues: {
      religion: "",
      ID_number: "",
      marital_status: "",
      date_of_birth: "",
      national_address: "",
      bank_name: "",
      iban_number: "",
      checkbox: false,
    },
    validate: {
      religion: isNotEmpty(vtn("required_field")),
      ID_number: isNotEmpty(vtn("required_field")),
      marital_status: isNotEmpty(vtn("required_field")),
      date_of_birth: isNotEmpty(vtn("required_field")),
      national_address: isNotEmpty(vtn("required_field")),
    },
  });
  const { setContractViewerData, contractViewerData }: any =
    useContext(AppContext);
  const openNewTab = () => {
    const url = WEB_ENDPOINT + "/contract_viewer"; // Replace with your desired URL
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <form
      onSubmit={form.onSubmit(async () => { 
        const values = form.values;
         

        setButtonLoading(true);
        const record = await pb
          .collection("applications")
          .update(data?.record?.id, {
            contract_data: {
              applicant: {
                name:
                  (pb.authStore?.model?.first_name +
                  " " +
                  pb.authStore?.model?.last_name),
                ID_number: values.ID_number,
                religion: values.religion,
                marital_status: values.marital_status,
                date_of_birth: values.date_of_birth
                  ? new Date(values.date_of_birth).toLocaleDateString()
                  : "",
                national_address: values.national_address,
                bank_name: values.bank_name,
                iban_number: values.iban_number,
                email: pb.authStore?.model?.email,
                mobile_number: pb.authStore?.model?.mobile,
                gender: pb.authStore?.model?.gender,
                approved_date: new Date().toLocaleDateString(),
              },
            },
            contract_applicant_status: "under_revision",
            contract_issuance_status: "waiting_for_review",
          })
          setReloader(!reloader);
          onClose();
      })}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        padding: "20px",
      }}
    >
      <Text
        style={{
          width: "100%",
          fontSize: "20px",
          fontWeight: "700",
          padding: "10px 0px",
          color: "#4F5152",
        }}
      >
        {tn("Complete the contract information")}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          borderRadius: "10px",
        }}
      >
      
        <Text
          style={{
            width: "calc(100% - 20px)",
            fontSize: "15px",
            fontWeight: "700",
            padding: "10px 0px",
            color: "#4F5152",
            margin: "5px 10px",
          }}
        >
          {tn(
            "To complete the training contract requirements, fill out the following information"
          )}
        </Text>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <InputBox
              title={tn("ID Number")}
              name="ID_number"
              form={form}
              type="text"
              width="calc(50% - 10px)"
              options={religion?.map((item: any) => ({
                value: item.name,
                label: item?.[locale],
              }))}
            />
            <InputBox
              title={tn("Religion")}
              name="religion"
              form={form}
              type="select"
              width="calc(50% - 10px)"
              options={religion?.map((item: any) => ({
                value: item.name,
                label: item?.[locale],
              }))}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <InputBox
              title={tn("Date of birth")}
              name="date_of_birth"
              form={form}
              type="date"
              width="calc(50% - 10px)"
              options={religion?.map((item: any) => ({
                value: item.name,
                label: item?.[locale],
              }))}
            />
            <InputBox
              title={tn("Marital status")}
              name="marital_status"
              form={form}
              type="select"
              width="calc(50% - 10px)"
              options={marital_status?.map((item: any) => ({
                value: item.name,
                label: item?.[locale],
              }))}
            />
          </div>

          {data?.record?.incentive && data?.record?.incentive > 0 && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <InputBox
                title={tn("Bank name")}
                name="bank_name"
                form={form}
                type="text"
                width="calc(50% - 10px)"
                options={religion?.map((item: any) => ({
                  value: item.name,
                  label: item?.[locale],
                }))}
              />
              <InputBox
                title={tn("IBAN number")}
                name="iban_number"
                form={form}
                type="text"
                width="calc(50% - 10px)"
                options={religion?.map((item: any) => ({
                  value: item.name,
                  label: item?.[locale],
                }))}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <InputBox
              title={tn("National address")}
              name="national_address"
              form={form}
              type="text"
              width="calc(50% - 10px)"
              options={religion?.map((item: any) => ({
                value: item.name,
                label: item?.[locale],
              }))}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          margin: "20px 0px",
          width: "100%",
        }}
      >
        <Checkbox
          {...form.getInputProps("checkbox")}
          color="yellow"
          size={"md"}
          id="my-checkbox"
          label={
            <Text>
              اقر بأنني اطلعت على{" "}
              <Text
                onClick={() => {
                  // applicant:{
                  //   name:pb.authStore?.model?.first_name + " " + pb.authStore?.model?.last_name,
                  //   ID_number:form.values.ID_number,
                  //   religion:form.values.religion,
                  //   marital_status:form.values.marital_status,
                  //   date_of_birth: new Date(form.values.date_of_birth).toLocaleDateString(),
                  //   national_address:form.values.national_address,
                  //   bank_name:form.values.bank_name,
                  //   iban_number:form.values.iban_number,
                  //   email:pb.authStore?.model?.email,
                  //   mobile_number:pb.authStore?.model?.mobile,
                  //   gender:pb.authStore?.model?.gender
                  // }
                  setContractViewerData({
                    applicant: {
                      name:
                        pb.authStore?.model?.first_name +
                        " " +
                        pb.authStore?.model?.last_name,
                      ID_number: form.values.ID_number,
                      religion:fv(form.values.religion,"religion",translation)?.[locale],
                      marital_status:fv(form.values.marital_status,"marital_status",translation)?.[locale], 
                      date_of_birth: form.values.date_of_birth
                        ? new Date(
                            form.values.date_of_birth
                          ).toLocaleDateString()
                        : "",
                      national_address: form.values.national_address,
                      bank_name: form.values.bank_name,
                      iban_number: form.values.iban_number,
                      email: pb.authStore?.model?.email,
                      mobile_number: pb.authStore?.model?.mobile,
                      gender: fv(pb.authStore?.model?.gender,"gender",translation)?.[locale],
                      approved_date: new Date().toLocaleDateString(),
                    },
                    company: {
                      logo: `${CMS_ENDPOINT}/api/files/${data?.record?.collectionName}/${data?.record?.id}/${data?.record?.company_logo}?token=`,
                    },
                  });
                  openNewTab();
                }}
                component="a"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                target="_blank"
                color="yellow"
              >
                مسودة العقد
              </Text>{" "}
              ولا توجد لدي أي ملاحظات وأن كافة البيانات الأعلاه صحيحة وأتحمل
              كافة المسؤولية في حال ثبت عكس ذلك
            </Text>
          }
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px 0px",
          gap: "10px",
        }}
      >
        <Button
          disabled={!form.values.checkbox}
          style={{
            background: form.values.checkbox ? "#69DCBF" : "",
            width:"100%",
          }}
          type="submit"
          size="compact-xl"
            radius="md"
        >
          {tn("Upload and approve")}
        </Button>
        <Button
          style={{
            background: "#F9837C",
            width:"100%",
          }}
          radius="md"
          type="submit"
          size="compact-xl"
          onClick={() => {
            onClose();
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </form>
  );
};
const InputBox = ({ title, name, form, type, width, options }: any) => {
  switch (type) {
    case "text":
      return (
        <div
          style={{
            width: width,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "5px 0px",
          }}
        >
          <Text  mb="xs"
            style={{
              minWidth: "110px",
            }}
          >
            {title}
          </Text>
          <TextInput
            style={{
              width: "100%",
              margin: "0px 10px",
            }}
            required
            {...form.getInputProps(name)}
            styles={TextInputStyle(form.errors[name])}
          />
        </div>
      );
    case "select":
      return (
        <div
          style={{
            width: width,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "5px 0px",
          }}
        >
          <Text  mb="xs"
            style={{
              minWidth: "110px",
            }}
          >
            {title}
          </Text>
          <Select
            style={{
              width: "100%",
              margin: "0px 10px",
            }}
            data={options}
            required
            {...form.getInputProps(name)}
            styles={TextInputStyle(form.errors[name])}
          />
        </div>
      );
    case "date":
      return (
        <div
          style={{
            width: width,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: "5px 0px",
          }}
        >
          <Text
          mb="xs"
            style={{
              minWidth: "110px",
            }}
          >
            {title}
          </Text>
          <DateInput
            style={{
              width: "100%",
              margin: "0px 10px",
            }}
            required
            {...form.getInputProps(name)}
            styles={TextInputStyle(form.errors[name])}
          />
        </div>
      );
    default:
      return <div>Invalid type</div>;
  }
};

const TextInputStyle = (error: any) => ({
  input: {
    borderRadius: "13px",
    border: "1px solid #000000",
    height: "45px",
  },
  error: {
    marginBottom: "-17px",
    transform: "translate(4px, 3px)",
    // fontSize: "8px",
  },
});

// const TextBox = ({ value, name }: any) => {
//   return (
//     <div
//       style={{
//         width: "100%",
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//         justifyContent: "space-between",
//         margin: "3px 0px",
//         padding: "0px 10px",
//       }}
//     >
//       <Text
//         style={{
//           padding: "10px 3px 10px ",
//           color: "#4F5152",
//           textAlign: "right",
//           fontSize: "14px",
//           fontWeight: "700",
//         }}
//       >
//         {name}
//       </Text>
//       <Text
//         style={{
//           minHeight: "34.8px",
//           width: "180px",
//           color: "#4F5152",
//           padding: "5px 10px",
//           borderRadius: "5px",
//           background: "#ffffff",
//           fontSize: value?.length > 23 ? "13px" : "16px",
//           textAlign: "center",
//           fontWeight: "400",
//         }}
//       >
//         {value}
//       </Text>
//     </div>
//   );
// };
