import { createContext, useState } from "react";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../constants";
import { Loader } from "@mantine/core";

export const TranslationContext = createContext(null);

function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // handle the error
  }
}

function getLocalStorage(key, initialValue) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
}

const TranslationContextProvider = ({ children }) => {
  const pb = new PocketBase(CMS_ENDPOINT);

  const [locale, setLocale] = useState(() => getLocalStorage("locale", "ar"));
  const [translation, setTranslation] = useState(() => getLocalStorage("translation", {}));
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  // Function to fetch translation data
  const fetchTranslationData = async () => {
    try {
      const record = await pb.collection('airtable').getOne('ozlep0yil3x3crb');
      setLocalStorage("translation", record?.data_json);
      setTranslation(record?.data_json);
    } catch (error) {
      console.error("Failed to fetch translation data:", error);
    } finally {
      setIsLoading(false);
      setIsFetched(true);
    }
  };

  // Check if translation data is already loaded, if not, fetch it
  if (!isLoading && !isFetched) {
    setIsLoading(true);

    fetchTranslationData();

  }else{
  }

  // Save locale to local storage whenever it changes
  const handleLocaleChange = (newLocale) => {
    setLocalStorage("locale", newLocale);
    setLocale(newLocale);
  };

  if (isLoading ) {
    return (
      <div style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Loader color="#5708a6"/>
      </div>
    );
  }

  return (
    <TranslationContext.Provider value={{
      translation,
      setTranslation,
      locale,
      setLocale: handleLocaleChange
    }}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationContextProvider;
