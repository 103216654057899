import {
  Button,
  Center,
  Divider,
  Header,
  Loader,
  Modal,
  Radio,
  rem,
  RingProgress,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pb } from "../../../config";
import { use } from "echarts";
import { CMS_ENDPOINT } from "../../../constants";
import { ltn, tn } from "../../../translation";
import { IconSquareRoundedCheck } from "@tabler/icons-react";
import { BannersContext } from "../../../context/BannersContext";
import { TranslationContext } from "../../../context/TranslationContext";
import { useMediaQuery } from "@mantine/hooks";

const HEADER_HEIGHT = rem(84);

export default function TestPage() {
  const navigate = useNavigate();
  const [timePassed, setTimePassed] = useState(0);
  const { setTopBanner }: any = useContext(BannersContext);
  const { locale }: any = useContext(TranslationContext);
  const [submitModal, setSubmitModal] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const { filtration_test_id, program_id } = useParams();
  const [loading, setLoading] = useState(true);
  const form: any = useForm({
    initialValues: {
      questions: [],
      answers: [],
      company: {},
      program: "",
      answers_record_id: "",
      percentage: 0,
      minimum_to_pass: 0,
      isFinished: false,
      duration: 0,
      started_at: null,
    },
  });
  const isMobile = useMediaQuery("(max-width: 550px)");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const record = await pb
          .collection("filtration_tests_view")
          .getOne(filtration_test_id, { expand: "company" });
        if (record) {
          const program_record = await pb
            .collection("applicant_view_programs")
            .getFullList({
              sort: "-created",
              filter: `id = '${program_id}'`,
            });
          const answers_record = await pb.collection("answers").getFullList({
            sort: "-created",
            filter: `filtration_test = '${filtration_test_id}' && program = '${program_id}'`,
          });
          console.log("answers_erecord", answers_record);
          if (answers_record?.length > 0) {
            form.setValues({
              answers: answers_record[0].answers,
              questions: record.questions,
              company: record.expand.company,
              answers_record_id: answers_record[0].id,
              duration: record.duration,
              started_at: answers_record[0]?.started_at,
              minimum_to_pass: record.minimum_to_pass,
              program: program_record?.[0],
            });
            if (answers_record[0].status === "submitted") {
              form.setFieldValue("isFinished", true);
            }
          } else {
            console.log("answers_erecord", answers_record);
            const newAnswersRecord = await pb.collection("answers").create({
              started_at: new Date(),
              filtration_test: filtration_test_id,
              applicant: pb.authStore.model.id,
              status: "pending",
              program: program_id,
              answers: record.questions.map((item: any) => ({
                question_id: item.id,
                question: item.question,
                answer_id: null,
                answer: null,
              })),
            });
           
            form.setValues({
              answers_record_id: newAnswersRecord.id,
              answers: newAnswersRecord.answers,
              questions: record.questions,
              company: record.expand.company,
              program: program_record?.[0],
              duration: record.duration,
              started_at: newAnswersRecord?.started_at,
              minimum_to_pass: record.minimum_to_pass,
            });
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    }
    fetchData();
  }, [filtration_test_id]);
  useEffect(() => {
    const saveAnswersAndUpdatePercentage = async () => {
      if (form.values.answers_record_id) {
        try {
          await pb.collection("answers").update(form.values.answers_record_id, {
            answers: form.values.answers,
          });

          // Calculate percentage out of 100
          const totalPoints = form.values.questions.length; // Assuming each question is worth one point
          const earnedPoints = form.values.answers.reduce(
            (acc: any, answer: any) => {
              return (
                acc +
                (answer.answer_id ==
                form.values.questions
                  ?.find((question: any) => question.id === answer.question_id)
                  ?.answers?.find((answer_: any) => answer_.correct === true)
                  ?.id
                  ? 1
                  : 0)
              ); // Assuming answer_id is filled correctly for correct answers
            },
            0
          );
          const percentage_ = (earnedPoints / totalPoints) * 100; // Normalize to 100-point scale
          setPercentage(percentage_);
        } catch (error) {
          console.error("Error updating answers:", error);
        }
      }
    };
    saveAnswersAndUpdatePercentage();
  }, [form.values.answers]);

  useEffect(() => {
    if (
      !form.values.isFinished &&
      form.values.started_at &&
      form.values.duration
    ) {
      const interval = setInterval(() => {
        const startTime = new Date(form.values.started_at).getTime();
        const currentTime = new Date().getTime();
        const durationMs = form.values.duration * 60000;
        const timeRemaining = durationMs - (currentTime - startTime);
        setTimePassed(timeRemaining > 0 ? timeRemaining : 0); // Ensuring time passed doesn't go negative
        if (timeRemaining <= 0) {
          clearInterval(interval);
          onTestCompletion(); // Call completion handler when time runs out
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [form.values.started_at, form.values.duration, form.values.isFinished]);

  const onTestCompletion = async () => {
    if (form.values.program) {
      const answers_record = await pb
        .collection("answers")
        .update(form.values.answers_record_id, {
          status: "submitted",
          percentage: percentage,
          isSuccess: percentage >= form.values.minimum_to_pass,
        });

      if (answers_record) {
        if (percentage >= form.values.minimum_to_pass) {
          if (
            form?.values?.program?.application_link &&
            form?.values?.program?.partial_status
          ) {
            openApplicationLink(form?.values?.program?.application_link);
          } else {
            await createApplicationRecord();
          }
        }

        form.setFieldValue("isFinished", true);
      }
    }
  };
  const openApplicationLink = (link: any) => {
    window.open(link, "_blank", "noreferrer");
  };
  const createApplicationRecord = async () => {
    if (!form?.values?.program?.id || !pb.authStore?.model?.id) {
      console.error("Missing required data for creating application record.");
      return;
    }

    const data = {
      program: form?.values?.program.id,
      applicant: pb.authStore?.model.id,
      applicant_status: "under_review",
      recruiter_status: "under_review",
      main_status: "new",
      date_of_commencement: new Date(form?.values?.program.start_date),
      date_of_end: new Date(form?.values?.program.end_date),
    };

    const record = await pb.collection("applications").create(data);

    if (form?.values?.program?.status === "new") {
      await updateProgramStatus(form?.values?.program.id);
    }

    displaySuccessBanner();
  };

  const updateProgramStatus = async (programId: any) => {
    await pb.collection("programs").update(programId, {
      status: "under_the_procedure",
    });
  };

  const displaySuccessBanner = () => {
    const message =
      locale === "ar"
        ? `تم التقديم بنجاح على برنامج ${form?.values?.company?.name} على ${form?.values?.program?.name}، قم بمتابعة حالة الطلب من خلال صفحة طلباتي`
        : `You have successfully applied for the ${form?.values?.program?.name} program at ${form?.values?.company?.name}. You can follow up on your application status through the My Applications page`;

    setTopBanner({
      duration: 10000,
      message: message,
      color: "#2DC071",
      top: "83px",
      backgroundColor: "#F2FFF7EB",
      icon: <IconSquareRoundedCheck />,
    });
  };

  const progressPercentage = Math.min(
    100,
    (timePassed / (form.values.duration * 60000)) * 100
  );
  const hours = Math.floor(timePassed / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((timePassed % 3600000) / 60000);
  const seconds = Math.floor((timePassed % 60000) / 1000);
  const isSuccess = percentage >= form?.values?.minimum_to_pass;
  const logo = form?.values?.company?.logo
    ? `${CMS_ENDPOINT}/api/files/${form?.values?.company?.collectionName}/${form?.values?.company?.id}/${form?.values?.company?.logo}?token=`
    : "/dark_text_logo.svg";
  if (loading || !form?.values?.minimum_to_pass) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Loader />
      </div>
    );
  }
  if (!form.values.program) {
    return <Text>not allowed</Text>;
  }

  return (
    <div
      style={{
        backgroundColor: "#f7f7f7",
        height: "100vh",
      }}
    >
      {!isMobile ? (
        <Header
          style={{
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            zIndex: 1001,
            background:
              "linear-gradient(277deg, rgba(105,220,191,1) 0%, rgba(33,3,63,1) 65%)",
            boxShadow: "0px 2px 0px 0px #DCDCDC",
          }}
          height={HEADER_HEIGHT}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Text style={{ marginLeft: "10px", color: "#FFF" }}>
                {!form.values.isFinished ? tn("the remaining time") : ""}
              </Text>
              <Text style={{ marginLeft: "10px", color: "#FFB004" }}>
                {!form.values.isFinished
                  ? `${hours}:${minutes}:${seconds.toString().padStart(2, "0")}`
                  : tn("The test is over")}
              </Text>
            </div>
          </div>
          <RingProgress
            size={60}
            rootColor="#351e4c"
            roundCaps
            thickness={8}
            sections={[{ value: 100 - progressPercentage, color: "#69DCBF" }]}
          />
        </Header>
      ) : (
        <div
          style={{
            position: "fixed",
            margin: "10px",
            width: "calc(100vw - 20px)",
            display: "flex",
            alignItems: "center",
            borderRadius: "15px",
            boxShadow: "1px 3px 30px 0px rgba(0, 0, 0, 0.13)",
            height: "120px",
            background: "white",
            zIndex: 1001,
          }}
        >
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            position: "absolute",
            top: "0",
            left: "10px",
            width: "calc(100% - 20px)",
            height: "10px",
            transform: "translateY(-50%)",
            borderRadius: "522px",
            background: "#d0d0d0",
          }}>
             <div style={{
            width: `
              calc(
                ${!form.values.isFinished ? progressPercentage : 100}% - 40px
              )
            `,
            height: "10px",
            borderRadius: "522px",
            background: "#FFB004",
          }}>
            
          </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              gap: "10px",
              textAlign: "center",
            }}
          >
            <Text style={{ marginLeft: "10px", color: "#000" }}>
              {tn("program title")}
            </Text>
            <Text style={{ marginLeft: "10px", color: "#FFB004" }}>
              {form?.values?.program?.name}
            </Text>
            <Text style={{ marginLeft: "10px", color: "#FFB004" }}>
             {tn("the remaining time")} {`${hours}:${minutes}:${seconds.toString().padStart(2, "0")}`}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              gap: "20px",
              padding: "20px 0px",
            }}
          >
            <img
              src={logo}
              alt="Company Logo"
              style={{
                width: "100%",
                maxWidth: "calc(100% - 20px)",
                maxHeight: "60px"
              }}
            />
            <Text style={{ marginLeft: "10px", color: "#4a4a4a" }}>
              {form?.values?.company?.name}
            </Text>
          </div>
        </div>
      )}

      <div
        style={{
          width: "100vw",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          height: "calc(100vh - 84px)",
          paddingTop: isMobile ? "130px" : "",
        }}
      >
        {!isMobile && (
          <div
            style={{
              backgroundColor: "white",
              width: "300px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "calc(100% - 20px)",
                display: "flex",
                height: "8px",
                borderRadius: "522px",
                background: "#ededed",
              }}
            >
              <div
                style={{
                  width: `${
                    !form.values.isFinished ? progressPercentage : 100
                  }%`,
                  background: "#FFB004",
                  height: "8px",
                  borderRadius: "522px",
                }}
              />
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              padding: "20px",
            }}>
            <img
              src={logo}
              alt="Company Logo"
              style={{  width: "100%", maxWidth: "calc(100% - 20px)", }}
            />
                {form.values.company?.name }
            </div>
           
            <div />
          </div>
        )}
        <div
          style={{
            width: !isMobile ? "calc(100vw - 300px)" : "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            overflowY: "scroll",
            height: "100%",
          }}
        >
          {form?.values?.isFinished ? (
            isSuccess ? (
              <div
                style={{
                  width: "calc(100% - 20px)",
                  maxWidth: "400px",
                  borderRadius: "15px",
                  padding: "20px",
                  marginTop: "20px",
                  gap: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  backgroundColor: "#F8FDFC",
                  boxShadow: "1px 3px 30px 0px rgba(0, 0, 0, 0.13)",
                  textAlign: "center",
                }}
              >
                {checkIcon}
                <Text
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#69DCBF",
                  }}
                >
                  {tn("Your application has been sent successfully")}
                </Text>
                <Text
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#69DCBF",
                  }}
                >
                  تم التقديم بنجاح على برنامج {form?.values?.company?.name} على{" "}
                  {form?.values?.program?.name}، قم بمتابعة حالة الطلب من خلال
                  صفحة طلباتي
                </Text>
                <Button
                  compact
                  style={{
                    backgroundColor: "#69DCBF",
                    color: "white",
                    borderRadius: "5.112px",
                  }}
                  onClick={() => {
                    navigate("/applicant/sent_requests");
                  }}
                >
                  {tn("Return to the home page")}
                </Button>
              </div>
            ) : (
              <div
                style={{
                  width: "calc(100% - 20px)",
                  maxWidth: "600px",
                  borderRadius: "15px",
                  padding: "20px",
                  marginTop: "20px",
                  gap: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  backgroundColor: "#fdf8f8",
                  boxShadow: "1px 3px 30px 0px rgba(0, 0, 0, 0.13)",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#585858",
                  }}
                >
                  {tn("Sorry!")}
                </Text>
                <Text
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#585858",
                  }}
                >
                  {ltn("faild_test")}
                </Text>
                <Button
                  compact
                  style={{
                    backgroundColor: "#dc6969",
                    color: "white",
                    borderRadius: "5.112px",
                  }}
                  onClick={() => {
                    navigate("/applicant/programs_search");
                  }}
                >
                  {tn("Return to the home page")}
                </Button>
              </div>
            )
          ) : (
            <div
              style={{
                width: "100%",
                maxWidth: "600px",
                borderRadius: "15px",
                padding: "20px",
                marginTop: "20px",
                gap: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                overflowY: "scroll",
                maxHeight: "calc(100vh - 110px)",
              }}
            >
              {form.values.questions.map((item: any, i: any) => (
                <QuestionBuilder
                  key={item.id}
                  i={i}
                  question={item.question}
                  question_id={item.id}
                  answers={item.answers}
                  form={form}
                />
              ))}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isMobile ? "center" : "end",
                  padding: "0px 0px",
                }}
              >
                <Button
                  compact={!isMobile}
                  style={{
                    backgroundColor: "#69DCBF",
                    color: "white",
                    width: "100px",
                    borderRadius: "5.112px",
                  }}
                  onClick={() => {
                    setSubmitModal(true);
                  }}
                >
                  {tn("Submit")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        size={"md"}
        style={{ zIndex: 10000, position: "relative" }}
        overlayProps={{
          zIndex: 2000,
          opacity: 0.55,
          blur: 3,
        }}
        styles={() => ({
          content: {
            zIndex: 10001,
            borderRadius: "10px",
            background: "linear-gradient(153deg, #69DCBF 100%, #21033f7a 100%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          },
          header: { display: "none" },
          body: { padding: "9px" },
          inner: { zIndex: 10001 },
        })}
        opened={submitModal}
        onClose={() => {
          setSubmitModal(false);
        }}
        centered
      >
        <div
          style={{
            background: "white",
            width: "100%",
            height: "100%",
            marginTop: "9px",
            borderRadius: "5px",
            // paddingTop: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              padding: "20px",
              width: "100%",
            }}
          >
            <Text fw={600} color="#5c5c5c">
              {ltn("test_submit")}
            </Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "20px",
                gap: "10px",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#69DCBF",
                  color: "white",
                  borderRadius: "5.112px",
                }}
                onClick={() => {
                  setSubmitModal(false);
                  onTestCompletion();
                }}
              >
                {tn("Yes")}
              </Button>
              <Button
                color="gray"
                variant="outline"
                style={{
                  borderRadius: "5.112px",
                }}
                onClick={() => {
                  setSubmitModal(false);
                }}
              >
                {tn("Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const QuestionBuilder = ({ question, question_id, answers, form, i }: any) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "15px",
      }}
    >
      <Text style={{ fontSize: "18px", fontWeight: "700", color: "#4a4a4a" }}>
        {question}
      </Text>
      <Radio.Group value={form.values.answers[i]?.answer_id}>
        <div style={{ width: "100%" }}>
          {answers.map((item: any) => (
            <div
              key={item.id}
              style={{
                width: "100%",
                padding: "10px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
              onClick={() => {
                console.log("formfdormform", form?.values);

                form.setFieldValue(`answers.${i}.answer_id`, item.id);
                form.setFieldValue(`answers.${i}.answer`, item.answer);
              }}
            >
              <Radio
                style={{ width: "100%" }}
                color="color3"
                size="xs"
                value={item.id}
                label={item.answer}
              />
            </div>
          ))}
        </div>
      </Radio.Group>
    </div>
  );
};

const checkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="122"
    height="122"
    viewBox="0 0 122 122"
    fill="none"
  >
    <path
      d="M52.0686 82.9296L30.5 61.3559L37.6878 54.1681L52.0686 68.5438L80.8199 39.7874L88.0128 46.9803L52.0686 82.9296Z"
      fill="#69DCBF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.08301 61C5.08301 30.1187 30.1184 5.08331 60.9997 5.08331C91.8809 5.08331 116.916 30.1187 116.916 61C116.916 91.8812 91.8809 116.917 60.9997 116.917C30.1184 116.917 5.08301 91.8812 5.08301 61ZM60.9997 106.75C54.9917 106.75 49.0426 105.567 43.4919 103.267C37.9413 100.968 32.8978 97.5984 28.6495 93.3501C24.4013 89.1018 21.0313 84.0584 18.7322 78.5078C16.433 72.9571 15.2497 67.008 15.2497 61C15.2497 54.992 16.433 49.0429 18.7322 43.4922C21.0313 37.9416 24.4013 32.8981 28.6495 28.6498C32.8978 24.4016 37.9413 21.0316 43.4919 18.7325C49.0426 16.4333 54.9917 15.25 60.9997 15.25C73.1333 15.25 84.77 20.0701 93.3498 28.6498C101.93 37.2296 106.75 48.8663 106.75 61C106.75 73.1336 101.93 84.7703 93.3498 93.3501C84.77 101.93 73.1333 106.75 60.9997 106.75Z"
      fill="#69DCBF"
    />
  </svg>
);
