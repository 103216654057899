import {  useContext, useEffect, useState } from "react";
import { CMS_ENDPOINT } from "../../constants";
import { ltn, tn,  } from "../../translation";
import { Button, Loader, Modal, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import {useViewportSize } from "@mantine/hooks";
import { ApplicantView } from "./AdminApplicantView";
import { ApplicantEdit } from "./AdminApplicantEdit";
import { DataContext } from "../../context/DataContext";


export function ApplicantModal({data, onClose}:any) {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { height, width }: any = useViewportSize();
  const [editing, setEditing] = useState(false);
  const [warnning, setWarnning]: any = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
//     pb.collection("applicants").subscribe(data.id, (e) => {
//       setUserData(e.record);
// });
    const record = pb.collection("applicants").getOne(data.id,{}).then(
      (e:any)=>{
        setUserData(e)

      }
    );
    // return () => {
    //   pb.collection('applicants').unsubscribe()
    // };
  }, [])
  return width > 10 ? (
    <>
      {" "}
      <div
        style={{
          position: "relative",
          width: "100%",
          maxHeight: "100%",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          padding: "20px",
          paddingTop: "30px",
          background: "#F6F5F7",
          borderRadius: "10px",
          // overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "10px 30px",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "1180px",
          }}
        >
          <Text
            style={{
              "color": "rgba(0, 0, 0, 0.70)",
              "textAlign": "right",
              "fontFamily": "IBM Plex Sans Arabic",
              "fontSize": "18px",
              "fontStyle": "normal",
              "fontWeight": "700",
              "lineHeight": "normal",
              marginBottom:width >= 420 ?"0px":"60px",
            }}
          >
            {tn("User data")}
          </Text>
        </div>
       {userData?.first_name ? <div
          style={{
            height: "100%",
            display: "flex",

            justifyContent: "center",
            width: "100%",
          }}
        >
          {editing ? (
            <ApplicantEdit
              setWarnning={setWarnning}
              setEditing={setEditing}
              data={userData}
            />
          ) : (
            <ApplicantView
              setEditing={setEditing}
              data={userData}
            />
          )}
        </div>:<Loader/>}
        <Modal
          style={{ zIndex: 10000 }}
          overlayProps={{
            zIndex: 10000,
            opacity: 0.55,
            blur: 3,
          }}
          styles={() => ({
            content: {
              zIndex: 10001,
              borderRadius: "10px",
              background:
                "linear-gradient(153deg, #22c0c372 100%, #21033f7a 100%)",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            },
            header: { display: "none" },
            body: { padding: "9px" },
            inner: { zIndex: 10000 },
          })}
          opened={warnning}
          onClose={() => {
            setWarnning(false);
          }}
          centered
        >
          <div
            style={{
              background: "white",
              width: "100%",
              height: "100%",
              marginTop: "9px",
              borderRadius: "5px",
              paddingTop: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Text fw="600" color="grey">
                {ltn("discard_massage")}
              </Text>
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Button
                my="md"
                style={{ backgroundColor: "#ff5e5e" }}
                onClick={() => {

                  setWarnning(false);
                  setEditing(false);
                }}
              >
                {tn("Yes")}
              </Button>
              <Button
                my="md"
                mx="md"
                variant="outline"
                color="gray"
                onClick={() => {
                  setWarnning(false);
                }}
              >
                {tn("Cancel")}
              </Button>{" "}
            </div>
          </div>
        </Modal>
      </div>
    </>
  ) : (
    <></>
  );
}
