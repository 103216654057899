import { AcceptWaitingInterview } from "./selectable_actions/AcceptWaitingInterview";
import { AcceptNewApplicant } from "./selectable_actions/AcceptNewApplicant";
import { ActivateAccounts } from "./selectable_actions/ActivateAccounts";
import { ActivateCompany } from "./selectable_actions/ActivateCompany";
import { DeactivateAccounts } from "./selectable_actions/DeactivateAccounts";
import { DeleteAccounts } from "./selectable_actions/DeleteAccounts";
import { DeleteCompany } from "./selectable_actions/DeleteCompany";
import { DeleteDepartment } from "./selectable_actions/DeleteDepartment";
import { ExcludeInterview } from "./selectable_actions/ExcludeInterview";
import { RejectNewApplicant } from "./selectable_actions/RejectNewApplicant";
import { SoftDeleteAccounts } from "./selectable_actions/SoftDeleteAccounts";
import { SchedulingInterview } from "./selectable_actions/SchedulingInterview";
import { AcceptScheduledInterview } from "./selectable_actions/AcceptScheduledInterview";
import { CancelInterview } from "./selectable_actions/CancelInterview";
import { Reschedule } from "./selectable_actions/Reschedule";
import { DidNotAttend } from "./selectable_actions/DidNotAttend";
import { SchedulePlan } from "./selectable_actions/SchedulePlan";
import { TerminationProgramWithoutDocument } from "./selectable_actions/TerminationProgramWithoutDocument";
import { DeletePermission } from "./selectable_actions/DeletePermission";
import { AcceptWithdraw } from "./selectable_actions/AcceptWithdraw";
import { RejectWithdraw } from "./selectable_actions/RejectWithdraw";

export const SelectableActions = (props:any) => {
  const { action, table, selectedRecords } = props;
  switch (action?.name) {
    case "delete_account":
      return <DeleteAccounts {...props} />;
    case "soft_delete_account":
      return <SoftDeleteAccounts {...props} />;
    case "account_activation":
      return <ActivateAccounts {...props} />;
    case "account_deactivation":
      return <DeactivateAccounts {...props} />;
    case "activate_company":
      return <ActivateCompany {...props} />;
    case "delete_company":
      return <DeleteCompany {...props} />;
    case "delete_department":
      return <DeleteDepartment {...props} />;
    case "accept_new_applicant":
      return <AcceptNewApplicant {...props} />;
    case "reject_new_applicant":
      return <RejectNewApplicant {...props} />;
    case "accept_waiting_interview":
      return <AcceptWaitingInterview {...props} />;
    case "exclude_interview":
      return <ExcludeInterview {...props} />;
    case "scheduling_interview":
      return <SchedulingInterview {...props} />;
    case "accept_scheduled_interview":
      return <AcceptScheduledInterview {...props} />;
    case "cancel_interview":
      return <CancelInterview {...props} />;
    case "reschedule":
      return <Reschedule {...props} />;
    case "did_not_attend":
      return <DidNotAttend {...props} />;
    case "termination_program_without_document":
      return <TerminationProgramWithoutDocument {...props} />;
    case "delete_permission":
      return <DeletePermission {...props} />;
    case "accept_withdraw":
      return <AcceptWithdraw {...props} />;
    case "reject_withdraw":
      return <RejectWithdraw {...props} />;

    default:
      <></>;
  }
};
