import { useContext } from "react";
import { DataContext } from "../../../../../../../context/DataContext";
import { ActionComponent } from "../../../../ActionComponent";

export const ExcludeContract = (props: any) => {
  const { setExcludeDocument }: any = useContext(DataContext);
  
  const { data, action , table } = props;

  const when_be_able = true
  return (
    <ActionComponent
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setExcludeDocument({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
    }}
    enabled={when_be_able}
  />
  );
};
