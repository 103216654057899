import { Button, Select, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useContext, useState } from "react";
import { fv, vf } from "../../../../../Functions";
import { AppContext } from "../../../../../../context/AppContext";
import { ltn, tn } from "../../../../../../translation";
import { TranslationContext } from "../../../../../../context/TranslationContext";

export function LocationsDetails({ data, onClose }) {
  const { locale, translation }: any = useContext(TranslationContext);
  const data_path = data?.record;
  
  return (
    <form
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
      }}
    >
      <Text
        style={{
          width: "100%",
          color: "#434343",
          fontSize: "15px",
          fontWeight: "bold",
          margin: "10px 10px 0px 10px",
        }}
      >
        {ltn("location_details")}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
        <TextField
          label={tn("City")}
          value={fv(data_path?.city,"city",translation)?.[locale]}
        />

        <TextField
          label={tn("Location")}
            value={data_path?.location}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
        <TextField
          label={tn("Building number")}
            value={data_path?.building_number}
        />
        <TextField
          label={tn("The floor")}
            value={data_path?.floor}
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          padding: "0px 10px",
        }}
      >
        <TextField  
          label={tn("Office No")}
            value={data_path?.office_no}
        />
        <div style={{ width: "100%" }} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 10px",
          gap: "10px",
        }}
      >
        <Button
          onClick={() => {
            onClose(false);
          }}
          style={{
            background: "#6f6f6f",
          }}
          compact
          px="xl"
        >
          {tn("close")}
        </Button>
      </div>
    </form>
  );
}

const TextField = ({
  label,
  marginTop,
  padding,
  value
}: any) => (
  <div
    style={{
      width: "100%",
      padding: padding ? padding : "0px 0px",
      marginTop: marginTop ? marginTop : "10px",
    }}
  >
     <Text style={{
                   margin: "5px 5px",
                   borderRadius: "5px",
                   padding: "0px 5px",
                   fontSize: "15px",
                     width: "100%",
                     color: "#434343",
            }}>
                {label}
            </Text>
    <Text style={{
         borderRadius: "5px",
         border: "1px solid #000000",
         height: "30px",
         width: "100%",
         display: "flex",
            alignItems: "center",
            justifyContent: "start",
            padding: "0px 5px",
    }}>
            {value}
    </Text>
  </div>
);
const TextInputStyle = (error: any) => ({
  label: {
    zIndex: "10",
    position: "relative",
    margin: "0px 10px",
    transform: "translate(0px, 7px)",
    background: "#f6f6f6",
    borderRadius: "5px",
    padding: "0px 5px",
    fontSize: "10px",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #000000",
  },
  error: {
    marginBottom: "-14px",
    transform: "translate(4px, 13px)",
    // fontSize: "8px",
  },
});