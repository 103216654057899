import { Avatar, Button, Text } from "@mantine/core";
import { tn } from "../../translation";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../context/TranslationContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { MainDataApplicant } from "./MainDataApplicant";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function ApplicantView({ setEditing, data }: any) {
  const { locale, translation }: any = useContext(TranslationContext);
  const { reloader, setReloader }: any = useContext(AppContext);

  const { height, width }: any = useViewportSize();
  const [userData, setUserData] = useState(data);
  // useEffect(() => {
  //   pb.collection(data?.collectionName).subscribe(data.id, (e) => {
  //     setUserData(e.record);
  //   });
  //   return () => {
  //     pb.collection(data?.collectionName).unsubscribe()
  //   };
  // }, []);

  return width > 10 ? (
    <div
      style={{
        width: "100%",
        //   height: "100%",
        marginBottom: "20px",
        display: "flex",
        justifyContent: width < 1400 ? "start" : "center",
        alignItems: "center",
        flexDirection: width > 1400 ? "row" : "column",
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          background: "#FFF",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
          padding: "10px",
          margin: "0px 10px",
          zIndex: 999,
          position: "absolute",
          top: width >= 420 ? "20px" : "75px",
          transform: width > 1400 ? "translateX(-530px)" : "translateX(0px)",
          left: width > 1400 ? "" : "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          mx="sm"
          // radius was 0 here
          style={{ background: "#69DCBF", height: "25px" }}
          onClick={() => {
            setEditing(true);
          }}
        >
          {tn("Edit data")}
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: width > 600 ? "row" : "column",
          width: width > 1400 ? "540px" : "100%",
          maxWidth: "700px",
          marginBottom: width > 1400 ? "0px" : "10px",
          justifyContent: "space-between",
          // height: "100%",
        }}
      >
        <MainDataApplicant data={userData} />
        <div
          style={{
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
            padding: "20px",
            width: width > 1400 ? "270px" : width > 600 ? "49%" : "100%",
            height: width > 600 ? "370px" : "270px",
            margin:
              width > 1400 ? "0px 10px" : width > 600 ? "" : "10px 0px 0px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Text fz="xl" fw="700" color="dimmed" pb={28} pt={10}>
            {tn("Overview of yourself")}
          </Text>
          <Text fw="500" color="grey" pb={28} pt={10}>
            {userData?.about ? userData?.about : tn("No Data")}
          </Text>
        </div>
      </div>
      <div
        style={{
          borderRadius: "10px",
          background: "#FFF",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
          padding: "30px 10px",
          width: "100%",
          maxWidth: "700px",
          // margin: width > 1000 ? "0px 20px" : "20px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Text fz="xl" fw="700" color="dimmed" pb={30}>
          {tn("Personal information")}
        </Text>
        <div
          style={{
            width: "100%",
            maxWidth: "700px",
            // margin: width > 1000 ? "0px 20px" : "20px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: width > 650 ? "row" : "column",
          }}
        >
          <div
            style={{
              width: "100%",
              margin: "0px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("First Name")}
              </Text>
              <BoxStyleF text={userData?.first_name} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Mobile")}
              </Text>
              <BoxStyleF text={userData?.mobile} />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Gender")}
              </Text>
              <BoxStyleF text={userData?.gender?.[locale]} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("University")}
              </Text>
              <BoxStyleF text={userData?.university?.[locale]} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("GPA")}
              </Text>

              <BoxStyleF text={userData?.GPA} />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              margin: "0px 10px",
              marginTop: width > 650 ? "0px" : "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Last Name")}
              </Text>
              <BoxStyleF text={userData?.last_name} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Email")}
              </Text>
              {/* <div style={boxStyle}>{userData?.email}</div> */}
              <BoxStyleF text={userData?.email} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Case Study")}
              </Text>
              <BoxStyleF text={userData?.case_study?.[locale]} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("Major")}
              </Text>
              <BoxStyleF text={userData?.major?.[locale]} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Text color="#676767" size="xs" fw="700">
                {tn("CV")}
              </Text>
              {userData?.cv ? (
                <a
                  style={{
                    textDecoration: "none",
                    color: "#676767",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                  }}
                  href={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.cv}?token=`}
                  download={userData?.cv}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                  fz="xs"
                    style={{
                      width: "100%",
                       maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      direction: "ltr",
                    }}
                  >
                    {userData?.cv}
                  </Text>
                </a>
              ) : (
                <Text color="#676767" size="xs" fw="700">
                  {tn("No Data")}
                </Text>
              )}
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

const BoxStyleF: any = ({ text }: any) => {
  const boxStyle: any = {
    height: "1.875rem",
    boxSizing: "border-box",
    fontSize: text?.length > 15 ? "50%" : "14px",
    minWidth: "50%",
    maxWidth: "50%",
    color: "#727272",
    border: "0.0625rem solid #ced4da",
    backgroundColor: "#fff",
    paddingRight: "calc(2.25rem / 3)",
    paddingLeft: "calc(2.25rem / 3)",
    borderRadius: "0.25rem",
    display: "flex",
    alignItems: "center",
    // justifyContent: "end",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // wordWrap: "break-word",
    // maxHeight: "3.6em",
    // lineHeight: "1.8em",
  };
  return <div style={boxStyle}>{text}</div>;
};
