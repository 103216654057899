import { Helmet } from "react-helmet";
import { DataGrid } from "../../main_components/data-grid/DataGrid";
import { tn, ttn } from "../../translation";

export const NewApplications = () => {
  return (
    <>
      <Helmet>
      <title>{ttn("new_applications") + " / " + tn("Coophub")}</title>
      </Helmet>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "20px",
          paddingTop: "30px",
          background: "#fcfcfc",
        }}
      >
        <DataGrid name="new_applications" />
      </div>
    </>
  );
};
