import { Text } from "@mantine/core";
import { tn } from "../../../translation";
import { useNavigate } from "react-router-dom";

export const TopShapeAuth = ({ title }: any) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "calc(100% - 40px)",
        height: "320px",
        minHeight: "320px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "0px 00px 10000px 10000px",
        backgroundColor: "#21033F",
        padding: "0px 50px",
      }}
    >
      <Text
        style={{
          color: "#69DCBF",
          fontSize: "25px",
          fontStyle: "normal",
          fontWeight: "700",
          width: "100%",
        }}
      >
        {tn("you are welcome in")}
      </Text>
      <img
        style={{ height: "93px", cursor: "pointer" }}
        src="/white_text_logo.png"
        onClick={() => {
          navigate("/applicant/login");
        }}
      />
      <Text
        style={{
          color: "#69DCBF",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        {" "}
        {title ?? tn("We build bridges, we create opportunities!")}
      </Text>
    </div>
  );
};
