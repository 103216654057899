import { useContext, useEffect, useState } from "react";
import { BannersContext } from "../../context/BannersContext";
import { Dialog } from "@mantine/core";
import { useDebouncedValue, useTimeout } from "@mantine/hooks";
import { Icon } from "@iconify/react/dist/iconify.js";
import { IconCheck } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { TranslationContext } from "../../context/TranslationContext";

export function BannersBuilder() {
  const { topBanner, setTopBanner, dialogBanner, setDialogBanner }: any =
    useContext(BannersContext);

  const [dialogBannerDebounced] = useDebouncedValue(dialogBanner, 200);
  const [topBannerDebounced] = useDebouncedValue(topBanner, 200);

  const { start, clear } = useTimeout(() => {
    setDialogBanner({});
    setTopBanner({});

    // clear
  }, topBanner?.duration?topBanner?.duration:dialogBanner?.duration?dialogBanner?.duration:4000);
  useEffect(() => {
    if (dialogBanner?.message) {
      start();
    }
  }, [dialogBanner]);

  useEffect(() => {
    if (topBanner?.message) {
        start();
    }
  }, [topBanner]);

  const {locale} = useContext(TranslationContext)
  return (
    <div
    style={{
      zIndex: 10001,
    }}
    >
      <Dialog
      styles={()=>({
        root:{
          zIndex:10001
        }
      })}
        style={{
          background: dialogBanner?.backgroundColor?? dialogBannerDebounced?.backgroundColor,
          color: dialogBanner?.color?? dialogBannerDebounced?.color,
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          // zIndex: 99999999,
          gap: "15px",

        }}
        opened={dialogBanner?.message}
        onClose={() => dialogBanner(false)}
      >
        {dialogBanner?.icon ? dialogBanner?.icon : dialogBannerDebounced?.icon}
        {dialogBanner?.message ?? dialogBannerDebounced?.message}
      </Dialog>
      <motion.div
        animate={{
            opacity: topBanner?.message ? 1 : 0,
            display: topBanner?.message ? "flex" : topBannerDebounced?.message?"flex":"none",
        }}
        style={{
          direction: locale === "ar" ? "rtl" : "ltr",
            position: "fixed",
            top: topBanner?.top ?? topBannerDebounced?.top??"0px",
            left: "0px",
            width: "100vw",
            height: "53px",
            zIndex: 10001,
            display: "flex",
            // background:"#0883ff",
            justifyContent: "start",
            alignItems: "center",
            background: topBanner?.backgroundColor ?? topBannerDebounced?.backgroundColor,
          color: topBanner?.color ?? topBannerDebounced?.color,
          gap: "15px",
          padding: "0px 10px",
          borderBottom: `1px solid ${topBanner?.color ?? topBannerDebounced?.color}`,
        }}
      >
                {topBanner?.icon ? topBanner?.icon : topBannerDebounced?.icon}

        {topBanner?.message ?? topBannerDebounced?.message}



      </motion.div>
    </div>
  );
}
// icon
// message
// backgroundColor
// color
// top
// duration






