import { createContext, useState, } from "react";
export const BannersContext: any = createContext(null);

const BannersContextProvider = ({ children }: any) => {
 

   const [topBanner, setTopBanner] = useState({});
   const [dialogBanner, setDialogBanner] = useState({});

  return (
    <BannersContext.Provider
      value={{
        topBanner,
        setTopBanner,
        dialogBanner,
        setDialogBanner,
      }}
    >
      {children}
    </BannersContext.Provider>
  );
};

export default BannersContextProvider;
