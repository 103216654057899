import {
  createStyles,
  Title,
  Text,
  Button,
  Container,
  Group,
  rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(220),
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colors[theme.primaryColor][3],

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(120),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(38),
    color: theme.white,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  description: {
    maxWidth: rem(540),
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colors[theme.primaryColor][1],
  },
}));

export function WrongRole() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.label}>Error</div>
        <Title className={classes.title}>Nothing to see here</Title>
        <Text size="lg" align="center" className={classes.description}>
          You are not authorized to view this page. Please contact the
          administrator if you think this is a mistake.
        </Text>
        <Group position="center">
          <Button
            variant="white"
            size="md"
            onClick={() => {
              navigate("/");
            }}
          >
            Take me back to home page
          </Button>
        </Group>
      </Container>
    </div>
  );
}
