import { Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../constants";
import jwt_decode from "jwt-decode";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function VerificationDirector() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token:any = searchParams.get("token");
    const [isError, setisError]: any = useState(false);
    // const decoded = ;

const navigate = useNavigate();

   useEffect (() => {
    if(jwt_decode(token)?.collectionId ? true : false){
      if(jwt_decode(token)?.collectionId == "1egdx08j2lxueql"){
       navigate("/admin/verification_success/?token="+token)
      }else if(
        jwt_decode(token)?.collectionId == "_pb_users_auth_"
      ){
        navigate("/applicant/verification_success/?token="+token)
      } else if(
        jwt_decode(token)?.collectionId == "nm8e7qqbnixlwl6"
      ){
        navigate("/recruiter/verification_success/?token="+token)
      }
       
      
    }else {
    }
   
   }, []);
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: 'center',
            height: "100vh",
            width: "100vw",
        }}>
            <Loader color="#21033F" onClick={async ()=>{

            }}/>
        </div>
    )

}  