import { TranslationContext } from "../../../context/TranslationContext";
import { useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Text } from "@mantine/core";

export const SelectableActionComponent = ({
  selectedRecords,
  action,
  table,
  setData,
  enabled,
}: any) => {
  const { locale }: any = useContext(TranslationContext);

  return (
    <div>
      <div
        style={{
          cursor: selectedRecords?.[0] && enabled ? "pointer" : "not-allowed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "70px",
          height: "100%",
          margin: "0px 3px",
          whiteSpace: "nowrap",
        }}
        onClick={() => {
          if (selectedRecords?.[0] && enabled) {

            setData();
          }
        }}
      >
        <Icon
          icon={action?.icon}
          color={selectedRecords?.[0] && enabled ? action?.color : action?.color + "50"}
          style={{
            margin: "0px 5px",
            fontSize: "20px",
          }}
        />
        <Text color={selectedRecords?.[0] && enabled ? "black" : "#b3b3b3"} fz={13}>
          {action?.["label_" + locale]}
        </Text>
      </div>
    </div>
  );
};
