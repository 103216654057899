import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const CancelProgram = (props: any) => {
  const { setCancelProgram }: any = useContext(DataContext);

  const { data, action, table } = props;
  const when_be_able =
    data?.status != "canceled" &&
    data?.status != "rejected" &&
    data?.status != "completed";
  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setCancelProgram(
      {
        id: data.id,
        record:data,
        action:action,
        table:table
      }
    )}
    enabled={when_be_able}
    visible={when_be_able}
    />
  );
};
