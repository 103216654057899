import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "./context/TranslationContext";
import { SystemContext } from "./context/SystemContext";

export const t = (name: string) => {
  const { translation, locale }: any = useContext(TranslationContext);

  const TextFinal =  translation.translations?.[0]
  ? translation?.translations.find((item: any) => item.name === name)
    ? translation?.translations.find((item: any) => item.name === name)[
      locale
      ]
    : ""
  : "";
  try{
    return TextFinal ? (
      <div dangerouslySetInnerHTML={{ __html: TextFinal }} />
    ) : (
      "!" + name
    );
  } catch(e) {
    return "!" + name
  }
  return ""
  
};
export const tn = (name: string) => {

  const { translation, locale }: any = useContext(TranslationContext);

  const TextFinal =  translation.translations?.[0]
  ? translation?.translations.find((item: any) => item.name === name)
    ? translation?.translations.find((item: any) => item.name === name)[
      locale
      ]
    : ""
  : "";

  try{
    return TextFinal ? (
      TextFinal
    ) : (
      "!" + name
    );
  } catch(e) {
    return "!" + name
  } 
  }



export const lt = (name: string) => {

  const { translation, locale }: any = useContext(TranslationContext);


  const TextFinal =  translation.long_texts?.[0]
  ? translation?.long_texts.find((item: any) => item.name === name)
    ? translation?.long_texts.find((item: any) => item.name === name)[
      locale
      ]
    : ""
  : "";
 
  try{
    return TextFinal ? (
      <div dangerouslySetInnerHTML={{ __html: TextFinal }} />
    ) : (
      "!" + name
    );
  } catch(e) {
    return "!" + name
  }
  
};


export const ltn = (name: string) => {
  const { translation, locale }: any = useContext(TranslationContext);
  
  const TextFinal =  translation.long_texts?.[0]
  ? translation?.long_texts.find((item: any) => item.name === name)
    ? translation?.long_texts.find((item: any) => item.name === name)[
      locale
      ]
    : ""
  : "";
  try{
    return TextFinal ? (
      TextFinal
    ) : (
      "!" + name
    );
  } catch(e) {
    return "!" + name
  }
  
};




export const vt = (name: string) => {

  const { translation, locale }: any = useContext(TranslationContext);


  const TextFinal =  translation.validates?.[0]
  ? translation?.validates.find((item: any) => item.name === name)
    ? translation?.validates.find((item: any) => item.name === name)[
      locale
      ]
    : ""
  : "";
 
  try{
    return TextFinal ? (
      <div dangerouslySetInnerHTML={{ __html: TextFinal }} />
    ) : (
      "!" + name
    );
  } catch(e) {
    return "!" + name
  }
  
};


export const vtn = (name: string) => {
  const { translation, locale }: any = useContext(TranslationContext);
  
  const TextFinal =  translation.validates?.[0]
  ? translation?.validates.find((item: any) => item.name === name)
    ? translation?.validates.find((item: any) => item.name === name)[
      locale
      ]
    : ""
  : "";
  try{
    return TextFinal ? (
      TextFinal
    ) : (
      "!" + name
    );
  } catch(e) {
    return "!" + name
  }
  
};

export const ttn = (name: string) => {

  const { systemData }: any = useContext(SystemContext);
  const {  locale }: any = useContext(TranslationContext);

  const TextFinal =  systemData.tables?.[0]
  ? systemData.tables.find((item: any) => item.name === name)
    ? systemData.tables.find((item: any) => item.name === name)[
     "title_"+ locale
      ]
    : ""
  : "";
  
  try{
    return TextFinal ? (
      TextFinal
    ) : (
      "!" + name
    );
  } catch(e) {
    return "!" + name
  } 
  }
