import { ltn, tn } from "../../../../../../translation";
import {
  Button,
  Loader,
  Progress,
  Rating,
  Slider,
  Text,
  TextInput,
} from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { set } from "lodash";

export const ProgramEvaluationView = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data?.record, 200);

  const { reloader, setReloader } = useContext(AppContext);
  const [company, setCompany]: any = useState({});
  const [evaluation, setEvaluation]: any = useState({});
  const [buttonLoading, setButtonLoading] = useState(false)
  useEffect(() => {
    const getCompany = async () => {
      const company = await pb
        .collection("companies")
        .getOne(pb.authStore.model.company);

        if(company?.current_models
          ?.trainee_evaluation_form){
            const evaluation_model = await pb
            .collection("evaluation_models")
            .getOne(
              company?.current_models
                ?.trainee_evaluation_form
            );
          setEvaluation(evaluation_model);
          }else{
            const record___ = await pb.collection('evaluation_models').getFirstListItem('identifier="trainee_evaluation_form"', {});
            setEvaluation(record___); 
          }


    
      setCompany(company);
    };
    getCompany();
  }, []);

  const form = useForm({
    initialValues: {
      precentage: 0,
      criterias: [],
    },
  });
  useEffect(() => {
    form.setValues({
      precentage: data?.record?.program_evaluation?.precentage ?? 0,
      criterias:
      data?.record?.program_evaluation?.criterias ??
        evaluation?.criterias?.map((criterias) => {
          return {
            name: criterias.name,
            description: criterias.description,
            rating: 0,
            comment: "",
            weight: criterias.weight,
          };
        }),
    });
  }, [evaluation, company]);

  const [precentage, setPrecentage] = useState(0);
  useEffect(() => {
    // get the precentage ot the evaluation by using the waight of each criteria
    let total_weight = 0;
    let total_score = 0;
    form.values?.criterias?.map((item) => {
      total_weight += item.weight;
      total_score += (item.rating / 5) * item.weight;
    });
    setPrecentage(Math.round((total_score / total_weight) * 100));
  }, [form.values?.criterias]);
  if (!form.values?.criterias) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "700px",
        }}
      >
        <Loader />
      </div>
    );
  }

  console.log("evaluationevaluationevaluation", debounced_data);
  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        setButtonLoading(true)
        await pb
          .collection("applications")
          .update(debounced_data?.id, {
            program_evaluation: {
              precentage: precentage,
              criterias: values.criterias,
            },
            program_evaluation_evaluator: pb.authStore?.model?.id,
          })
        onClose({});
        setReloader(!reloader);
        setButtonLoading(false)
      })}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text size="xl" fw={600} color="#444444">
        {tn("Trainee evaluation form after completion of the program")}
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          marginTop: "20px",
          width: "100%",
          padding: "0px 10px",
        }}
      >
       
       <DataBox
          title={tn("evaluator")}
          value={
            debounced_data?.applicant_name
          }
          onClick={undefined}
        />
        {/* program name */}
       
        <DataBox
          title={tn("Evaluation date")}
          value={ debounced_data?.program_evaluation_date?new Date(
            debounced_data?.program_evaluation_date
          ).toLocaleDateString():tn("not evaluated")}
          onClick={undefined}
        />
        {/* السيرة الذاتية: */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: "calc(50% - 20px)",
            padding: "0px 10px",
            gap: "10px",
          }}
        >
          <Text size="xl" fw="800" color="color3">
            {precentage > -1 && (precentage + "%")}
          </Text>
          <Progress
            color="color3"
            radius="lg"
            size="xl"
            value={precentage}
            style={{
              width: "66%",
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "20px",
          border: "1px solid #E0E0E0",
          borderRadius: "10px",
          minHeight: "100px",
          maxHeight: "650px",
          overflowY: "auto",
        }}
      >
        {form?.values?.criterias?.map((item: any, index) => {
          return <CriteriaBox item={item} form={form} index={index} data={data} />;
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
          width: "100%",
          gap: "10px",
        }}
      >
        <Button variant="outline" color="gray" onClick={() => onClose({})}>
          {tn("Cancel")}
        </Button>
        {/* <Button loading={buttonLoading} color="color2" type="submit">
          {tn("Save")}
        </Button> */}
      </div>
    </form>
  );
};
{
  /* <Slider
       marks={[
        { value: 20, label: '20%' },
        { value: 50, label: '50%' },
        { value: 80, label: '80%' },
      ]}
      label={(value:any) => value + "%"} 
      value={evaluation}
      onChange={(e:any)=>{
        setEvaluation(e);
      }} style={{
        width:"100%",
        marginTop:"20px",
        marginBottom:"10px"
      }}  min={0} max={100} step={1}
  /> */
}

const DataBox = ({ value, title, onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        marginTop: "10px",
        gap: "5px",
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <Text size="sm" color="#444444">
        {title}
        {": "}
      </Text>
      <Text size="sm" color={onClick ? "#FFB004" : "#444444"}>
        {value}
      </Text>
    </div>
  );
};

const CriteriaBox = ({ item, form, index,data }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF",
        padding: "10px",
        borderRadius: "10px",opacity: 0.8,
      }}
    >
      <div
        style={{
          width: "calc(40% - 10px)",
          display: "flex",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Text size="sm" color="#444444">
          {index + 1}
        </Text>
        <Text size="sm" color="#444444">
          {/* title with bold + - + description */}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {item.name}
          </span>
          {" - "}
          {item.description}
        </Text>
      </div>
  
    <Rating
   value={item.rating}
    size="lg"
            style={{
              opacity: 0.5,
            }}
  />
 
      <TextInput
      disabled
        placeholder={tn("Add comment")}
        {...form.getInputProps(`criterias.${index}.comment`)}
        style={{
          width: "calc(50% - 10px)",
        }}
        styles={() => ({
          input: {
            height: "40px",
            borderRadius: "10px",
            border: "1px solid #E0E0E0",
            padding: "10px",
            fontSize: "14px",
            color: "#444444",
          },
        })}
      />
    </div>
  );
};
