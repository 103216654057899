import { TranslationContext } from "../../../../context/TranslationContext";
import { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import { SelectableActionComponent } from "../SelectableActionComponent";

export const AcceptNewApplicant = (props: any) => {
  const { setAcceptNewApplicant }: any = useContext(DataContext);

  const { selectedRecords, action, table } = props;
  return (
   <SelectableActionComponent
   selectedRecords={selectedRecords}
      action={action}
      table={table}
      setData={async () => {
        setAcceptNewApplicant({
          id: selectedRecords?.[0],
          record: selectedRecords,
          action: action,
          table: table,
        });
      }}
      enabled={true}
   />
  );
};
