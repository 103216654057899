import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const ProgramEvaluation = (props: any) => {
  const { setProgramEvaluation }: any = useContext(DataContext);

  const { data, action, table } = props;
  return (
    <ActionComponent
    is_mobile={true}
    data={data}
    action={action}
    table={table}
    setData={async () => {
      setProgramEvaluation({
        id: data.id,
        record: data,
        action: action,
        table: table,
      });
      
    }}
    enabled={!data?.program_evaluation?.precentage}
  />
    
  );
};
