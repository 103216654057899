import {
    Checkbox,
    Divider,
    Group,
    Modal,
    MultiSelect,
    NumberInput,
    Radio,
    Select,
    Text,
    TextInput,
    Textarea,
  } from "@mantine/core";
import { tn } from "../../../../../../../translation";
import { DateInput } from "@mantine/dates";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { pb } from "../../../../../../../config";
import { convertArabicNumbersToEnglish, vf } from "../../../../../../Functions";
import { useContext, useEffect, useState } from "react";
import { AddDepartment } from "../../departments_manage/AddDepartment";
import { DataContext } from "../../../../../../../context/DataContext";



 export const FirstForm = ({form,locale,translation,departments,disabled}:any) => {
    const program_type = vf("program_type");
    const city = vf("city");
    const major =  vf("major");
    const degree =  vf("degree");
    const case_study =  vf("case_study");
    const period = vf("period");
    const nature =  vf("program_nature");
    const { setAddDepartment, addDepartment} = useContext(DataContext);
    useEffect(() => {
      // dont let objectives obove 300 characters and brief above 500 characters
      if (form.values?.objectives?.length > 300) {
        form.setFieldValue("objectives", form.values?.objectives?.slice(0, 300));
      }
      if (form.values?.brief?.length > 500) {
        form.setFieldValue("brief", form.values?.brief?.slice(0, 500));
      }
    }, [form.values?.objectives, form.values?.brief]);
    useEffect(() => {
      if (form.values?.department === "add_department") {
        setAddDepartment({id: true, form:form, z: 10001});
        form.setFieldValue("department", "");
      }
    }, [form.values?.department]);
    console.log("fotment",form.values?.department,form?.values?.department_data)
    return (
        <div
          style={{
            width: "calc(50% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "93%",
              background: "#21033F",
              height: "7px",
              borderRadius: "522px",
            }}
          />
          <Divider
          labelPosition="center"
           label={tn("Basic data of the program")}
           style={{
            width: "calc(100% - 20px)",
            margin: "5px 0px -10px",
            
           }}
          />
     
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "10px",
              padding: "0px 10px",
            }}
          >
            <SelectField
              data={mapOptions(form?.values?.department_data?.[0]?.id ?
                  [
                  ...departments,
                  ...form?.values?.department_data,
                  {
                    name : locale === "ar" ? "اضافة قسم" : "Add department",
                    id: "add_department",
                  }
                  ]
                :[
                ...departments,
                {
                  name : locale === "ar" ? "اضافة قسم" : "Add department",
                  id: "add_department",
                }
               
              ], "name", "id")}
              label="Select section"
              description="Choose the training department of your facility"
              error={form.errors?.department}
              inputProps={form.getInputProps("department")}
              withAsterisk
              disabled={disabled || pb.authStore.model.expand?.permission?.accessible_data != "all_data"
              }
            />
            <SelectField
              data={mapOptions(program_type, locale, "name")}
              label="Program type"
              description="Choose the type of training program you want to publish"
              error={form.errors?.type}
              inputProps={form.getInputProps("type")}
              withAsterisk
              disabled={disabled}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              padding: "0px 10px",
              margin: "10px 0px 0px",
            }}
          >
            <Text fz="13" color="#444444">
              {tn("Program nature")}
            </Text>
            <Radio.Group
              {...form.getInputProps("nature")}
              withAsterisk
              size="xs"
            >
              <Group>
                {nature?.[0] && nature?.map((item: any) => (
                  <Radio
                    key={item.name}
                    value={item.name}
                    label={item?.[locale]}
                    disabled={disabled}
                  />
                ))}
              
              </Group>
            </Radio.Group>
          </div>
          <TextField
            padding="0px 10px"
            description="The name of the program, which will appear when it is published"
            label="Program name"
            error={form.errors?.name}
            inputProps={form.getInputProps("name")}
            withAsterisk
            disabled={disabled}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "10px",
              padding: "0px 10px",
            }}
          >
            <TextAreaField
              description="Write a brief description of the program"
              label="Program brief"
              error={form.errors?.brief}
              inputProps={form.getInputProps("brief")}
              withAsterisk
              disabled={disabled}
            />
            <TextAreaField
              description="Write the program objectives and expected outcomes for the trainee"
              label="Program objectives"
              error={form.errors?.objectives}
              inputProps={form.getInputProps("objective")}
              withAsterisk
              disabled={disabled}
            />
          </div>{" "}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "10px",
              padding: "0px 10px",
            }}
          >
            <SelectField
              data={mapOptions(period, locale, "name")}
              label="program period"
              description="Choose the duration of the program"
              error={form.errors?.period}
              inputProps={form.getInputProps("period")}
              withAsterisk
              disabled={disabled}
            />

            <MultiSelectField
              data={mapOptions(degree, locale, "name")}
              label="Targeted degrees"
              description="degrees targeted in this training program"
              error={form.errors?.degree}
              inputProps={form.getInputProps("degree")}
              withAsterisk
              maxValues={2}
              searchable
              disabled={disabled}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "10px",
              padding: "0px 10px",
            }}
          >
            <MultiSelectField
              data={mapOptions(major,locale, "name")}
              label="Targeted majors"
              description="University majors targeted in this training program"
              error={form.errors?.targeted_majors}
              inputProps={form.getInputProps("targeted_majors")}
              withAsterisk
              maxValues={2}
              searchable
              disabled={disabled}
            />
            <MultiSelectField
              data={mapOptions(case_study, locale, "name")}
              label="Target educational segment"
              description="At the stage of study, or about to graduate or graduates"
              error={form.errors?.target_educational_segment}
              inputProps={form.getInputProps("target_educational_segment")}
              withAsterisk
              maxValues={2}
              searchable
              disabled={disabled}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 0px",
              padding: "0px 10px",
              gap: "10px",
            }}
          >
            
            <NumberField
              disabled={!form?.values?.department || disabled}
              description="Enter the number of training seats available in this program"
              label="Candidate in need"
              error={form.errors?.candidate_in_need}
              inputProps={form.getInputProps("candidate_in_need")}
              withAsterisk
              min={0}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                margin: "0px 0px 14px",
              }}
            >
              <Checkbox
                style={{ marginTop: "23px" }}
                {...form.getInputProps("is_incentive")}
                checked={form.values?.is_incentive}
                size="xs"
                styles={() => ({
                  label: {
                    fontSize: "12px",
                    color: "#FFB004",
                  },
                })}
                disabled={disabled}
              />

              <TextInput
                disabled={!form?.values?.is_incentive || disabled}
                {...form.getInputProps("incentive", {
                
                })}
                onChange={(event) => {
                  if(event?.target?.value == "" || event?.target?.value == null || event?.target?.value == undefined) {
                    form.setFieldValue("incentive", 0);  
                  } ;
                  let inputValue = event.target.value;
              
                  // Allow only Arabic or English numerals and prevent spaces
                  const validCharacters = /^[0-9٠-٩]+$/;
              
                  // Remove any invalid characters (non-numeric or spaces)
                  if (validCharacters.test(inputValue)) {
                    const toEnglish = convertArabicNumbersToEnglish(inputValue); // Convert Arabic to English if needed
                    form.setFieldValue("incentive", parseInt(toEnglish));  // Save the valid value
                  }
                }}
                style={{
                  width: "100%",
                }}
                rightSection={<Text color="#5c5c5c">{tn("riyal")}</Text>}
                rightSectionProps={{
                  fontSize: "10px",
                }}
                rightSectionWidth={40}
                size="xs"
                hideControls={true}
                styles={() => TextInputStyle(form.errors?.incentive)}
                label={tn("Program incentive")}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "10px",
              padding: "0px 10px",
              marginBottom: "30px",
            }}
          >
            <DateInput
            disabled={disabled}
              style={{ width: "100%", direction: "ltr" }}
              nextIcon={
                <IconChevronRight size={20} style={{ color: "#1d604f" }} />
              }
              previousIcon={
                <IconChevronLeft size={20} style={{ color: "#1d604f" }} />
              }
              firstDayOfWeek={0}
              minDate={new Date()}
              description={tn("Start date of the training program")}
              withAsterisk
              label={tn("Start Date")}
              {...form.getInputProps("start_date")}
              styles={() => TextInputStyleLTR(form.errors?.start_date)}
              size="xs"
            />
            <DateInput
              nextIcon={
                <IconChevronRight size={20} style={{ color: "#1d604f" }} />
              }
              previousIcon={
                <IconChevronLeft size={20} style={{ color: "#1d604f" }} />
              }
              firstDayOfWeek={0}
              disabled={!form?.values?.start_date || disabled}
              maxDate={form?.values?.start_date}
              minDate={new Date()}
              description={tn(
                "The last date for receiving applications to join"
              )}
              withAsterisk
              label={tn("Last submission date")}
              {...form.getInputProps("last_submission_date")}
              styles={() =>
                TextInputStyleLTR(form.errors?.last_submission_date)
              }
              size="xs"
              style={{ width: "100%", direction: "ltr" }}
            />
          </div>
         
        
        </div>
    )
 }

 const SelectField = ({
    data,
    label,
    description,
    error,
    inputProps,
    withAsterisk = false,
    disabled,
  }: any) => (
    <div
      style={{
        width: "100%",
      }}
    >
      <Select
        disabled={disabled}
        data={data}
        label={tn(label)}
        withAsterisk={withAsterisk}
        styles={() => TextInputStyle(error)}
        {...inputProps}
        size="xs"
        style={{ width: "100%" }}
      />
      <Text mt="2px" px="5px" fz="8px" color="#868e96">
        {tn(description)}
      </Text>
    </div>
  );
  
  // Generic TextInput Component
  const TextField = ({
    description,
    label,
    error,
    inputProps,
    withAsterisk = false,
    padding,
    disabled
  }: any) => (
    <div
      style={{
        width: "100%",
        padding: padding ? padding : "0px 0px",
      }}
    >
      <TextInput
        disabled={disabled}
        label={tn(label)}
        withAsterisk={withAsterisk}
        styles={() => TextInputStyle(error)}
        {...inputProps}
        size="xs"
        style={{ width: "100%" }}
      />
      <Text mt="2px" px="5px" fz="8px" color="#868e96">
        {tn(description)}
      </Text>
    </div>
  );
  const NumberField = ({
    disabled,
    description,
    label,
    error,
    inputProps,
    withAsterisk = false,
    padding,
    min
  }: any) => (
    <div
      style={{
        width: "100%",
        padding: padding ? padding : "0px 0px",
      }}
    >
      <NumberInput
        disabled={disabled}
        label={tn(label)}
        withAsterisk={withAsterisk}
        styles={() => TextInputStyle(error)}
        {...inputProps}
      
        size="xs"
        style={{ width: "100%" }}
        min={min}
      />
      <Text mt="2px" px="5px" fz="8px" color="#868e96">
        {tn(description)}
      </Text>
    </div>
  );
  
  // Generic TextArea Component
  const TextAreaField = ({
    disabled,
    description,
    label,
    error,
    inputProps,
    withAsterisk = false,
  }: any) => (
    <div
      style={{
        width: "100%",
      }}
    >
      <Textarea
        disabled={disabled}
        label={tn(label)}
        withAsterisk={withAsterisk}
        styles={() => TextInputStyle(error)}
        {...inputProps}
        size="xs"
        style={{ width: "100%" }}
        maxLength={500}
      />
      <Text mt="2px" px="5px" fz="8px" color="#868e96">
        {tn(description)}
      </Text>
    </div>
  );
  

  const MultiSelectField = ({
    disabled,
    data,
    label,
    description,
    error,
    inputProps,
    withAsterisk = false,
    maxValues,
    searchable,
  }: any) => (
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <MultiSelect
        disabled={disabled}
        maxValues={maxValues}
        searchable={searchable}
        data={data}
        label={tn(label)}
        withAsterisk={withAsterisk}
        styles={() => TextInputStyle(error)}
        {...inputProps}
        size="xs"
        style={{ width: "100%", maxWidth: "180px", }}
      />
      <Text mt="2px" px="5px" fz="8px" color="#868e96">
        {tn(description)}
      </Text>
    </div>
  );

  
const TextInputStyle = (error: any) => ({
    label: {
      zIndex: "10",
      position: "relative",
      margin: "0px 10px",
      transform: "translate(0px, 7px)",
      background: "#f6f6f6",
      borderRadius: "5px",
      padding: "0px 5px",
      fontSize: "10px",
    },
    input: {
      borderRadius: "5px",
      border: "1px solid #000000",
    },
    error: {
      marginBottom: "-14px",
      transform: "translate(4px, 12px)",
      // fontSize: "8px",
    },
  });

const TextInputStyleLTR = (error: any) => ({
    root: {
      position: "relative",
      marginTop: "23px",
    },
    description: {
      marginBottom: "-17px",
      transform: "translate(5px, 35px)",
      fontSize: "8px",
      opacity: error ? "0" : "1",
    },
    label: {
      zIndex: "10",
      position: "absolute",
      left: "0px",
      margin: "0px 10px",
      transform: "translate(0px, -10px)",
      background: "#f6f6f6",
      borderRadius: "5px",
      padding: "0px 5px",
      fontSize: "10px",
    },
    input: {
      borderRadius: "5px",
      border: "1px solid #000000",
    },
    calendarHeader: {},
    error: {
      marginBottom: "-17px",
      transform: "translate(4px, 3px)",
      // fontSize: "8px",
    },
  });
  
  const mapOptions = (items: any, labelField: any, valueField: any) =>
  items.map((item: any) => ({
    value: item[valueField],
    label: item[labelField],
  }));