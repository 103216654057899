import { useContext, useEffect, useState } from "react";
import { Button, Dialog, Menu, Tooltip } from "@mantine/core";
import PocketBase from "pocketbase";
import { Icon } from "@iconify/react";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { IconAlertTriangle, IconSquareRoundedX } from "@tabler/icons-react";
import { ltn } from "../../../../../../translation";
import { ActionComponent } from "../../../ActionComponent";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { DataContext } from "../../../../../../context/DataContext";
import { BannersContext } from "../../../../../../context/BannersContext";
import { pb } from "../../../../../../config";
import { useNavigate } from "react-router-dom";
import { handleApplyInProgramClick } from "../programs_search/ApplyForProgram";

interface ApplyForProgramProps {
  data: {
    id: string;
    [key: string]: any;
  };
  action: string;
  table: string;
}


export const AcceptInvitation = ({
  data,
  action,
  table,
}: ApplyForProgramProps) => {
  const { setAcceptInvitation }: any = useContext(DataContext);

  const { setTopBanner }: any = useContext(BannersContext);
  const messageTaken = ltn("applied_already");
  const messageUserData = ltn("fill_your_data");
  const messageAccepted = ltn("accepted_already");
  const messageAcceptedAnswer = ltn("accepted_already_answer");
  const GPA_not_enough = ltn("GPA_not_enough");
  const university_not_in_program = ltn("university_not_in_program");
  const case_study_not_in_program = ltn("case_study_not_in_program");
  const major_not_in_program = ltn("major_not_in_program");
  const degree_not_in_program = ltn("degree_not_in_program");
  const navigate = useNavigate();

  return (
    <ActionComponent
      is_mobile={true}
      data={data}
      action={action}
      table={table}
      setData={async () => {
       
        if (
          await handleApplyInProgramClick({
            setTopBanner,
            messages: {
              messageTaken,
              messageUserData,
              messageAccepted,
              messageAcceptedAnswer,
              GPA_not_enough,
              university_not_in_program,
              case_study_not_in_program,
              major_not_in_program,
              degree_not_in_program,
            },
            navigate,
            data: data?.expand?.program,
          })
        ) {
          // setAcceptInvitation({ id: data.id, record: data, action, table });
          setAcceptInvitation({
            id: data.id,
            record: data,
            action: action,
            table: table,
          });
        }
      }}
      enabled={true}
    />
  );
};
