import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { Navbars } from "../../../main_components/navbar/Navbar";
import { useEffect } from "react";
import MainHeaderApplicant from "../components/MainHeaderApplicant";

const MainLayout = () => {
  const navigate = useNavigate();
  const pb: any = new PocketBase(CMS_ENDPOINT);
  useEffect(() => {
      if(pb?.authStore?.model?.collectionName !== "applicants" || pb?.authStore?.model?.verified !== true){
      navigate("/applicant/login")
    }
  }, [])
  const isAllowed = pb?.authStore?.model?.collectionName === "applicants" || pb?.authStore?.model?.verified === true;
  return (
    isAllowed? <Body>
      <div style={{position: "absolute",top:"0",zIndex: "9996"}}>
      <MainHeaderApplicant/>
      </div>
      
      <div style={{
        width: "100vw",
        display: "flex",
        alignItems: "end",
        justifyContent: "center",
        marginTop: "84px",
      }}>
        <Navbars name="applicant_nav" color ="#000"/>
     
      <Content>
        <Outlet />
      </Content>
      </div>
    </Body>:<></>
  );
};


const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const Content = styled.div`
  min-width:  calc(100vw - 300px);
  max-width:  calc(100vw - 300px);
  @media (max-width: 1179px) {
    min-width:  calc(100vw - 0px);
    max-width:  calc(100vw - 0px);
  }
  max-height: calc(100vh - 84px);
  min-height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: scroll;
`;
export default MainLayout;
