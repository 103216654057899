import { useContext } from "react";
import { DataContext } from "../../../../context/DataContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../constants";
import { ltn } from "../../../../translation";
import { BannersContext } from "../../../../context/BannersContext";
import { IconSquareRoundedX } from "@tabler/icons-react";
import alasql from "alasql";
import { SelectableActionComponent } from "../SelectableActionComponent";

export const AcceptScheduledInterview = (props: any) => {
  const { setAcceptScheduledInterview }: any = useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);

  const pb = new PocketBase(CMS_ENDPOINT);
  const { selectedRecords, action, table } = props;
  const dialogMessage = selectedRecords?.length > 1 
    ? ltn("applicants_view_programs_full") 
    : ltn("applicant_view_programs_full");

  const fetchProgramsData = async (programIds: string[]) => {
    return pb.collection("applicant_view_programs").getList(1, 50, {
      filter: programIds.map((id) => `id = "${id}"`).join(" || "),
    });
  };

  const checkFreeSeats = (programs: any[], applications: any[]) => {
    return programs.every((program) =>
      alasql(`select * from ? where program = ?`, [
        applications,
        program.id,
      ]).length <= program.free_seats
    );
  };

  const handleAction = async () => {
    try {
      const applicationsResponse = await pb.collection("applications_view").getList(1, 50, {
        filter: selectedRecords.map((id: string) => `id = "${id}"`).join(" || "),
      });

      const programIds = applicationsResponse.items.map((record: any) => record.program);
      const programsResponse = await fetchProgramsData(programIds);

      if (checkFreeSeats(programsResponse.items, applicationsResponse.items)) {
        setAcceptScheduledInterview({
          id: selectedRecords[0],
          record: selectedRecords,
          action,
          table,
        });
      } else {
        setTopBanner({
          message: dialogMessage,
          color: "#c02d2d",
          top: "83px",
          backgroundColor: "#fff2f2eb",
          icon: <IconSquareRoundedX />,
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Error handling action:", error);
      // Optionally, you can set an error banner here.
    }
  };

  return (
    <SelectableActionComponent
      selectedRecords={selectedRecords}
      action={action}
      table={table}
      setData={handleAction}
      enabled={true}
    />
  );
};
