import {
  TextInput,
  PasswordInput,
  Button,
  Text,
  Anchor,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { isNotEmpty, useForm } from "@mantine/form";
import { useContext, useState } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { BannersContext } from "../../../context/BannersContext";
import { IconCheck, IconKey, IconMail, IconX } from "@tabler/icons-react";
import { Helmet } from "react-helmet";
import { TopShapeAuth } from "../components/TopShapeAuth";

function Login() {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [isValidatedError, setIsValidatedError] = useState(false);
  const navigate = useNavigate();
  const [isError, setisError]: any = useState(false);
  const [buttonLoading, setButtonLoading]: any = useState(false);

  const form: any = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: isNotEmpty(vtn("email_empty")),
      password: isNotEmpty(vtn("password_empty")),
    },
  });

  const { locale } = useContext(TranslationContext);
  const { setTopBanner, setDialogBanner }: any = useContext(BannersContext);

  const errorMassage = tn("Invalid email or password");
  const errorValidatedMassage = tn("Please verify your email first");
  const dialogMassage = tn("Activation message sent");
  return (
    <>
      <Helmet>
        <title>{tn("Login") + " / " + tn("Coophub")}</title>
      </Helmet>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100vh",
          overflowY: "auto",
        }}
        onSubmit={form.onSubmit(async () => {
          // if(form.isValid()){
            setButtonLoading(true);
            setisError(false);
            setIsValidatedError(false);

          try {
            const authData = await pb
              .collection("applicants")
              .authWithPassword(
                form.values.email?.toLowerCase(),
                form.values.password
              )
              .then((res: any) => {
                setButtonLoading(false);
                // if (res.record.full_info) {
                //   navigate("/applicant/programs_search");
                // } else {
                //   navigate("/applicant/full_info");
                // }
                if (res?.record.verified) {
                  navigate("/applicant/programs_search");
                } else {
                  setIsValidatedError(true);
                  pb.authStore.clear();
                  setButtonLoading(false);
                }
              });
          } catch (err) {
            setisError(true);
            setButtonLoading(false);
          }
          // }
        })}
      >
        <TopShapeAuth/>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            gap: "20px",
            width: "100%",
          }}
        >
          <TextInput
            icon={
              <IconMail
                {...IconStyle}
              />
            }
            placeholder={tn("Email Address")}
            {...form.getInputProps("email")}
            {...InputStyle}
          />
          <PasswordInput
            icon={
              <IconKey
              {...IconStyle}
              />
            }
            placeholder={tn("Password")}
            {...form.getInputProps("password")}
            {...InputStyle}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "0px 10px",
            }}
          >
            <Anchor
              color="#ffc038"
                onClick={() => navigate("/applicant/forgot_password")}
              style={{
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              {tn("Forgot Password?")}
            </Anchor>
          </div>
          <div style={{
            width: "100%",
          }}>
          {!buttonLoading && isError && (
                <Text  size="13px" color="red">
                  {errorMassage}
                </Text>
              )}
              {!buttonLoading && isValidatedError && (
                <Text size="13px" color="red">
                  {errorValidatedMassage}
                  {"  "}
                  <span>
                    <Anchor
                      onClick={async () => {
                        try {
                          await pb
                            .collection("applicants")
                            .requestVerification(form.values.email)
                            .then(() => {
                              setDialogBanner({
                                message: dialogMassage,
                                color: "green",
                                backgroundColor: "#9be1d0",
                                icon: (
                                  <IconCheck
                                    style={{
                                      margin: "0px 10px",
                                    }}
                                  />
                                ),
                              });
                            });
                        } catch (err) {
                          setDialogBanner({
                            message: "Something went wrong",
                            color: "red",
                            backgroundColor: "#e19b9b",
                            icon: (
                              <IconX
                                style={{
                                  margin: "0px 10px",
                                }}
                              />
                            ),
                          });
                        }
                      }}
                      color="#ff9900"
                    >
                      {tn("Click here to send the verification link")}
                    </Anchor>
                  </span>
                </Text>
              )}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            width: "100%",
            padding: "20px",
          }}
        >
          <Button
          loading={buttonLoading}
            color="color2"
            style={{
              width: "100%",
              height: "60px",
              borderRadius: "15px",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
            type="submit"
          >
            {tn("login")}
          </Button>
          <Anchor
          onClick={() => navigate("/applicant/register")}
            color="#767676"
            style={{
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "700",
            }}
          >
            {tn("Create a new account")}
          </Anchor>
          </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            width: "100%",
            padding: "20px",
          }}
        >
         
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              width: "100%",
              maxWidth: "500px",
              flexDirection: "row",
            }}
          > <a style={{textDecoration: "none"}} target="_blank" href="https://www.coophub.co/terms">
          <Text
              style={{
                textAlign: "center",
              }}
              fz="12px"
              color="#767676"
              weight={700}
            >
              {tn("terms and conditions")}
            </Text>
        </a>
        {divider}
        <a style={{textDecoration: "none"}} target="_blank" href="https://www.coophub.co/terms">
        <Text
              style={{
                textAlign: "center",
              }}
              fz="12px"
              color="#767676"
              weight={700}
            >
              {tn("Usage policy")}
            </Text>
        </a>
            {divider}
            <Text
              style={{
                textAlign: "center",
              }}
              fz="12px"
              color="#767676"
              weight={700}
            >
              {tn("All rights are save")}
            </Text>{" "}
          </div>
        </div>
      </form>
    </>
  );
}

export default Login;

const divider = (
  <div
    style={{
      width: "1px",
      height: "15px",
      background: "#767676",
      margin: "0px 5px",
    }}
  />
);


const InputStyle = {
    size:"lg",
    style:{
      width: "100%",
    },
    styles:() => ({
      input: {
        color: "#4b4b4b",
        border: "1px solid rgba(33, 3, 63, 0.50)",
        borderRadius: "15px",
      },
    })
}

const IconStyle = {
    size:"1.5rem",
    stroke:1.5,
    style:{
      color: "#a1a1a1",
    }
}