import { ltn, tn } from "../../../../../../translation";
import { Button, Table, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT, WEB_ENDPOINT } from "../../../../../../constants";
import { useContext } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { fv } from "../../../../../Functions";

export const ActiveProgramsApplicant = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const [debounced_data] = useDebouncedValue(data?.record, 200);
  const { translation, locale }: any = useContext(TranslationContext);
  const { setContractViewerData }: any =
  useContext(AppContext);
  const openNewTab = () => {
    const url = WEB_ENDPOINT + "/contract_viewer"; // Replace with your desired URL
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fz="xl" fw={700} color="#4a4a4a">
        {tn("Applicant information")}
      </Text>
      <MainBox
        style={{
          marginTop: "30px",
        }}
      >
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#5c5c5c",
            textAlign: "center",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "7px",
            marginTop: "10px",
          }}
        >
          {tn("Applicant")}
        </Text>
        <div
          style={{
            background: "#fff",
            borderRadius: "10px",
            width: "calc(100% - 20px)",
            minWidth: "550px",
            height: "100px",
            margin: "10px 0px",
            boxShadow: "0px 0px 3px #51515133",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            padding: "0px 5px",
          }}
        >
          <Table>
            <thead>
              <tr>
                <TH>{tn("name")}</TH>
                <TH>{tn("Email Address")}</TH>
                <TH>{tn("Phone")}</TH>
                <TH>{tn("University")}</TH>
                <TH>{tn("Major")}</TH>
              </tr>
            </thead>
            <tbody>
              {" "}
              <tr key={1}>
                <TD>{debounced_data?.applicant_name}</TD>
                <TD>{debounced_data?.applicant_email}</TD>
                <TD>{debounced_data?.applicant_mobile}</TD>
                <TD>{fv(debounced_data?.university,"university",translation)?.[locale]}</TD>
                <TD>{fv(debounced_data?.major,"major",translation)?.[locale]}</TD>
              </tr>
            </tbody>
          </Table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div/>
          <div/>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text mx="sm" fz="md" fw={700} color="#868686">
              {tn("CV")}
            </Text>
            <Button color="color3"
            disabled={!debounced_data?.cv}
              style={{
                borderRadius: "10px",
                width: "70px",
                height: "35px",
              }}
              onClick={() => {
                window.open(`${CMS_ENDPOINT}/api/files/${debounced_data?.collectionId}/${debounced_data?.id}/${debounced_data?.cv}?token=`, "_blank");
              }}
            >
              {tn("Show")}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text mx="sm" fz="md" fw={700} color="#868686">
              {tn("Training request letter")}
            </Text>
            <Button color="color3"
              style={{
               
                borderRadius: "10px",
                width: "70px",
                height: "35px",
              }}
              disabled={!debounced_data?.training_request_letter}
              onClick={() => {
                window.open(`${CMS_ENDPOINT}/api/files/${debounced_data?.collectionId}/${debounced_data?.id}/${debounced_data?.training_request_letter}?token=`, "_blank");
              }}
            >
              {tn("Show")}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text mx="sm" fz="md" fw={700} color="#868686">
              {tn("Acceptance letter")}
            </Text>
            <Button color="color3"
            disabled={!debounced_data?.acceptance_letter}
              style={{
                borderRadius: "10px",
                width: "70px",
                height: "35px",
              }}
              onClick={() => {
                window.open(`${CMS_ENDPOINT}/api/files/${debounced_data?.collectionId}/${debounced_data?.id}/${debounced_data?.acceptance_letter}?token=`, "_blank");
              }}
            >
              {tn("Show")}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text mx="sm" fz="md" fw={700} color="#868686">
              {tn("Taining contract")}
            </Text>
            <Button color="color3"
            disabled={!data?.record?.contract_data || data?.record?.contract_status != "finished"}
              style={{
                borderRadius: "10px",
                width: "70px",
                height: "35px",
              }}
              onClick={() => {
                setContractViewerData(
                  {
                    applicant:{
                      ...data?.record?.contract_data?.applicant,
                      religion:fv(data?.record?.contract_data?.applicant?.religion,"religion",translation)?.[locale],
                      marital_status:fv(data?.record?.contract_data?.applicant?.marital_status,"marital_status",translation)?.[locale],
                      gender:fv(data?.record?.contract_data?.applicant?.gender,"gender",translation)?.[locale]
                    },
                    company:{
                      ...data?.record?.contract_data?.company,
                      job_title: data?.record?.contract_data?.company?.job_title?.[locale],
                    },
                    program:{
                      ...data?.record?.contract_data?.program,
                      programs_majors: data?.record?.contract_data?.program?.programs_majors?.map((item:any)=>fv(item,"major",translation)?.[locale]).join(', '),
                      program_type_translated:fv(data?.record?.contract_data?.program?.program_type_translated,"program_type",translation)?.[locale],
  
                    
                    }
                  }
                );
                openNewTab()
              }}
            >
              {tn("Show")}
            </Button>
          </div>
          <div/>
          <div/>
        </div>
      </MainBox>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
        }}
      >
        <Button
          style={{
            background: "#F9837C",
            borderRadius: "5.112px",
            width: "100px",
            height: "30px",
          }}
          onClick={() => {
            onClose({});
          }}
        >
          {tn("close")}
        </Button>
      </div>
    </div>
  );
};
const TH = (props: any) => {
  return (
    <th>
      {" "}
      <Text
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          color: "#5c5c5c",
          textAlign: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "7px",
          height: "30px",
        }}
      >
        {props.children}
      </Text>
    </th>
  );
};

const TD = (props: any) => {
  return (
    <td>
      {" "}
      <Text
        style={{
          fontSize: "12px",
          color: "#5c5c5c",
          textAlign: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "30px",
        }}
      >
        {props.children}
      </Text>
    </td>
  );
};

const MainBox = (props: any) => {
  const { height, width }: any = useViewportSize();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(79, 81, 82, 0.05)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: width > 600 ? "center" : "start",
        justifyContent: "start",
        marginTop: "20px",
        overflowX: "scroll",
        overflowY: "hidden",
        ...props.style,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            background: "#21033F",
            borderRadius: "50px",
            width: "calc(100% - 20px)",
            minHeight: "5px",
          }}
        />
      </div>
      {props.children}
    </div>
  );
};
