import { PasswordInput, Button, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { CMS_ENDPOINT } from "../constants";
import { tn, vtn } from "../translation";
import PocketBase from "pocketbase";

export function ChangePassword({ onClose, setBanner, table }: any) {
  const validators = [
    vtn("password"),
    vtn("password1"),
    vtn("password2"),
    vtn("password3"),
    vtn("password4"),
    vtn("password5"),
    vtn("password_comfirm"),
  ];
  const validatePassword = (value: any, values: any) => {
    if (!value) return validators?.[0];

    if (value === values.oldPassword) return validators[1];

    // Check for minimum 8 characters
    if (value.length < 8) return validators[2];

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(value)) return validators[3];

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(value)) return validators[4];

    // Check for at least one of the special characters !@#%?
    if (!/[!”#$%&’()*+,-./:;<=>?@[\]^_`{|}~]/.test(value)) return validators[5];

    return null;
  };

  const validateOldPassword = (value: any) => {
    // if (error) return 'Password is incorrect';
    if (!value) return "Old password is required";
    return null;
  };

  const validatePasswordConfirm = (value: any, values: any) => {
    if (!value) return "Confirmation is required";
    if (value !== values.password) return validators[6];
    return null;
  };
  const form = useForm({
    initialValues: {
      password: "",
      passwordConfirm: "",
      oldPassword: "",
    },
    validate: {
      password: validatePassword,
      oldPassword: validateOldPassword,
      passwordConfirm: validatePasswordConfirm,
    },
  });
  useEffect(() => {
    pb.collection("recruiters").authRefresh(
      {},
      {
        expand: "company, permission", // replace with your relation field name
      }
    );
  }, []);

  //   const form = useForm({
  //     initialValues: {
  //       password: "",
  //       passwordConfirm: "",
  //       oldPassword: "",
  //     },
  //     validate: {
  //         password: (value) =>
  //         value?.length < 8
  //             ? vtn("password")
  //             : null,
  //         passwordConfirm:

  //     }
  //   });
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [error, setError] = useState(false);
  const [finsh, setfinsh]: any = useDebouncedState("", 200);
  const inputsStyle = {
    innerInput: {
      color: "#21033F",
    },
    label: {
      color: "#21033F",
    },
    input: {
      borderRadius: "0px",
      color: "black",
      backgroundColor: "transparent",
      border: "0px solid #21033F",
      borderBottom: "1px solid #21033F85",
      "&:focus-within": {
        border: "0px solid #21033F",
        borderBottom: "1px solid #21033F",
      },
    },
  };
  return (
    <form
      onSubmit={form.onSubmit((value) => {
        const data = {
          password: value?.password,
          passwordConfirm: value?.passwordConfirm,
          oldPassword: value?.oldPassword,
        };
        try {
          const record = pb
            .collection(table)
            .update(pb?.authStore?.model?.id, data)
            .then((records: any) => {
              onClose(false);
              form.reset();
              setBanner(true);
              setError(false);
            });
          record?.catch((err) => {
            setError(true);
            console.log("ereror", err);
          });
        } catch {}
      })}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "start",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text
          style={{
            color: "rgba(0, 0, 0, 0.70)",
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          {tn("Change password")}
        </Text>
      </div>

      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PasswordInput
          style={{
            width: "100%",
          }}
          {...form.getInputProps("oldPassword")}
          required
          my={10}
          variant="filled"
          styles={(theme) => inputsStyle}
          label={tn("Current Password")}
          size="md"
        />
        <PasswordInput
          style={{
            width: "100%",
          }}
          {...form.getInputProps("password")}
          required
          my={10}
          variant="filled"
          styles={(theme) => inputsStyle}
          label={tn("New Password")}
          size="md"
        />
        <PasswordInput
          style={{
            width: "100%",
          }}
          {...form.getInputProps("passwordConfirm")}
          required
          my={10}
          variant="filled"
          styles={(theme) => inputsStyle}
          label={tn("Password Confirm")}
          size="md"
        />
        <div style={{ width: "100%" }}>
          {error && (
            <Text fz="sm" color="red">
              {vtn("new_password_error")}
            </Text>
          )}
        </div>
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "end",
          alignItems: "start",
          marginBottom: "20px",
          gap: "10px",
        }}
      >
        <Button
          type="submit"
          // radius was 0 here
          style={{ background: "#F9837C",
          borderRadius: "5.112px"
        }}
        >
          {tn("Change password")}
        </Button>
        <Button
          // radius was 0 here
          style={{ background: "#69DCBF" }}
          onClick={() => {
            onClose(false);
            form.reset();
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </form>
  );
}
