import { Button, Dialog, Menu, Tooltip, Text } from "@mantine/core";
import { TranslationContext } from "../../../../context/TranslationContext";
import { useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { DataContext } from "../../../../context/DataContext";
import { motion } from "framer-motion";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../constants";
import { SelectableActionComponent } from "../SelectableActionComponent";

export const DeletePermission = (props: any) => {
  const { locale }: any = useContext(TranslationContext);
  const { setDeletePermission }: any = useContext(DataContext);
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { selectedRecords, action, table } = props;
  return (
    <SelectableActionComponent
    selectedRecords={selectedRecords}
       action={action}
       table={table}
       setData={async () => {
        const records = await pb
        .collection("permissions")
        .getFullList({
          sort: "-created",
          filter: selectedRecords
            ?.map((record: any) => `id = '${record}'`)
            ?.join(" || "),
        })
        ?.then((res: any) => {
          if (
            res.every(
              (record: any) =>
                record?.company
            )
          ) {
            setDeletePermission({
              id: selectedRecords?.[0],
              record: selectedRecords,
              action: action,
              table: table,
            });
          }else{
            alert("you can't delete main permissions")
          }
        });
      }
      }
       enabled={true}
    />
  
  );
};
