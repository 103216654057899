import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import { ltn, tn, vtn } from "../../../../../../translation";
import {
  Accordion,
  Button,
  Checkbox,
  Modal,
  Text,
  TextInput,
} from "@mantine/core";
import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { AppContext } from "../../../../../../context/AppContext";
import { BannersContext } from "../../../../../../context/BannersContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { SystemContext } from "../../../../../../context/SystemContext";
import { DataContext } from "../../../../../../context/DataContext";
import alasql from "alasql";
const pb = new PocketBase(CMS_ENDPOINT);
export const ViewPermission = ({ data:data_, onClose }: any) => {
  const {record:data}:any = data_;
  const { translation, locale }: any = useContext(TranslationContext);
  const { systemData }: any = useContext(SystemContext);

  const { setEditPermission }: any = useContext(DataContext);
  const [warnning, setWarnning]: any = useState(false);

  const pageGroubs = systemData?.permissions?.filter(
    (item: any) => item?.id?.split(".")?.length == 1
  );
  const pages = alasql(`Select * from ? order by order_by`,[systemData?.permissions?.filter(
    (item: any) => item?.id?.split(".")?.length == 2
  )??[]]);

  const permissions = alasql(`Select * from ? order by order_by`,[ systemData?.permissions?.filter(
    (item: any) => item?.id?.split(".")?.length == 3
  )??[]]);


  // const { setTopBanner }: any = useContext(BannersContext);
  // const emailsError = [vtn("email"), vtn("taken_email")];
  // const validate_text = [vtn("empty"), vtn("sub_departments_empty")];
  // const dialogMassage = ltn("user_added_successfully");
  const form: any = useForm({
    initialValues: {
      name: (data?.collectionName == "permissions" )?(data?.name ?? "") : (data?.expand?.permission?.name ?? ""),
      permissions:  (data?.collectionName == "permissions" )?(data?.permissions ?? []):(data?.expand?.permission?.permissions ?? []),
    },

    validate: {
      name: isNotEmpty(vtn("empty")),
    },
  });
  const [loadingBotton, setLoadingButton] = useState(false);
  const [currentPages, setCurrentPages] = useState<string | null>(null);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
    { data?.expand?.permission?.company &&   <Button
        loading={loadingBotton}
        type="submit"
        compact
        style={{
          position: "absolute",
          bottom: "25px",
          left: "135px",
          background: "#69DCBF",
          borderRadius: "5.112px",
          width: "100px",
        }}
        onClick={() => {
          setEditPermission({
            record: (data?.collectionName == "permissions" )? data : data?.expand?.permission,
            id: (data?.collectionName == "permissions" )? data?.id : data?.expand?.permission?.id,
          });
          onClose({});
        }}
      >
        {tn("Edit data")}
      </Button>}
      <Button
        compact
        style={{
          position: "absolute",
          bottom: "25px",
          left: "25px",
          background: "#F9837C",
          borderRadius: "5.112px",
          width: "100px",
        }}
        onClick={() => {
          form?.isDirty() ? setWarnning(true) : onClose({});
        }}
      >
        {tn("close")}
      </Button>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            width: "100%",
            padding: "20px",
            color: "rgba(0, 0, 0, 0.70)",
            textAlign: "right",
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          {tn("Edit a permission")}
        </Text>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "stretch",
            padding: "0px 20px",
            marginBottom: "50px",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              background: "rgba(79, 81, 82, 0.05)",
              width: "calc(40% - 5px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <div />
            <Icon
              icon={"icon-park-outline:permissions"}
              style={{
                fontSize: "160px",
                color: "#4F5152",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "0px 20px",
                marginTop: "30px",
                marginBottom: form?.errors?.name ? "10px" : "10px",
              }}
            >
              <TextInput
                disabled
                mb="xl"
                {...form.getInputProps("name")}
                label={tn("Permission")}
                placeholder={tn("Permission name")}
                style={{ width: "calc(100%)" }}
                styles={() => ({
                  input: {
                    borderRadius: "3.453px",
                    ["&:disabled"]: {
                      color: "black",
                      background: "#f3f3f3",
                      opacity: 1,
                      border: "1px solid #aeaeae",
                    },
                  },
                  error: {
                    marginBottom: "-10px",
                  },
                  label: {
                    position: "sticky",
                    top: "0px",
                    zIndex: 9999,
                    background: "white",
                    padding: "0px 5px",
                    transform: "translate(10px, 10px)",
                    borderRadius: "3px",
                  },
                })}
              />
            </div>
          </div>
          <div
            style={{
              borderRadius: "10px",
              background: "rgba(79, 81, 82, 0.05)",
              width: "calc(60% - 5px)",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 20px",
              overflow: "auto",
            }}
          >
            <Accordion
              style={{
                width: "100%",
              }}
              value={currentPages}
              onChange={setCurrentPages}
              variant="separated"
            >
              {pages?.map((page) => {
                const pagePermissions = permissions?.filter(
                  (permission: any) =>
                    permission?.id?.split(".")?.[0] ==
                      page.id?.split(".")?.[0] &&
                    permission?.id?.split(".")?.[1] == page.id?.split(".")?.[1]
                );
                const currentPermissions = form.values.permissions?.filter(
                  (item) =>
                    item?.split(".")?.[0] + "." + item?.split(".")?.[1] ==
                    page.id
                );
                return (
                  <Accordion.Item
                    style={{
                      border: pagePermissions?.[0]
                        ? "1px solid #050505"
                        : "1px solid #aeaeae",
                    }}
                    value={page.id}
                  >
                    <Accordion.Control
                      disabled={!pagePermissions?.[0]}
                      icon={
                        <div
                          style={{
                            width: "18px",
                            height: "18px",
                            borderRadius: "100%",
                            background:
                              currentPermissions?.length ==
                              pagePermissions?.length
                                ? "#69DCBF"
                                : currentPermissions?.length > 0
                                ? "#f9d37c"
                                : "#F9837C",
                          }}
                        />
                      }
                    >
                      {page?.["label_" + locale]}
                    </Accordion.Control>
                    <Accordion.Panel>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {pagePermissions?.map((permission: any, i: any) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "start",
                                width: "100%",
                                padding: "0px 20px",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: i != 0 ? "20px" : "",
                                  borderRight:
                                    i != 0 ? "1px solid #aeaeae" : "",
                                  margin: "5px 9px",
                                }}
                              />

                              <Text
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "10px",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "100%",
                                    background: currentPermissions?.includes(
                                      permission?.id
                                    )
                                      ? "#69DCBF"
                                      : "#F9837C",
                                  }}
                                />
                                {permission?.["label_" + locale]}
                              </Text>
                            </div>
                          );
                        })}
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};
