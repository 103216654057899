import { tn, vtn } from "../../../../../../translation";

import { Button, Loader, Text, Textarea } from "@mantine/core";

import { useContext, useState } from "react";
import { TranslationContext } from "../../../../../../context/TranslationContext";
import { CMS_ENDPOINT } from "../../../../../../constants";
import PocketBase from "pocketbase";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { isNotEmpty, useForm } from "@mantine/form";
import { AppContext } from "../../../../../../context/AppContext";
import { fv } from "../../../../../Functions";
import { AfterExclude } from "../Function";
const pb = new PocketBase(CMS_ENDPOINT);
export const WithdrawalReason = ({ data, onClose }: any) => {
  const handleOnClick = async () => {
    try {
      setButtonLoading(true);
  
      // Updating the application status
      const application = await pb
        .collection("applications")
        .update(data?.record?.id, {
          applicant_status: "withdrawn",
          recruiter_status: "withdrawn",
          main_status: "withdrawn",
          withdrawal_status: "accepted",
        });
 
  
      // Check program and applications status to update if necessary
      await AfterExclude(application);
  
      // Final UI updates
      setReloader((prev) => !prev);
      onClose({});
    } catch (error) {
      console.error('Error processing the application:', error);
      // Optionally, handle error state in UI
    } finally {
      setButtonLoading(false);
    }
  };
  

  

  



  const { translation, locale }: any = useContext(TranslationContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const { reloader, setReloader } = useContext(AppContext);
  const { width } = useViewportSize();
  const [buttonLoading, setButtonLoading] = useState(false);

  return width > 10 ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          width: "100%",
          padding: "10px 20px 10px ",
          color: "rgba(0, 0, 0, 0.70)",
          textAlign: "right",
          fontSize: "22px",
          fontWeight: "700",
        }}
      >
        {tn("Request to correct a document")}
      </Text>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0px 10px",
          flexDirection: width > 850 ? "row" : "column",
          alignItems: width > 850 ? "stretch" : "stretch",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: width > 850 ? "calc(35% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "calc(100% - 30px)",
              background: "#21033F",
              height: "5px",
              borderRadius: "522px",
            }}
          />
          <TextBox
            value={debounced_data?.record?.expand?.program?.name}
            name={tn("Program") + ":"}
          />
          <TextBox
            value={debounced_data?.record?.department_name}
            name={tn("Department") + ":"}
          />
          <TextBox
            value={fv(debounced_data?.record?.expand?.program?.type,"program_type",translation)?.[locale]}
            name={tn("Program Type") + ":"}
          />
          <TextBox
            value={new Date(
              debounced_data?.record?.expand?.program?.start_date
            )?.toLocaleDateString("en-GB")}
            name={tn("Program start date") + ":"}
          />
          <TextBox
            value={new Date(
              debounced_data?.record?.expand?.program?.end_date
            )?.toLocaleDateString("en-GB")}
            name={tn("Program end date") + ":"}
          />
          <Text
            style={{
              width: "100%",
              textAlign: "center",
              color: "#4F5152",
              fontSize: "18px",
              margin: "5px 0px",
              fontWeight: "600",
            }}
          >
            {tn("Applicant data")}
          </Text>
          <TextBox
            value={debounced_data?.record?.applicant_name}
            name={tn("Applicant name") + ":"}
          />
          <TextBox
            value={fv(debounced_data?.record?.university,"university",translation)?.[locale]}
            name={tn("University") + ":"}
          />
          <TextBox
            value={fv(debounced_data?.record?.major,"major",translation)?.[locale]}
            name={tn("Major") + ":"}
          />
          <TextBox
            value={debounced_data?.record?.interview_evaluation?.precentage?(debounced_data?.record?.interview_evaluation?.precentage + "%"):tn("not evaluated")}
            name={tn("Interview evaluation") + ":"}
          />
        </div>
        <div
          style={{
            width: width > 850 ? "calc(65% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            background: "rgba(79, 81, 82, 0.05)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "calc(100% - 30px)",
                background: "#21033F",
                height: "5px",
                borderRadius: "522px",
              }}
            />
            <Text
              style={{
                width: "100%",
                padding: "10px 20px 10px ",
                color: "rgba(0, 0, 0, 0.70)",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              {tn("Justifications for withdrawal from the program")}
            </Text>
          </div>
          <div
            style={{
              width: "calc(100% - 40px)",
              borderRadius: "5px",
              border: "1px solid #4F5152",
              height: "calc(100% - 120px)",
              padding: "10px",
              color:"#4F5152",
              fontSize: "16px",
            }}
          >
            {debounced_data?.record?.withdrawal_reason}
          </div>
          <div />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          padding: "20px 10px",
        }}
      >
      {debounced_data?.table?.name == "new_withdrawal_requests" && <>  <Button
        loading={buttonLoading}
          type="submit"
          compact
          style={{
            backgroundColor: "#69DCBF",
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
          }}
          onClick={handleOnClick}
        >
          {tn("Accept")}
        </Button>
        <Button
          loading={buttonLoading}
          compact
          mx="sm"
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={
            async () => {
              setButtonLoading(true);

              await pb
                .collection("applications")
                .update(debounced_data?.record?.id, {
                  main_status:debounced_data?.record?.withdrawal_history?.main_status,
                  applicant_status:debounced_data?.record?.withdrawal_history?.applicant_status,
                  withdrawal_status:"rejected"
                });
              onClose({});
              setReloader(!reloader);
            
          }}
        >
          {tn("Reject")}
        </Button></>}
        <Button
          loading={buttonLoading}
          compact
          style={{
            color: "white",
            width: "100px",
            borderRadius: "5.112px",
            backgroundColor: "#F9837C",
          }}
          color="red"
          onClick={() => {
            onClose({});
          }}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "1270px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader color="color1" />
    </div>
  );
};

const TextBox = ({ value, name }: any) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "3px 0px",
        padding: "0px 10px",
      }}
    >
      <Text
        style={{
          padding: "10px 3px 10px ",
          color: "#4F5152",
          textAlign: "right",
          fontSize: "14px",
          fontWeight: "700",
        }}
      >
        {name}
      </Text>
      <Text
        style={{
          minHeight: "34.8px",
          width: "180px",
          color: "#4F5152",
          padding: "5px 10px",
          borderRadius: "5px",
          background: "#ffffff",
          fontSize: value?.length > 23 ? "13px" : "16px",
          textAlign: "center",
          fontWeight: "400",
        }}
      >
        {value}
      </Text>
    </div>
  );
};
