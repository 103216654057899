import { createContext, useState } from "react";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../constants";
import { Loader } from "@mantine/core";

export const SystemContext = createContext(null);

function setLocalStorage(key:any, value:any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // handle the error
  }
}

function getLocalStorage(key:any, initialValue:any) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
}

const SystemContextProvider = ({ children }:any) => {
  const pb:any = new PocketBase(CMS_ENDPOINT);

  const [systemData, setSystemData]:any = useState(() => getLocalStorage("systemData", {}));
  const [isLoading, setIsLoading]:any = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  if (!isLoading && !isFetched) {
    setIsLoading(true);
    pb.collection('airtable').getOne('7tn3nk2393et09l')
      .then((record:any) => {
        setLocalStorage("systemData", record?.data_json);
        setSystemData(record?.data_json);
      })
      .catch((error:any) => {
        console.error("Failed to fetch system data:", error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetched(true);
      });
  }

  if (isLoading && !systemData?.tables?.[0]) {
    return (
      <div style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Loader color="#5708a6"/>
      </div>
    );
  }
  console.log("systemData",systemData);
  console.log("systemDatasystemData",pb?.authStore?.model?.expand);

  return (
    <SystemContext.Provider value={{ systemData, setSystemData }}>
      {children}
    </SystemContext.Provider>
  );
};

export default SystemContextProvider;
