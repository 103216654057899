import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";
import { pb } from "../../../../../../config";

export const EditProgram = (props: any) => {
  const { setEditProgram }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  if(data?.status == "completed" || data?.main_status == "current" && !pb.authStore.model.expand?.permission?.permissions?.includes("2.1.12")) return null;

  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setEditProgram({
      id: data.id,
      record: data,
      action: action,
      table: table,
    })}
    enabled={true}
    />
  );
};
