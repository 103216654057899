import { useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { ActionComponent } from "../../../ActionComponent";

export const EditRecruiter = (props: any) => {
  const { setEditRecruiter }: any = useContext(DataContext);
  
  const { data, action , table } = props;
  return (
    <ActionComponent 
    data={data}
    action={action}
    table={table}
    setData={()=>setEditRecruiter(
      {
        id: data.id,
        record: data,
        action: action,
        table: table,
      }
    )}
    enabled={true}
    />
  );
};
