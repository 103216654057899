
import {
    IconCircleCheck,
    IconEdit,
    IconSquareRoundedX,
    IconTrash,
  } from "@tabler/icons-react";
  import PocketBase from "pocketbase";
  import { CMS_ENDPOINT } from "../../../constants";
  import { useContext, useEffect, useState } from "react";
  import { Button, Dialog, Group, Modal, Text, TextInput } from "@mantine/core";
  import {  tn, ttn } from "../../../translation";
  import { useDebouncedState, useTimeout } from "@mantine/hooks";
  import { TranslationContext } from "../../../context/TranslationContext";
  
  import { DataGrid } from "../../../main_components/data-grid/DataGrid";
import { Helmet } from "react-helmet";
import { DataGridMobile } from "../../../main_components/data-grid/DataGridMobile";
  
   function ProgramsHistory() {
    const pb: any = new PocketBase(CMS_ENDPOINT);
    return (
      <>
       <Helmet>
<title>{ttn("my_programs_history") + " / " + tn("Coophub")}</title>
</Helmet>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 84px)",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            padding: "20px",
            paddingTop: "90px",
            background: "#fcfcfc",
          }}
        >
          <DataGridMobile name="my_programs_history"/>
        </div>
      </>
    );
  }
  export default ProgramsHistory;
  